import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 720 539">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="">
          <path
            fill="#F1F1F0"
            opacity="1.000000"
            stroke="none"
            d="
M1.000000,461.000000 
	C1.000000,307.693451 1.000000,154.386902 1.000000,1.040181 
	C240.931274,1.040181 480.862549,1.040181 720.896912,1.040181 
	C720.896912,180.566513 720.896912,360.133118 720.896912,540.000000 
	C486.974243,540.000000 252.948380,540.000000 18.405254,539.585144 
	C17.613764,538.213440 17.106976,537.258057 17.100811,536.299500 
	C17.029051,525.138184 16.982809,513.976013 17.070562,502.815155 
	C17.083656,501.150116 18.321568,499.431580 18.133474,497.839294 
	C17.407141,491.690491 15.933852,485.611206 15.495977,479.452148 
	C15.027441,472.861969 7.696927,462.952148 1.000000,461.000000 
M465.538605,78.789497 
	C465.273224,78.625481 465.007812,78.461464 464.753540,78.322876 
	C464.753540,78.322876 464.721771,78.323738 464.832275,77.606636 
	C464.147583,77.120865 463.462860,76.635094 462.308136,75.785683 
	C461.681641,75.024719 461.055115,74.263756 460.696655,72.786942 
	C457.062164,68.693474 453.720276,64.275894 449.681732,60.629982 
	C447.317078,58.495213 443.841553,57.590954 440.405029,56.085392 
	C440.405029,56.085392 439.988800,56.288544 439.676331,55.979282 
	C439.676331,55.979282 439.248413,55.878654 438.978210,55.295502 
	C438.405640,55.069679 437.833099,54.843857 437.326691,54.703159 
	C437.326691,54.703159 437.358307,54.600075 437.331787,54.086285 
	C437.178070,53.774361 437.024384,53.462437 436.820374,52.444813 
	C436.070862,52.154446 435.321320,51.864079 434.159119,51.420029 
	C434.159119,51.420029 433.767761,51.218082 433.650848,50.698738 
	C433.339996,50.571014 433.029144,50.443291 432.393311,50.122574 
	C432.393311,50.122574 432.079254,49.912296 431.936707,49.247684 
	C429.004364,47.785309 426.072021,46.322937 422.911469,44.223724 
	C421.271729,43.797428 419.632019,43.371136 417.488373,42.882267 
	C417.009277,42.900391 416.530182,42.918510 415.666779,42.808388 
	C415.414185,42.893810 415.163330,42.984097 414.827271,42.617340 
	C414.677856,42.327923 414.474030,42.083847 414.137054,41.460556 
	C414.137054,41.460556 413.891968,41.105061 413.563293,40.452267 
	C411.972137,40.079803 410.380951,39.707340 408.344513,39.299641 
	C407.905823,39.361652 407.467102,39.423664 406.890076,39.454372 
	C406.890076,39.454372 406.753815,39.493786 406.712402,39.233635 
	C406.712402,39.233635 406.567474,39.005951 406.771057,38.424572 
	C401.027863,36.770157 395.377197,34.460339 389.517212,33.633148 
	C382.737244,32.676090 375.773071,33.023785 368.193390,32.845474 
	C367.496582,32.837093 366.799805,32.828712 365.408264,32.374859 
	C360.903656,32.875969 356.399078,33.377083 351.266296,33.937733 
	C350.845276,33.963207 350.424225,33.988682 349.488495,33.507710 
	C346.928345,34.223106 344.368164,34.938499 341.218536,35.780869 
	C340.820007,35.833061 340.421478,35.885258 339.550934,35.478008 
	C337.654999,36.222454 335.759033,36.966896 333.254425,37.888107 
	C332.841064,37.976189 332.427734,38.064270 331.646393,37.651604 
	C330.037018,38.357964 328.427643,39.064320 326.432343,39.898067 
	C326.432343,39.898067 326.042755,40.017056 325.309662,39.982746 
	C324.181335,40.605598 323.052979,41.228451 321.528717,42.124756 
	C321.528717,42.124756 321.174011,42.449844 320.467285,42.329193 
	C318.907806,43.171066 317.348297,44.012939 315.381531,44.919651 
	C315.381531,44.919651 315.025238,45.127434 314.296051,45.086624 
	C312.112274,46.338760 309.928497,47.590893 307.121887,48.922817 
	C306.739532,49.087910 306.357208,49.253002 305.580963,49.371632 
	C305.300385,49.503590 305.184937,49.722763 304.621368,50.388557 
	C299.437531,53.968433 294.253723,57.548309 288.392120,61.132874 
	C287.609924,62.098423 286.827759,63.063972 285.834290,64.434731 
	C285.834290,64.434731 285.852417,64.891342 285.931274,65.000191 
	C285.931274,65.000191 285.889923,64.872307 285.249512,65.041489 
	C283.765839,66.624954 282.282135,68.208427 281.005402,69.977394 
	C281.005402,69.977394 280.791626,69.801697 280.211426,69.964340 
	C279.465302,70.637672 278.719147,71.311005 277.339935,72.059143 
	C276.871918,72.699097 276.403900,73.339043 275.540375,74.433449 
	C274.708893,75.309273 273.877380,76.185089 272.352112,77.128288 
	C270.232697,80.412140 268.113251,83.695999 265.996704,86.995857 
	C265.996704,86.995857 265.980865,86.996124 265.311401,87.070267 
	C264.648315,88.417435 263.985199,89.764603 262.989990,91.595192 
	C262.671967,92.084290 262.353943,92.573387 261.366638,93.147484 
	C260.632904,94.478577 259.899170,95.809669 258.555908,97.432404 
	C258.053528,98.633247 257.551147,99.834091 256.583221,101.485443 
	C256.366364,102.303543 256.149536,103.121643 255.685806,104.530731 
	C255.114655,105.676453 254.543488,106.822166 253.311508,108.119698 
	C253.061264,109.102737 252.811020,110.085770 252.284332,111.767441 
	C252.058426,112.738472 251.832504,113.709503 251.008759,115.020439 
	C249.690170,119.679581 248.371597,124.338722 246.871964,129.662720 
	C246.867554,130.119995 246.863144,130.577255 246.660889,131.327682 
	C246.660889,131.327682 246.606186,131.677078 246.027191,132.065918 
	C245.706329,134.706146 245.385468,137.346390 244.938782,140.789490 
	C244.841202,142.405823 244.743637,144.022156 244.039108,146.120789 
	C243.767853,147.548752 243.294891,148.971359 243.256744,150.405533 
	C242.899612,163.826233 243.669067,177.128006 247.559082,190.704697 
	C250.041611,197.630157 251.982544,204.817291 255.165268,211.404434 
	C258.105652,217.490021 262.377075,222.932480 266.344299,229.271774 
	C267.244537,230.505035 268.144775,231.738297 269.214325,233.456497 
	C269.500580,233.618011 269.786835,233.779510 270.388580,234.403137 
	C270.761078,234.821335 271.133606,235.239532 271.281189,236.379669 
	C273.981750,239.772018 276.593658,243.241791 279.442169,246.505020 
	C280.201172,247.374557 281.684967,247.611450 283.040680,248.454590 
	C283.040680,248.454590 283.245117,248.770569 283.198029,249.480103 
	C286.822235,252.282333 290.446472,255.084564 294.614594,258.049988 
	C295.085358,258.359802 295.556091,258.669617 296.184814,259.652496 
	C297.775635,261.185974 299.123962,263.189545 301.005188,264.160126 
	C305.659607,266.561462 310.557983,268.490051 316.088898,270.741058 
	C317.067902,270.885468 318.046875,271.029907 319.360962,271.637543 
	C319.915070,271.737122 320.469177,271.836670 321.100952,272.634064 
	C323.065063,273.435425 325.029205,274.236786 327.630920,275.104004 
	C328.038574,275.227631 328.446198,275.351257 329.115753,276.083344 
	C336.089661,279.567688 343.488922,280.953979 351.194183,282.307648 
	C364.894257,284.714447 378.221466,282.716248 391.684418,281.687592 
	C393.195099,281.572144 394.595184,280.008545 396.697754,278.941498 
	C397.365570,278.853912 398.033386,278.766327 399.315674,278.948883 
	C399.536499,278.978485 399.757294,279.008057 400.316925,279.281189 
	C400.539917,278.843658 400.762909,278.406158 401.451263,277.694763 
	C401.798828,277.607574 402.146362,277.520386 403.291412,277.769501 
	C406.297333,276.935547 409.405151,276.354004 412.279602,275.194214 
	C414.973816,274.107147 417.421082,272.408112 420.408752,270.680206 
	C420.707764,270.498840 421.006775,270.317474 421.627045,270.104767 
	C421.840363,270.029388 421.997681,269.890564 422.734467,269.354584 
	C423.424744,269.163574 424.114990,268.972534 425.379333,268.981537 
	C425.580750,268.994873 425.782135,269.008179 426.457123,269.503784 
	C427.200348,268.589142 427.943573,267.674500 428.705963,266.689209 
	C428.705963,266.689209 428.729309,266.619843 429.461517,266.801697 
	C433.579376,264.609344 437.753479,262.512146 441.765594,260.140900 
	C442.528625,259.689911 442.650696,258.154449 443.360901,256.878052 
	C443.360901,256.878052 443.693817,256.696686 444.399872,256.808960 
	C445.608856,255.560181 446.817841,254.311401 448.011200,253.002304 
	C448.011200,253.002304 448.067413,253.029266 448.727417,252.952713 
	C449.799683,252.425385 451.021515,252.072327 451.899445,251.318649 
	C452.678375,250.649979 453.075439,249.536484 453.964020,248.118713 
	C454.229431,247.823486 454.494873,247.528259 454.973389,247.134796 
	C454.973389,247.134796 455.236755,246.756439 455.648682,246.384689 
	C455.794220,246.270569 455.939789,246.156433 456.590790,245.899582 
	C456.843964,245.461105 457.097137,245.022629 457.736206,244.219864 
	C458.021729,244.011688 458.307281,243.803497 459.233063,243.762756 
	C459.577972,242.564835 459.922913,241.366898 460.636688,239.979309 
	C460.636688,239.979309 460.999695,239.778671 461.779633,239.872421 
	C462.764862,238.820099 463.750122,237.767776 464.946381,236.114136 
	C465.226959,235.542801 465.507507,234.971466 466.074402,234.237457 
	C466.250305,234.083145 466.340485,233.888992 466.404968,233.573944 
	C466.404968,233.573944 466.505493,233.565231 466.681091,233.630737 
	C466.681091,233.630737 466.850006,233.540421 467.205688,233.679565 
	C467.455688,233.442886 467.705688,233.206207 468.177826,232.410355 
	C468.522400,231.685745 468.866974,230.961136 469.889130,230.079636 
	C472.204895,229.165161 474.838318,228.452011 473.364044,224.500259 
	C473.798553,223.932220 474.233032,223.364182 475.335632,222.602859 
	C475.559326,221.746582 475.783020,220.890305 476.072388,219.532059 
	C476.083740,219.363708 476.095093,219.195343 476.880127,218.971252 
	C479.986664,218.891098 480.347931,217.204376 479.421143,214.563278 
	C479.421143,214.563278 479.535004,214.428665 480.207703,214.440109 
	C480.294342,213.840744 480.381012,213.241364 480.643799,212.102631 
	C480.732239,211.736023 480.820679,211.369415 481.228119,210.654968 
	C481.228119,210.654968 481.379059,210.207489 481.699036,209.883789 
	C481.895935,209.637894 482.001556,209.357605 482.417938,208.608292 
	C482.597168,208.049240 482.776398,207.490204 483.569122,206.627350 
	C489.157104,202.524139 487.572968,195.641876 489.786346,190.219025 
	C490.922058,187.436478 490.663666,184.084915 491.095825,180.317734 
	C491.048889,179.873291 491.001953,179.428864 491.110260,178.374084 
	C491.203827,177.965439 491.297424,177.556793 492.042847,176.807114 
	C492.306030,174.183533 492.569214,171.559967 492.850739,168.893448 
	C492.850739,168.893448 492.854828,168.846924 493.309509,168.636642 
	C493.220825,168.080887 493.132172,167.525116 493.084015,166.168045 
	C493.001038,165.107986 492.918060,164.047943 492.937897,162.161530 
	C492.812866,160.787857 492.687836,159.414169 492.825897,157.554230 
	C492.842804,157.370941 492.859711,157.187653 493.435364,156.396133 
	C493.564301,152.179337 494.059570,147.930878 493.698975,143.756363 
	C493.439667,140.754074 492.038422,137.850403 491.031494,134.319107 
	C490.975372,133.926575 490.919250,133.534042 491.009247,132.258209 
	C489.570221,127.292358 488.131195,122.326508 486.536865,116.891708 
	C486.536865,116.891708 486.371429,116.426247 486.660980,115.792778 
	C486.152557,115.172569 485.644165,114.552368 484.926849,113.521561 
	C484.801605,113.234009 484.617737,112.990761 484.363647,112.094147 
	C484.261719,111.404495 484.159790,110.714836 484.503296,109.605904 
	C483.815735,108.674599 483.128174,107.743294 482.234650,106.253906 
	C482.110291,105.879082 481.985931,105.504257 481.932678,104.365379 
	C481.154663,103.032318 480.376678,101.699257 479.268829,99.971794 
	C478.912720,99.608185 478.556580,99.244576 478.202881,98.264908 
	C478.119232,97.862640 478.035553,97.460373 477.984863,96.307159 
	C476.277771,93.915199 474.570648,91.523239 472.445557,88.945572 
	C472.445557,88.945572 471.989410,88.978943 471.888885,88.757668 
	C471.888885,88.757668 471.646545,88.739143 471.552643,88.103859 
	C471.131470,87.654259 470.710266,87.204659 470.261414,86.705894 
	C470.261414,86.705894 470.317749,86.702629 470.272003,86.058556 
	C469.557251,84.973000 468.842529,83.887451 468.109161,82.146797 
	C467.588165,81.648071 467.067169,81.149345 466.365631,80.506485 
	C466.365631,80.506485 466.254852,80.303749 466.471924,79.857864 
	C466.471924,79.857864 466.119720,79.511749 466.119720,79.511749 
	C466.119720,79.511749 465.674194,79.727074 465.433533,79.542526 
	C465.433533,79.542526 465.318146,79.262085 465.538605,78.789497 
M617.784546,455.826050 
	C617.784546,455.826050 617.814697,455.768677 618.502258,455.780914 
	C618.692688,454.518585 618.883118,453.256287 619.093018,451.283600 
	C619.174316,450.578583 619.255615,449.873596 619.974548,448.865875 
	C620.596985,447.365997 621.500977,445.918335 621.790649,444.356720 
	C622.773376,439.059753 621.262573,437.865265 616.173828,439.409424 
	C614.851379,439.810730 613.297485,439.376801 611.897034,439.609772 
	C609.609436,439.990326 607.360535,440.603394 604.992676,441.025085 
	C604.992676,441.025085 605.082886,441.131775 604.453125,441.100067 
	C604.110596,441.537201 603.768066,441.974365 603.106567,442.765930 
	C602.887390,442.996643 602.656616,443.215149 601.658020,443.472534 
	C599.928345,446.195526 598.432434,448.935455 601.416077,452.384369 
	C601.716187,452.850250 602.016235,453.316162 602.091980,454.540100 
	C601.308533,455.509583 599.921570,456.444977 599.864563,457.455444 
	C599.737549,459.706696 600.275269,461.995453 600.542175,464.268921 
	C601.696716,463.837036 602.851257,463.405151 604.885559,463.005157 
	C607.470581,462.848480 610.055664,462.691803 613.358215,462.753296 
	C614.495300,461.429260 615.632324,460.105194 616.917664,458.214050 
	C617.022888,457.837769 617.128113,457.461487 617.617432,456.798218 
	C617.846802,456.527649 617.902100,456.225220 617.784546,455.826050 
M371.712463,456.905914 
	C373.548492,455.222687 373.790222,453.842499 370.988953,453.010223 
	C370.988953,453.010223 370.956299,453.020813 371.019745,452.281311 
	C372.470764,450.025482 373.921783,447.769653 375.686890,445.025482 
	C376.289368,445.025482 377.571442,445.025482 378.875458,445.650513 
	C378.895386,446.066772 378.915314,446.483032 378.744568,447.455444 
	C378.483276,447.964752 378.221954,448.474060 377.266418,449.125153 
	C374.056000,452.962646 378.160370,454.652222 379.802551,456.725800 
	C381.727112,459.156067 384.487122,459.487152 387.726837,457.231262 
	C388.134735,457.234924 388.542664,457.238586 389.382477,457.616943 
	C389.942261,457.697388 390.502045,457.777832 391.727448,457.895416 
	C392.509705,456.623657 393.237061,455.313599 394.089935,454.091125 
	C394.909607,452.916229 395.859192,451.832001 397.519012,450.856781 
	C398.849915,451.894226 400.153442,452.969482 401.519592,453.958252 
	C402.823120,454.901703 404.191864,455.755005 406.035370,456.723022 
	C406.343384,456.863129 406.651428,457.003265 407.109070,457.796021 
	C408.743927,457.573761 410.378784,457.351501 412.751617,456.947998 
	C413.502411,457.021820 414.253174,457.095642 415.442230,457.644836 
	C417.354248,457.472656 419.266296,457.300446 421.178314,457.128235 
	C420.837585,456.082764 420.496857,455.037323 420.112122,453.359070 
	C420.400299,452.574219 420.688507,451.789398 421.681580,450.902283 
	C424.644531,447.515289 425.577576,439.350708 423.161682,435.394470 
	C423.064728,435.007446 422.967743,434.620422 422.865204,433.492767 
	C418.955566,429.602264 417.470215,435.273621 414.456116,435.244598 
	C414.874756,436.573547 415.180878,437.545349 415.608612,438.903137 
	C410.189484,437.612122 406.331299,439.588318 402.645905,442.739929 
	C401.177521,443.995636 398.887665,444.290802 396.570709,444.919128 
	C396.570709,444.919128 396.161407,444.854736 396.092438,444.149078 
	C393.211548,437.899902 391.572968,437.246674 386.286224,440.622192 
	C385.889435,440.646729 385.492645,440.671234 384.979828,440.181366 
	C383.665802,440.471832 382.351807,440.762329 380.136719,441.027039 
	C377.717194,441.057007 375.297668,441.086975 372.997559,440.998779 
	C372.997559,440.998779 372.883179,441.121185 372.949005,440.370880 
	C371.931000,439.277466 370.912964,438.184082 369.443085,437.033356 
	C369.443085,437.033356 369.016022,436.874847 368.504242,436.384308 
	C366.750031,435.962494 364.995819,435.540710 363.154541,435.097961 
	C362.894135,436.678864 362.658112,438.111603 362.443756,439.412842 
	C360.928589,438.519073 359.640015,437.107422 358.639557,437.288452 
	C355.367767,437.880463 352.200928,439.052368 348.338013,440.086304 
	C347.897736,440.074371 347.457489,440.062469 346.567322,439.530212 
	C344.262939,440.469177 340.347656,438.306763 339.698639,443.326874 
	C339.509277,443.567413 339.314331,443.803314 338.373352,444.084778 
	C335.681366,445.459015 334.000519,444.786011 332.274078,442.123779 
	C330.051544,438.696625 327.364136,435.036804 322.368774,438.681793 
	C321.786224,439.106873 320.612122,438.678528 319.966156,439.067474 
	C317.971863,440.268402 315.950928,441.505920 314.224609,443.047546 
	C312.579834,444.516388 311.292267,446.385254 309.986420,447.915649 
	C311.136261,450.193939 312.541412,452.977997 313.688843,455.251465 
	C312.149719,457.040741 310.997040,458.380737 309.844391,459.720734 
	C310.187866,460.104736 310.531372,460.488708 310.874847,460.872711 
	C314.752350,459.818359 318.815948,456.320465 321.548065,460.850922 
	C324.232208,459.738586 326.646973,458.988586 328.778229,457.750580 
	C329.871429,457.115570 330.463165,455.612305 331.272217,454.494690 
	C332.098389,453.353455 332.913269,452.204010 333.733093,451.058136 
	C334.395691,452.221924 335.033997,453.400421 335.727722,454.545349 
	C336.421967,455.691162 337.003174,457.591217 337.927246,457.788818 
	C341.255676,458.500519 344.716705,458.933533 348.109100,458.834930 
	C349.442139,458.796204 350.726349,457.079254 352.324890,455.884460 
	C352.324890,455.884460 352.672363,455.733551 353.403168,455.915924 
	C354.899536,456.929626 356.395874,457.943298 358.194061,459.161438 
	C357.929291,456.266510 357.722473,454.005188 357.492188,451.487305 
	C356.210663,451.170532 354.864655,450.837830 353.831299,450.582397 
	C355.111267,447.317505 356.281128,444.333435 357.821991,440.403137 
	C358.657104,441.775543 359.750275,442.814362 359.899750,443.974731 
	C360.214020,446.414825 359.600586,449.027924 360.190277,451.362244 
	C360.673157,453.273682 362.347290,454.884125 363.781647,456.727234 
	C363.781647,456.727234 364.020294,456.916931 364.150604,457.678741 
	C366.618713,459.342957 369.034882,460.464996 371.000946,456.998779 
	C371.000946,456.998779 371.022522,457.015106 371.712463,456.905914 
M230.002045,451.020905 
	C230.002045,451.020905 229.938995,451.073669 230.003952,450.339233 
	C230.655441,448.847351 231.306946,447.355469 232.254669,445.574646 
	C232.455795,445.385803 232.650772,445.190826 233.442139,444.568726 
	C232.921890,443.097046 232.840057,440.903198 231.807083,440.276184 
	C226.780746,437.225037 221.249161,437.716431 217.048798,441.288605 
	C210.428680,446.918488 210.884827,454.048157 219.040024,458.768677 
	C222.073563,460.524628 228.578201,458.562134 230.186707,455.374725 
	C230.699051,454.607025 231.211411,453.839355 232.279343,452.746002 
	C231.498840,452.185181 230.718338,451.624359 230.002045,451.020905 
M526.351562,455.369293 
	C526.662476,455.527313 526.973328,455.685303 527.350830,456.595184 
	C530.985535,456.744232 535.524414,458.280640 538.007263,456.662415 
	C540.628174,454.954193 541.642090,450.344543 542.712219,446.807068 
	C543.950195,442.714691 540.635681,441.294922 537.617371,440.203461 
	C533.245178,438.622467 529.297607,439.295013 525.959534,443.495331 
	C525.975708,443.662842 525.991943,443.830322 525.682373,444.375183 
	C525.484741,444.642517 525.296326,444.916168 524.370239,445.292480 
	C522.869080,448.864868 521.303894,452.448303 526.351562,455.369293 
M454.556488,443.435059 
	C453.392792,446.914001 451.949158,450.337006 451.236511,453.906036 
	C451.023529,454.972534 452.609985,456.801117 453.817322,457.638611 
	C455.103394,458.530731 456.899048,458.740387 458.503967,459.110504 
	C462.614075,460.058380 469.991730,456.915222 470.484802,453.314667 
	C471.093201,448.872131 471.351654,443.952362 469.906464,439.900360 
	C469.427246,438.556641 463.155304,438.802917 459.643066,439.232452 
	C458.060669,439.425995 456.751129,441.850830 455.088806,443.181213 
	C455.088806,443.181213 455.267792,443.345978 454.556488,443.435059 
M494.897919,439.582642 
	C494.804382,438.221100 494.710876,436.859558 494.647949,435.943573 
	C491.521942,437.404694 488.756134,438.697449 485.334961,440.083344 
	C484.894714,440.067047 484.454468,440.050751 483.656647,439.541290 
	C482.436676,440.056152 481.216705,440.571014 479.767944,441.267944 
	C479.767944,441.267944 479.476410,441.290100 478.722137,441.167450 
	C473.203003,443.831665 472.598358,445.108124 475.060181,449.673340 
	C475.014740,450.112762 474.969299,450.552185 474.241333,451.091614 
	C472.030396,453.895782 474.092224,453.915405 476.546173,454.367157 
	C477.382812,455.206543 478.219421,456.045898 479.104309,457.583252 
	C480.739319,457.485992 482.374298,457.388702 484.729248,457.099426 
	C485.465973,457.125122 486.202698,457.150787 487.039459,457.813843 
	C488.852570,460.899597 490.121307,460.214294 491.492493,457.045502 
	C491.492493,457.045502 491.960693,456.979401 492.769379,457.001343 
	C493.440338,456.620636 494.584381,456.354004 494.708008,455.841370 
	C495.774323,451.419708 496.717834,446.964020 497.475647,442.480896 
	C497.535034,442.129608 495.916107,441.494598 494.845581,440.741730 
	C494.845581,440.741730 494.747620,440.415527 494.897919,439.582642 
M465.139984,329.659180 
	C462.854584,329.305908 460.569183,328.952637 457.793152,328.523529 
	C457.793152,336.096924 457.793152,342.816833 457.793152,349.782440 
	C447.053925,342.499664 435.527893,342.926819 428.533539,350.584381 
	C421.751740,358.009308 421.445648,370.706940 427.856812,378.655640 
	C434.674957,387.108917 445.634766,387.797363 457.901337,380.432648 
	C458.087524,382.063477 458.265442,383.622314 458.447784,385.219604 
	C460.798859,385.219604 462.850311,385.219604 465.223206,385.219604 
	C465.223206,366.721100 465.223206,348.596619 465.139984,329.659180 
M303.139893,362.892639 
	C299.707367,364.708954 301.092804,361.588745 301.083405,360.639404 
	C300.979340,350.131622 301.017914,339.622375 301.017914,329.110138 
	C298.228638,329.110138 295.986084,329.110138 293.802185,329.110138 
	C293.802185,348.002106 293.802185,366.545868 293.802185,385.164703 
	C296.363007,385.164703 298.563934,385.164703 301.363678,385.164703 
	C300.223694,377.164185 301.583496,370.511108 309.738068,367.000854 
	C313.528961,371.730713 317.639282,376.332703 321.125977,381.366638 
	C324.227570,385.844513 328.052002,386.177795 333.320129,385.006073 
	C327.004822,376.912109 321.039551,369.266815 314.052155,360.311462 
	C319.751770,355.185425 325.201080,350.284546 330.860535,345.194641 
	C325.590057,344.053406 321.791382,345.169678 318.300598,348.845337 
	C313.736206,353.651428 308.581604,357.897003 303.139893,362.892639 
M267.130096,385.789398 
	C272.206146,384.865875 277.199738,383.785645 280.581451,378.857391 
	C279.199554,377.405914 277.970062,376.114502 276.581116,374.655579 
	C270.538788,380.117645 263.823822,380.355560 257.025513,377.303101 
	C253.333923,375.645569 250.717880,372.641174 250.938217,367.513245 
	C261.750366,367.513245 272.324310,367.513245 282.839722,367.513245 
	C283.786011,355.725494 277.792969,346.852631 267.983276,344.936035 
	C256.463043,342.685181 246.489716,348.087585 243.685989,358.673340 
	C239.946777,372.791016 248.105682,386.322021 267.130096,385.789398 
M483.263092,353.243744 
	C487.296967,352.480347 491.328369,351.111420 495.365326,351.094727 
	C501.501221,351.069305 504.442627,355.374847 503.498077,361.595459 
	C497.515045,361.989868 491.488770,361.933594 485.630493,362.898041 
	C479.496307,363.907837 476.374634,367.942871 476.170868,373.439178 
	C475.971069,378.828949 479.065735,383.121735 484.762573,384.908142 
	C491.530548,387.030426 497.888428,385.879822 503.698273,381.768738 
	C505.361237,386.238556 505.464722,386.278717 511.621155,384.772858 
	C511.621155,377.418701 511.645844,369.955627 511.603485,362.492950 
	C511.593109,360.666595 511.907562,358.554962 511.127075,357.072327 
	C509.192108,353.396790 507.469177,348.894226 504.293640,346.733459 
	C497.305756,341.978668 482.446930,344.239380 477.539062,350.035767 
	C479.275940,351.216766 478.172119,355.942078 483.263092,353.243744 
M355.505249,361.992004 
	C352.853058,362.148804 350.151184,362.036285 347.558197,362.514221 
	C340.512878,363.812897 337.043945,367.621979 336.993652,373.691589 
	C336.943756,379.720276 340.400604,383.600494 347.233826,385.310242 
	C353.607910,386.905090 359.313904,385.153595 364.677032,381.682159 
	C365.994293,386.163177 366.475128,386.348267 372.078339,384.699738 
	C372.078339,375.932129 372.555450,367.087341 371.847992,358.338318 
	C371.557190,354.742096 369.510712,350.501007 366.875671,348.036407 
	C360.347198,341.930298 344.810425,343.391083 338.340637,350.151672 
	C339.192474,351.604401 340.079254,353.116730 340.941711,354.587524 
	C356.281219,347.704224 365.400421,350.595428 364.393677,361.991272 
	C361.765747,361.991272 359.113464,361.991272 355.505249,361.992004 
M155.390396,385.339020 
	C161.581802,386.819366 167.196350,385.406891 172.574158,381.590668 
	C173.414185,387.091492 176.737274,385.888763 180.152740,384.985565 
	C180.152740,376.256866 180.623734,367.745575 179.940338,359.327972 
	C179.630707,355.514282 177.919205,351.006866 175.291168,348.349548 
	C168.936493,341.924103 153.067123,343.220367 146.378784,350.113281 
	C147.225479,351.577545 148.106842,353.101776 148.970703,354.595703 
	C163.910675,347.641083 173.260178,350.541260 172.537216,362.224396 
	C167.280151,362.224396 161.939575,361.798096 156.695679,362.328247 
	C149.539810,363.051605 145.568985,366.909943 145.026566,372.553345 
	C144.431732,378.742126 147.382019,382.625305 155.390396,385.339020 
M579.663940,353.907928 
	C580.578857,356.406647 581.493774,358.905365 582.455750,361.532684 
	C575.332520,362.001434 569.141724,361.950470 563.120300,362.935333 
	C557.224243,363.899689 554.176880,367.915466 554.006775,373.149567 
	C553.813843,379.085876 556.767883,383.127930 562.706360,384.938782 
	C569.517578,387.015686 575.815491,385.652039 581.663269,381.704559 
	C582.976929,386.201294 583.612732,386.438507 589.027161,384.570587 
	C589.027161,375.806610 589.088257,366.981903 589.004761,358.158539 
	C588.952637,352.649780 586.355530,348.343475 581.346802,346.272766 
	C572.232422,342.504700 563.536255,343.999146 555.061584,349.449432 
	C556.164246,351.430481 557.089539,353.092957 558.004211,354.736206 
	C565.210205,351.069672 571.966675,348.527466 579.663940,353.907928 
M409.563477,371.161255 
	C413.350983,362.579529 417.138489,353.997772 420.947296,345.367706 
	C415.658539,343.649994 413.047150,345.319397 411.478485,350.033295 
	C409.805695,355.059967 407.369537,359.832672 405.265839,364.715912 
	C403.670898,368.418121 402.074585,372.119781 399.971161,376.999512 
	C395.646637,367.199066 391.590668,358.856262 388.298492,350.222198 
	C386.380249,345.191437 383.697845,343.547394 378.293488,345.412964 
	C379.215393,347.514465 380.058929,349.451813 380.914001,351.384064 
	C385.420471,361.567352 389.711548,371.854370 394.537598,381.883942 
	C396.695343,386.368134 394.755249,389.790497 391.945007,391.909668 
	C389.786163,393.537628 385.972626,393.133362 382.878754,393.272705 
	C381.775482,393.322418 380.624542,392.313690 379.273102,391.684601 
	C378.344757,393.636047 377.584167,395.234894 376.859650,396.757812 
	C384.485626,403.428894 395.468689,401.363586 400.050781,392.303528 
	C403.420868,385.640106 406.185791,378.670654 409.563477,371.161255 
M206.056458,353.391571 
	C217.200027,347.289581 225.101028,351.950531 225.138519,364.663055 
	C225.158493,371.441437 225.142059,378.219910 225.142059,385.209991 
	C227.831055,385.209991 230.031189,385.209991 232.478653,385.209991 
	C232.478653,376.342163 232.841614,367.860687 232.380386,359.424255 
	C231.881683,350.302368 226.577438,345.174103 217.439819,344.392212 
	C211.469559,343.881348 205.975677,345.442078 201.245346,350.329620 
	C201.085403,348.140656 200.971832,346.586304 200.860291,345.059784 
	C198.452103,345.059784 196.365524,345.059784 194.222626,345.059784 
	C194.222626,358.555084 194.222626,371.783630 194.222626,385.316162 
	C196.552338,385.316162 198.631729,385.316162 201.202454,385.316162 
	C201.202454,379.365997 201.104904,373.577087 201.232101,367.793091 
	C201.341705,362.808960 201.290390,357.700226 206.056458,353.391571 
M106.183563,373.994385 
	C104.354507,375.423676 102.525452,376.852966 100.384666,378.525879 
	C102.972282,380.521729 104.569656,381.998474 106.387810,383.116028 
	C117.315140,389.832916 131.906509,384.934753 132.739395,373.510864 
	C133.737625,359.819305 132.975128,345.999390 132.975128,332.021637 
	C124.255867,332.021637 115.514915,332.021637 106.747833,332.021637 
	C106.747833,334.212036 106.747833,336.097260 106.747833,338.300690 
	C113.250793,338.300690 119.389847,338.300690 125.629646,338.300690 
	C125.629646,349.418152 126.064522,360.235474 125.474388,370.996643 
	C125.090889,377.989594 119.522636,381.027710 112.891235,378.601257 
	C110.646301,377.779785 108.784828,375.910370 106.183563,373.994385 
M526.284363,373.985931 
	C526.635925,379.211792 528.634644,383.356873 533.978577,385.036469 
	C538.931396,386.593109 543.651672,386.295563 548.107056,382.741180 
	C547.294189,381.052826 546.600525,379.612183 545.865967,378.086578 
	C543.836121,378.512085 542.088135,379.032837 540.305237,379.218445 
	C537.099792,379.552094 534.325745,378.277466 534.185120,374.942841 
	C533.851990,367.044220 534.078064,359.122009 534.078064,350.791840 
	C537.929199,350.791840 541.341003,350.791840 544.994507,350.791840 
	C544.994507,348.622986 544.994507,346.883392 544.994507,344.859802 
	C541.313599,344.859802 537.878540,344.859802 534.168518,344.859802 
	C533.983948,341.838684 533.815063,339.073547 533.637329,336.164490 
	C531.093994,336.164490 528.743896,336.164490 526.301819,336.164490 
	C526.163269,339.289490 526.042419,342.016296 525.924377,344.677582 
	C523.429749,344.818512 521.501831,344.927460 519.354919,345.048767 
	C519.492126,347.252075 519.599915,348.981995 519.705017,350.669556 
	C522.080872,350.829956 523.869263,350.950684 526.215393,351.109070 
	C526.215393,358.555847 526.215393,365.822540 526.284363,373.985931 
M124.927734,444.625427 
	C123.515045,443.847748 122.102356,443.070068 120.689667,442.292389 
	C120.824646,441.860168 120.959633,441.427979 121.094620,440.995758 
	C122.797462,440.995758 124.563744,441.277557 126.184479,440.912750 
	C127.665199,440.579468 128.988373,439.546295 130.380981,438.821564 
	C129.352341,437.703125 128.462433,435.839539 127.266975,435.616913 
	C124.535347,435.108307 121.349091,434.631012 118.921082,435.591309 
	C116.571373,436.520599 114.120918,438.940948 113.190971,441.290649 
	C111.909798,444.527832 113.416336,447.453705 117.263618,448.513336 
	C118.445137,448.838745 119.541389,449.473724 120.676697,449.966919 
	C120.587776,450.563385 120.498856,451.159821 120.409935,451.756287 
	C118.473404,451.561981 116.465118,450.966217 114.622200,451.295654 
	C113.041618,451.578156 111.650223,452.919067 110.175171,453.791931 
	C111.136963,454.989136 111.973640,457.059753 113.084129,457.218811 
	C116.344261,457.685791 119.949127,458.131531 122.966003,457.167725 
	C125.444771,456.375824 128.142441,453.923279 129.154251,451.533966 
	C130.201889,449.060150 129.156555,445.875183 124.927734,444.625427 
M580.228271,451.433319 
	C581.730408,442.344391 581.221985,441.681610 570.923340,439.568024 
	C570.856567,438.845459 570.976501,437.964874 570.667236,437.277405 
	C570.130005,436.083221 569.471802,434.237671 568.600464,434.074524 
	C567.458130,433.860687 565.132263,434.776794 564.917908,435.616089 
	C563.144409,442.561829 561.741211,449.602142 560.183289,456.811340 
	C563.982910,456.980560 566.628845,456.884430 569.206299,457.259369 
	C573.999817,457.956726 577.772339,456.845032 580.228271,451.433319 
M176.291077,462.470673 
	C180.452866,464.103363 184.259445,463.747467 186.988220,459.913391 
	C190.437576,455.066742 193.789062,450.132294 196.822189,445.021698 
	C197.492554,443.892242 196.652527,441.866394 196.508011,440.253296 
	C194.909775,440.663086 192.912964,440.619110 191.807510,441.589996 
	C190.182861,443.016907 189.194824,445.168671 187.446259,447.727783 
	C186.780090,445.616272 186.388565,444.427460 186.030151,443.228760 
	C185.181793,440.391296 183.005722,439.469391 180.640121,440.563660 
	C179.711395,440.993286 179.143387,443.477600 179.364182,444.875671 
	C179.998138,448.889435 181.119171,452.826263 182.097427,457.001038 
	C178.825058,457.737366 174.749573,457.012451 176.291077,462.470673 
M165.289200,440.579315 
	C163.637054,440.473389 161.958771,440.128876 160.337708,440.306580 
	C155.599976,440.825867 152.215958,445.563049 152.598663,450.873657 
	C152.905319,455.128967 156.384811,457.722351 161.439713,457.463226 
	C169.936249,457.027710 169.935028,457.027466 171.919388,448.551361 
	C172.032822,448.066864 172.210754,447.597595 172.326691,447.113586 
	C174.082184,439.785767 173.679810,439.361328 165.289200,440.579315 
M299.250427,454.949463 
	C300.777069,458.388794 303.858978,459.249329 304.920746,456.269958 
	C306.385834,452.158600 306.591888,447.341034 306.097443,442.966003 
	C305.970337,441.841339 301.215057,441.175446 298.547607,440.437103 
	C297.617004,440.179504 296.554199,440.444366 295.580780,440.288940 
	C291.343231,439.612305 289.444183,441.786285 288.840973,445.653320 
	C288.295990,449.147369 287.719391,452.636505 287.150848,456.164398 
	C292.944611,458.374634 294.034882,457.768921 294.777191,451.869293 
	C294.920563,450.729645 294.920593,449.392242 295.534241,448.540405 
	C296.188782,447.631775 297.492401,447.190674 298.512665,446.545471 
	C298.937042,447.599976 299.637146,448.633789 299.718323,449.714081 
	C299.828156,451.175354 299.462891,452.672333 299.250427,454.949463 
M262.470764,452.623138 
	C262.196838,453.864349 261.922913,455.105591 261.629303,456.435913 
	C266.352600,458.098267 268.889893,457.024658 269.361755,452.012207 
	C269.610352,449.371796 269.800354,446.299133 274.654053,445.790955 
	C274.281219,449.638977 273.950317,453.054260 273.624329,456.418762 
	C279.133636,457.986084 279.773560,457.675079 280.639282,452.809631 
	C281.045074,450.529114 281.216705,448.206635 281.486908,445.902313 
	C281.915039,442.250916 279.815430,440.811432 276.611176,440.324036 
	C265.738007,438.670074 263.398682,440.541870 262.737335,451.435822 
	C262.727356,451.600372 262.674255,451.762268 262.470764,452.623138 
M238.683578,444.374756 
	C236.422424,449.344452 237.287979,454.246674 240.797302,456.346039 
	C245.059753,458.895996 252.085861,458.086365 254.715286,453.793640 
	C256.226471,451.326508 256.692108,447.372955 255.869324,444.613007 
	C255.299835,442.702637 251.893097,441.070038 249.457703,440.414246 
	C245.586487,439.371796 241.865128,440.406891 238.683578,444.374756 
M511.205750,456.834045 
	C513.860901,452.832458 516.668640,448.919678 519.079346,444.775879 
	C519.813660,443.513733 519.454285,441.615356 519.595825,440.008362 
	C517.672485,440.715912 515.057007,440.865295 513.953369,442.233887 
	C510.391449,446.650848 510.320007,446.738464 507.579010,441.924500 
	C507.001892,440.910889 504.953094,439.857269 504.013855,440.160126 
	C502.949066,440.503387 501.672485,442.378845 501.717896,443.540100 
	C501.826721,446.323578 502.870667,449.057617 503.146637,451.850220 
	C503.615509,456.595642 505.983002,458.320679 511.205750,456.834045 
M136.449875,449.592621 
	C135.873520,453.605682 136.508438,457.107361 141.239624,457.775146 
	C142.752136,457.988617 144.595551,457.037018 145.985992,456.147217 
	C146.356232,455.910278 146.020752,453.491608 145.307724,452.824951 
	C141.964752,449.699280 142.667465,447.629974 146.462585,445.659546 
	C148.919235,444.384064 149.436096,442.634552 146.764587,440.757019 
	C146.015671,440.230652 145.593811,439.244385 145.007782,438.479919 
	C143.058395,435.937073 140.488251,436.438232 138.386414,438.854919 
	C135.692719,441.951996 135.303207,445.092529 136.449875,449.592621 
M588.654236,457.530487 
	C591.847534,457.565826 593.246643,456.272797 593.631104,452.808075 
	C594.206055,447.626190 595.699707,442.549957 596.698364,437.408569 
	C596.927917,436.226837 597.204590,434.258087 596.639404,433.881104 
	C595.399109,433.053894 593.518066,432.499847 592.120728,432.815979 
	C591.026428,433.063507 589.734375,434.661591 589.448181,435.876007 
	C588.115540,441.530579 587.275085,447.302856 585.892090,452.943115 
	C585.311707,455.309906 585.221802,456.839844 588.654236,457.530487 
M427.405457,462.230377 
	C431.839294,461.662933 432.485901,457.829712 433.409058,454.642273 
	C433.731934,453.527344 432.103485,451.847229 431.366119,450.425232 
	C429.776581,451.478912 427.184418,452.263458 426.813751,453.644348 
	C426.119934,456.229187 426.713623,459.159637 427.405457,462.230377 
z"
          />
          <path
            fill="#F6F6F6"
            opacity="1.000000"
            stroke="none"
            d="
M14.000000,540.000000 
	C9.688080,540.000000 5.376159,540.000000 1.000000,540.000000 
	C1.000000,514.979126 1.000000,489.958221 1.345270,464.507080 
	C9.335978,470.662018 13.855759,478.861450 13.940342,489.068420 
	C14.081021,506.044617 13.992181,523.022705 14.000000,540.000000 
z"
          />
          <path
            fill="#E5E5E5"
            opacity="1.000000"
            stroke="none"
            d="
M14.399996,540.000000 
	C13.992181,523.022705 14.081021,506.044617 13.940342,489.068420 
	C13.855759,478.861450 9.335978,470.662018 1.345270,464.038452 
	C1.000000,463.250000 1.000000,462.500000 1.000000,461.375000 
	C7.696927,462.952148 15.027441,472.861969 15.495977,479.452148 
	C15.933852,485.611206 17.407141,491.690491 18.133474,497.839294 
	C18.321568,499.431580 17.083656,501.150116 17.070562,502.815155 
	C16.982809,513.976013 17.029051,525.138184 17.100811,536.299500 
	C17.106976,537.258057 17.613764,538.213440 17.944000,539.585144 
	C16.933331,540.000000 15.866661,540.000000 14.399996,540.000000 
z"
          />
          <path
            fill="#F7B706"
            opacity="1.000000"
            stroke="none"
            d="
M491.390991,177.148148 
	C491.297424,177.556793 491.203827,177.965439 490.715668,178.664337 
	C489.557281,178.619522 488.793488,178.284439 488.025574,177.551483 
	C487.901245,176.005417 488.079712,174.714539 487.610748,173.732941 
	C485.194275,168.675140 482.921082,163.507492 480.005615,158.740433 
	C476.671936,153.289612 472.715546,148.219650 468.979553,142.650223 
	C468.262329,140.916046 467.595306,139.515823 466.920227,137.759476 
	C465.584747,136.280411 464.257324,135.157471 462.702393,134.015564 
	C462.474915,133.996597 462.022034,133.938873 461.990173,133.644958 
	C461.608154,132.912384 461.257996,132.473694 460.704956,131.927780 
	C460.247467,131.634430 460.063080,131.394379 459.852478,130.856857 
	C459.469360,130.427414 459.182648,130.241501 458.674438,130.036438 
	C458.452942,130.017303 458.012817,129.953979 457.902161,129.747864 
	C457.597595,129.284866 457.348907,129.102829 457.029236,128.991547 
	C457.013000,128.987457 457.003967,128.955261 456.888000,128.745361 
	C456.566772,128.274704 456.304840,128.097870 455.706055,127.861832 
	C454.291809,126.785034 453.157745,125.851402 452.011780,124.558540 
	C450.352997,120.081650 448.075714,116.759529 442.741882,116.921539 
	C442.266632,116.625237 442.076935,116.378609 441.921967,115.717072 
	C440.240265,114.580635 438.599182,113.801155 436.637878,112.976242 
	C435.188263,111.992981 434.058807,111.055161 432.916260,109.753189 
	C430.843140,106.895432 429.146667,103.538704 424.647583,106.031403 
	C423.876709,105.973503 423.436523,105.935944 422.879211,105.700287 
	C422.559021,105.258080 422.305634,105.089737 421.786102,104.891052 
	C421.296295,104.597588 421.100830,104.348564 420.997314,103.678505 
	C421.497528,102.066803 421.984314,100.814461 422.517914,99.441689 
	C419.143616,100.004318 416.063934,100.517822 412.654053,101.025810 
	C411.884003,100.999229 411.444214,100.978172 410.987610,100.567719 
	C408.571991,96.146591 404.428040,97.652504 400.553833,97.018616 
	C398.099640,96.667664 396.048737,96.320717 393.941925,95.587967 
	C390.245972,91.385353 386.112823,92.695763 381.601624,94.048187 
	C380.482758,94.003471 379.737061,93.967186 378.812012,93.701538 
	C378.079803,93.325737 377.527039,93.179291 376.987732,93.017731 
	C377.001221,93.002609 376.977020,93.038361 376.793884,92.691589 
	C371.263702,89.388298 366.144501,91.652519 360.757385,93.040176 
	C360.528320,93.053513 360.069489,93.042488 359.711121,93.027252 
	C358.874542,93.026031 358.396362,93.040047 357.518707,93.061752 
	C355.787750,93.072891 354.456268,93.076340 352.668152,93.051361 
	C348.730957,93.755890 344.208984,90.974403 341.629242,96.059532 
	C339.507843,96.388519 337.770721,96.702599 335.581238,97.007675 
	C328.687469,96.899612 322.662323,97.996620 317.771637,103.089539 
	C317.291656,103.418304 317.089630,103.674263 316.667511,104.006790 
	C315.921814,104.329170 315.461029,104.626106 314.748901,104.944534 
	C314.329712,104.975746 314.161865,104.985481 313.734070,104.996872 
	C313.301086,105.006905 313.127991,105.015259 312.610535,105.042908 
	C310.890350,105.737183 309.514557,106.412163 307.747620,107.096176 
	C305.488068,107.689636 303.291412,107.832260 301.807800,108.934570 
	C295.227600,113.823624 288.803223,118.927879 282.435120,124.093117 
	C280.741943,125.466461 279.448456,127.332588 277.630310,129.022034 
	C274.525696,132.178894 271.560425,135.134369 269.068665,138.446091 
	C267.368774,140.705338 266.330383,143.462250 264.851593,146.291443 
	C263.460419,148.389160 262.213867,150.192078 260.755676,152.111908 
	C260.280701,152.436203 260.104797,152.701553 260.015991,153.011826 
	C260.015656,152.998779 260.032257,153.024933 259.738678,153.047424 
	C259.299042,153.718155 259.153015,154.366394 258.958923,155.354218 
	C257.638977,157.479767 256.367065,159.265717 254.733459,161.033051 
	C253.242615,160.673035 252.113510,160.331635 250.990082,159.604797 
	C251.329773,157.811569 251.663788,156.403778 252.344879,154.937241 
	C255.151428,153.157471 257.467926,151.387878 255.029053,147.634796 
	C255.043701,146.533020 255.055786,145.799225 255.303070,145.051727 
	C255.538254,145.038010 256.008057,145.002472 256.224670,144.893829 
	C256.711517,144.586914 256.897430,144.328049 256.998993,144.003693 
	C256.998993,143.998779 257.008911,144.000854 257.352478,143.942108 
	C258.123077,142.907471 258.550079,141.931595 259.012573,140.629150 
	C259.682831,139.193481 260.317596,138.084351 261.314331,136.920105 
	C262.119385,135.580933 262.562469,134.296875 263.002777,133.005798 
	C263.000000,132.998779 263.014465,133.004852 263.333008,132.958206 
	C264.098999,132.267822 264.546448,131.624084 265.000793,130.740433 
	C265.007721,130.500519 265.036713,130.021362 265.382019,129.976212 
	C268.980438,127.217392 274.081879,125.848679 273.026306,119.624084 
	C288.256104,101.464012 306.900024,88.703705 329.445068,81.008682 
	C333.801941,81.155304 337.715515,81.280922 340.440125,77.107483 
	C357.361572,74.638870 373.884308,73.889587 390.756714,76.306015 
	C389.947113,74.458870 389.430542,73.280281 389.279480,72.071411 
	C391.428558,73.023865 393.212189,74.006599 395.004333,75.383331 
	C396.363983,76.865944 397.550751,78.391991 399.093109,78.971474 
	C408.380615,82.460823 417.769531,85.679131 427.079987,89.109093 
	C430.114807,90.227119 433.016083,91.707657 436.031982,93.359543 
	C436.505646,94.183830 436.878967,94.722832 437.352844,95.150345 
	C445.054321,102.098373 452.778015,109.021790 460.473846,115.976021 
	C462.669373,117.960007 464.792542,120.024124 466.997681,122.373161 
	C468.016296,123.817093 468.986084,124.939354 470.073059,126.324783 
	C470.757904,127.089523 471.325500,127.591110 471.909332,128.470886 
	C475.286163,134.240494 478.646790,139.631912 482.005554,145.010422 
	C482.003723,144.997513 481.980347,144.985825 481.997101,145.380493 
	C484.996033,154.859299 487.978180,163.943420 490.979004,173.443253 
	C491.128754,174.955368 491.259857,176.051758 491.390991,177.148148 
z"
          />
          <path
            fill="#F8B705"
            opacity="1.000000"
            stroke="none"
            d="
M471.646545,88.739136 
	C471.646545,88.739143 471.888885,88.757668 471.967773,89.062111 
	C472.136627,89.618324 472.289642,89.822998 472.586517,90.238495 
	C472.694824,90.674530 472.722260,90.852638 472.475281,91.065376 
	C471.959015,91.452896 471.717224,91.805794 471.475433,92.158691 
	C472.005371,92.415390 472.535278,92.672073 473.336395,92.934540 
	C473.786682,92.915306 473.965759,92.890312 474.369202,93.076363 
	C475.322113,94.515022 476.050629,95.742638 476.738831,96.974075 
	C476.698517,96.977913 476.678436,97.056442 476.565735,97.170410 
	C476.385101,97.635330 476.524780,97.892769 476.899353,98.300308 
	C476.926636,98.543945 476.974854,99.031883 476.776581,99.297768 
	C476.143494,100.365143 475.708679,101.166634 475.273865,101.968124 
	C476.842773,102.366783 478.411713,102.765457 480.202759,103.328690 
	C480.591827,104.020897 480.758789,104.548531 480.923035,105.438484 
	C480.974884,106.524162 481.029449,107.247528 480.845215,108.156670 
	C481.368469,108.916954 482.130493,109.491455 482.914001,110.037453 
	C482.935516,110.008957 483.004486,109.990364 482.984314,110.345566 
	C483.434509,111.397789 483.904877,112.094803 484.375244,112.791809 
	C484.617737,112.990761 484.801605,113.234009 484.949371,114.069458 
	C484.975250,115.086884 484.978577,115.556412 484.968262,116.421249 
	C485.372345,118.224770 485.790009,119.632973 486.178650,121.388504 
	C486.199158,122.425964 486.248718,123.116089 486.112335,124.001404 
	C484.951813,123.802094 483.977203,123.407608 482.993744,122.680794 
	C482.962677,121.906113 482.940491,121.463760 482.907410,120.673714 
	C481.928680,119.220215 480.960876,118.114418 479.996552,117.004463 
	C480.000000,117.000305 479.991730,117.003799 479.990173,116.618408 
	C478.983276,114.514725 477.977936,112.796448 476.728699,110.890823 
	C475.977570,109.809502 475.470306,108.915535 474.967041,107.656364 
	C473.976044,106.194107 472.981079,105.097046 471.721130,103.793640 
	C467.682220,99.250282 463.881317,94.936401 460.139740,90.571724 
	C450.543152,79.376976 438.449341,71.593025 425.473785,64.963974 
	C422.007355,63.193001 418.718262,61.074852 414.582245,58.671207 
	C413.992584,58.671207 412.124725,59.024429 410.450806,58.604546 
	C405.556854,57.376957 400.816620,55.422970 395.876038,54.520706 
	C387.783966,53.042896 379.554230,51.098000 371.431885,51.303104 
	C360.277832,51.584759 349.021240,52.972271 338.089020,55.238651 
	C329.346527,57.051071 320.181030,58.773018 312.993835,65.003624 
	C313.000061,64.999672 313.010590,64.989182 312.785767,65.098946 
	C312.370667,65.475723 312.180389,65.742737 311.994965,66.005203 
	C311.999817,66.000656 312.010284,65.992638 311.621124,65.988167 
	C309.218384,66.280693 306.977692,66.120613 305.244385,66.981766 
	C302.641296,68.275040 300.393799,70.284103 297.844604,71.842270 
	C297.462494,72.124702 297.230865,72.553673 297.000244,72.991730 
	C297.001221,73.000809 296.983276,73.003807 296.773132,73.112114 
	C296.300446,73.413345 296.115631,73.663666 295.769165,73.988556 
	C295.529816,74.005714 295.050293,74.025642 294.680511,74.023361 
	C292.800140,74.583755 291.332092,75.361481 289.771271,75.670410 
	C284.202393,76.772659 282.011719,79.327942 282.014923,85.008789 
	C282.004639,85.000305 282.016998,85.018105 281.794189,85.126770 
	C281.293823,85.439453 281.107697,85.707069 280.859436,86.337448 
	C275.293396,92.531296 269.755371,98.317291 264.519836,104.365120 
	C261.786224,107.522835 259.196381,110.934296 257.241211,114.603912 
	C254.787415,119.209435 253.050797,124.197067 250.754028,128.828308 
	C249.996811,127.748833 249.490219,126.860840 249.009506,125.659134 
	C249.103836,124.931450 249.172272,124.517479 249.564041,123.985252 
	C251.295410,119.281403 252.703445,114.695816 254.447769,109.999985 
	C255.525452,107.924065 256.266846,105.958389 257.048553,103.770546 
	C257.088837,103.548386 257.195435,103.109573 257.585388,103.068039 
	C262.751953,102.799789 260.471680,99.186836 260.975647,96.608727 
	C261.337463,95.884277 261.646240,95.435539 262.314331,94.946350 
	C263.430939,93.574547 264.188232,92.243210 265.098694,90.601898 
	C265.494873,89.193314 265.737854,88.094719 265.980865,86.996124 
	C265.980865,86.996124 265.996704,86.995857 266.253723,87.035126 
	C266.684814,87.054886 266.858887,87.035370 267.250488,86.896896 
	C267.740601,86.561134 267.905182,86.280907 268.079285,85.733467 
	C268.393341,85.275398 268.639801,85.085960 269.175537,84.869141 
	C269.599213,84.497482 269.783447,84.218048 270.011444,83.636871 
	C270.146210,82.938957 270.237244,82.542786 270.581055,82.071892 
	C271.197632,81.641602 271.561371,81.286026 271.959900,80.965919 
	C271.994659,81.001389 271.931488,80.926918 272.192657,80.788269 
	C272.644897,80.106888 272.835968,79.564171 273.088745,78.798218 
	C273.150452,78.574982 273.303101,78.137627 273.642090,78.089966 
	C275.371429,76.417595 276.761780,74.792900 278.512939,73.103378 
	C279.513062,71.959602 280.152344,70.880646 280.791626,69.801697 
	C280.791626,69.801697 281.005402,69.977394 281.289612,69.955795 
	C284.745697,69.724731 286.169128,68.024437 285.889923,64.872307 
	C285.889923,64.872307 285.931274,65.000191 286.205353,64.979691 
	C286.976593,64.643150 287.473724,64.327126 287.979492,63.997910 
	C287.988098,63.984730 288.017334,63.973053 288.237701,63.875923 
	C288.731262,63.579285 288.901062,63.313660 288.976349,62.982323 
	C288.985229,62.982731 288.967285,62.955761 289.351440,62.965405 
	C291.293884,62.065468 292.926849,61.261627 294.395050,60.224564 
	C297.572357,57.980282 300.653229,53.754086 303.795441,53.733791 
	C308.629944,53.702568 309.218201,50.617393 311.338257,47.911964 
	C312.774841,47.194359 313.926605,46.624542 315.405273,46.038933 
	C316.485535,45.678917 317.238953,45.334702 318.185425,44.777405 
	C318.916595,44.381279 319.454681,44.198231 320.090210,44.167854 
	C320.418518,44.175098 320.649353,44.029678 320.936035,43.645676 
	C321.110535,43.099041 321.171234,42.779957 321.174011,42.449844 
	C321.174011,42.449844 321.528717,42.124756 322.065186,42.074890 
	C323.067078,42.018990 323.532532,42.012955 324.340454,42.056736 
	C325.790619,42.402527 326.898285,42.698502 328.072906,43.331329 
	C329.141113,44.049377 330.142365,44.430573 331.143585,44.811771 
	C331.239288,44.375523 331.334961,43.939274 331.430634,43.503025 
	C330.284546,43.337276 329.138489,43.171532 328.092560,42.741913 
	C328.419586,41.965412 328.646454,41.452782 328.882599,40.914307 
	C328.891876,40.888466 328.939514,40.861126 329.259491,40.873772 
	C330.034149,40.500462 330.488831,40.114506 331.079926,39.661392 
	C331.216339,39.594238 331.515076,39.650761 331.913513,39.747066 
	C333.807709,39.581909 335.303497,39.320446 336.799286,39.058987 
	C336.609314,38.713509 336.419342,38.368031 336.467041,37.799248 
	C337.782349,37.365200 338.860046,37.154449 340.341736,36.989479 
	C341.826752,37.011600 342.907745,36.987949 344.179108,37.204849 
	C345.559448,36.864033 346.749451,36.282665 348.179504,35.583549 
	C348.926880,35.298752 349.434235,35.131699 350.319794,35.009178 
	C351.457764,35.049057 352.217468,35.044399 353.065277,35.397873 
	C356.414459,38.017105 358.978058,37.194889 361.323486,33.971287 
	C362.076324,33.950256 362.506256,33.938721 363.400726,33.953972 
	C367.589203,33.973984 371.313110,33.967205 375.363831,33.973419 
	C376.126678,34.010986 376.562714,34.035561 377.059479,34.436440 
	C378.584930,35.535240 380.007935,36.752773 381.522827,36.880432 
	C385.015045,37.174721 388.551910,36.939732 392.279297,36.833572 
	C392.760712,36.592701 392.959076,36.371105 393.371338,36.127560 
	C394.043793,36.232090 394.428070,36.291946 394.978058,36.518311 
	C395.409393,36.881996 395.706543,36.961658 396.048157,36.951591 
	C396.061066,36.979385 396.016846,37.021839 396.145874,37.237720 
	C396.847107,37.393707 397.419312,37.333817 398.332520,37.256077 
	C399.714020,37.524071 400.754517,37.809914 401.656067,38.186749 
	C401.694061,38.501648 401.871033,38.725555 402.341583,38.906902 
	C402.816711,38.785965 402.998291,38.707588 403.386353,38.685421 
	C403.592804,38.741631 403.979828,38.924419 404.235535,39.217896 
	C405.245422,39.505512 405.999603,39.499649 406.753815,39.493786 
	C406.753815,39.493786 406.890076,39.454372 407.089294,39.828159 
	C408.211761,40.439259 409.134949,40.676567 410.101105,40.869308 
	C410.144043,40.824734 410.267181,40.837635 410.208832,41.089973 
	C410.165344,41.513214 410.180237,41.684113 410.235413,42.267906 
	C411.821899,42.813618 413.368073,42.946438 414.914215,43.079254 
	C415.163330,42.984097 415.414185,42.893810 415.825928,43.391472 
	C415.992279,44.318413 415.999481,44.662270 415.730469,45.046127 
	C415.136810,45.379711 414.819427,45.673290 414.502075,45.966873 
	C414.704620,46.140484 414.907166,46.314095 415.109680,46.487709 
	C415.404419,45.989586 415.699127,45.491455 416.323425,45.131351 
	C417.801331,45.453915 418.949677,45.638451 420.106201,45.852333 
	C420.114410,45.881680 420.128967,45.940868 420.178833,46.110649 
	C420.481476,46.184166 420.734192,46.087906 421.297546,46.020599 
	C422.021881,46.082413 422.435638,46.115276 422.929901,46.284950 
	C423.010437,46.421768 423.119415,46.720013 423.183899,46.985001 
	C423.544525,47.438824 423.840668,47.627663 424.409790,47.796337 
	C424.850159,47.703411 425.017517,47.630657 425.418213,47.631874 
	C425.651520,47.705845 426.113007,47.869221 426.179077,48.112041 
	C426.487457,48.581226 426.729736,48.807587 427.020081,49.380630 
	C428.606354,53.444729 431.221771,54.583485 435.178772,53.093338 
	C435.393860,53.161118 435.781464,53.391777 435.709808,53.627739 
	C436.211548,54.109161 436.784912,54.354618 437.358307,54.600075 
	C437.358307,54.600075 437.326691,54.703159 437.357666,55.002422 
	C438.008545,55.494007 438.628479,55.686329 439.248413,55.878654 
	C439.248413,55.878654 439.676331,55.979282 439.911072,56.299221 
	C440.300507,56.816814 440.494659,56.961563 441.030212,57.126495 
	C441.915955,57.730255 442.499756,58.260918 443.190369,59.074154 
	C443.828583,59.589504 444.359955,59.822285 445.106262,60.160164 
	C445.599884,60.443665 445.799072,60.687134 445.686157,61.200901 
	C444.710236,65.705063 447.701904,62.515926 448.956299,63.251053 
	C449.093536,63.417263 449.324005,63.781578 449.335968,64.010513 
	C449.507782,64.565567 449.763336,64.682465 450.162018,64.640762 
	C450.209473,64.691383 450.172150,64.825035 450.243225,65.164841 
	C449.995331,67.725906 449.676300,69.947174 449.357300,72.168434 
	C449.616211,72.268051 449.875153,72.367668 450.134064,72.467293 
	C451.097046,70.645302 452.059998,68.823311 453.306152,67.147583 
	C454.790558,68.739456 455.991760,70.185074 457.256653,71.935562 
	C457.671906,72.426910 458.023499,72.613373 458.637817,72.875778 
	C459.409912,73.135406 459.919250,73.319092 460.428589,73.502785 
	C461.055115,74.263756 461.681641,75.024719 462.460602,76.175323 
	C462.613068,76.564964 462.528534,77.004860 462.229767,77.081696 
	C461.339478,78.170113 460.747925,79.181702 460.156372,80.193291 
	C460.589600,80.552254 461.022858,80.911217 461.456085,81.270172 
	C462.309814,80.385475 463.163574,79.500786 464.201019,78.561295 
	C464.384735,78.506508 464.721771,78.323738 464.721771,78.323738 
	C464.721771,78.323738 464.753540,78.322876 464.765808,78.510132 
	C464.869873,78.970062 465.049866,79.158295 465.318146,79.262085 
	C465.318146,79.262085 465.433533,79.542526 465.597046,79.773781 
	C465.875488,80.186966 466.040253,80.286537 466.254852,80.303749 
	C466.254852,80.303749 466.365631,80.506485 466.502197,80.857437 
	C466.781799,81.764854 466.924835,82.321320 466.876709,83.154724 
	C466.313690,84.195816 465.941803,84.959969 465.569916,85.724121 
	C467.152527,86.050293 468.735138,86.376457 470.317749,86.702629 
	C470.317749,86.702629 470.261414,86.705894 470.215790,87.094330 
	C470.662292,87.901550 471.154419,88.320343 471.646545,88.739136 
z"
          />
          <path
            fill="#F5B80C"
            opacity="1.000000"
            stroke="none"
            d="
M271.506104,235.657715 
	C271.133606,235.239532 270.761078,234.821335 270.265198,233.922928 
	C270.113403,233.277344 270.085022,233.111954 270.004883,232.605530 
	C270.165802,230.786453 269.943512,229.031006 270.675079,227.883942 
	C272.626617,224.824097 274.988281,222.025848 277.490997,219.012085 
	C280.534271,216.262299 283.095001,213.402435 286.045624,211.026840 
	C295.339630,203.544113 305.313354,197.275970 316.086334,191.903580 
	C333.977051,182.981689 352.792572,179.951675 372.389954,180.065308 
	C376.604462,180.089752 380.814575,180.874786 385.026703,181.309464 
	C385.124481,180.907730 385.222260,180.505997 385.320038,180.104279 
	C383.865265,179.434692 382.410461,178.765091 381.169220,177.825073 
	C383.239105,177.963348 385.095459,178.372070 387.073151,179.143250 
	C388.933990,180.344162 390.590393,181.545593 392.425659,181.965775 
	C414.421173,187.001541 433.952972,196.858185 451.041809,211.581253 
	C454.335388,214.418854 457.652740,217.228836 461.002197,220.407196 
	C462.079681,222.221832 463.038574,223.742294 464.164948,225.126465 
	C466.192993,227.618561 467.773315,230.182312 466.505493,233.565231 
	C466.505493,233.565231 466.404968,233.573944 466.127380,233.558319 
	C465.829224,233.828506 465.808655,234.114319 465.788086,234.400131 
	C465.507507,234.971466 465.226959,235.542801 464.531860,236.487274 
	C463.396790,237.322739 462.676208,237.785095 461.678467,238.045715 
	C447.536621,223.933243 431.779694,212.894730 413.171478,206.238419 
	C398.873749,201.124008 384.200256,197.721207 368.931274,198.288559 
	C360.855225,198.588623 352.762634,199.498764 344.774048,200.749924 
	C338.769409,201.690369 332.918579,203.612656 326.632141,205.078796 
	C324.143188,206.278275 322.021362,207.496796 319.503296,208.763794 
	C317.666046,209.131592 316.099976,209.189697 314.803162,209.810333 
	C300.571960,216.621017 287.299805,224.789490 276.710236,237.069794 
	C275.977539,237.381424 275.528687,237.681229 274.766754,237.931915 
	C273.471161,237.141098 272.488617,236.399414 271.506104,235.657715 
z"
          />
          <path
            fill="#F5B809"
            opacity="1.000000"
            stroke="none"
            d="
M480.909119,211.002823 
	C480.820679,211.369415 480.732239,211.736023 480.344116,212.532776 
	C479.874634,213.451492 479.704803,213.940079 479.535004,214.428665 
	C479.535004,214.428665 479.421143,214.563278 479.207794,214.744812 
	C478.680450,215.205368 478.366455,215.484375 477.799683,215.550339 
	C474.378052,211.628677 470.975128,208.093002 468.083527,204.179550 
	C458.783813,191.593445 446.759949,181.994598 433.567596,174.107101 
	C425.038422,169.007645 415.632538,165.010864 406.153992,161.972000 
	C397.770294,159.284149 388.742737,158.604614 379.531219,157.009048 
	C370.377075,157.310349 361.691162,157.641006 352.744385,157.721619 
	C339.391541,158.793167 326.951996,162.423218 314.690247,168.028503 
	C313.538116,168.009949 312.770874,168.007111 311.774780,167.736465 
	C310.952637,167.190598 310.196869,167.059753 309.791138,166.611847 
	C305.443726,161.812149 304.887299,162.097427 299.672241,165.630768 
	C294.413483,169.193726 288.582794,171.912552 282.997803,174.640427 
	C282.966125,173.797653 282.939331,173.315689 283.096008,172.552521 
	C286.273376,169.458298 289.002411,166.272217 292.320862,163.916412 
	C297.799805,160.026962 303.583649,156.545700 309.373596,153.119873 
	C323.130737,144.979996 338.144012,140.837524 353.942139,138.852737 
	C363.357971,137.669769 372.539124,137.797668 382.027802,138.685059 
	C395.846802,139.977448 408.651154,144.084717 420.928589,149.780304 
	C429.046112,153.546082 436.589478,158.763000 443.891327,164.022369 
	C454.246277,171.480820 463.923401,179.725540 470.296112,191.215836 
	C472.669495,195.495224 475.931305,199.281891 478.874939,203.658203 
	C479.610931,206.349731 480.260040,208.676285 480.909119,211.002823 
z"
          />
          <path
            fill="#F5B80B"
            opacity="1.000000"
            stroke="none"
            d="
M283.245117,248.770569 
	C283.245117,248.770569 283.040680,248.454590 282.892090,247.955643 
	C276.926788,240.972763 277.181976,241.221222 283.036346,235.207947 
	C293.885468,224.064346 307.251801,216.853653 321.243469,209.930847 
	C323.183411,209.057846 324.762665,208.220688 326.674988,207.338013 
	C327.672974,207.193558 328.337952,207.094589 329.308533,207.007507 
	C330.450500,206.681442 331.286865,206.343506 332.444336,205.790726 
	C345.985596,203.541061 358.948364,200.247025 372.612732,200.891144 
	C395.919861,201.989822 417.266632,208.765015 436.577148,221.495270 
	C444.656097,226.821228 451.664124,233.771637 459.125214,240.350296 
	C458.925171,241.676514 458.759003,242.635925 458.592804,243.595322 
	C458.307281,243.803497 458.021729,244.011688 457.250732,244.387817 
	C456.538635,245.051285 456.311981,245.546799 456.085327,246.042313 
	C455.939789,246.156433 455.794220,246.270569 455.253601,246.472412 
	C454.858490,246.560135 454.565613,246.860458 454.565613,246.860458 
	C454.565613,246.860458 454.760284,247.233032 454.760284,247.233032 
	C454.494873,247.528259 454.229431,247.823486 453.437195,248.418243 
	C451.927582,248.841843 450.944763,248.965912 449.804779,248.792953 
	C445.426636,245.327408 441.321716,241.988525 436.964447,239.020111 
	C418.931458,226.735016 398.679840,220.521759 377.164276,219.184509 
	C344.043823,217.126022 314.119263,226.213150 288.019928,247.121262 
	C286.782227,248.112793 284.852539,248.240540 283.245117,248.770569 
z"
          />
          <path
            fill="#F5B80C"
            opacity="1.000000"
            stroke="none"
            d="
M443.693817,256.696686 
	C443.693817,256.696686 443.360901,256.878052 442.839508,257.019775 
	C439.223938,259.618500 436.573669,259.128662 432.954956,257.329071 
	C424.201050,252.975662 414.993988,249.533478 405.625366,245.506165 
	C403.954132,245.143585 402.627167,244.998840 401.065155,244.648529 
	C399.865204,244.212082 398.900269,243.981216 397.591309,243.563721 
	C377.790436,239.408615 358.334656,238.332001 338.630798,243.323975 
	C337.959900,243.502625 337.539429,243.756546 336.840942,244.143082 
	C335.766998,244.485916 334.971008,244.696121 334.043823,244.792084 
	C333.649658,244.795776 333.386749,244.913712 332.839783,245.061127 
	C332.384430,245.173553 332.213074,245.256500 331.671021,245.316376 
	C322.432953,249.123962 313.606964,253.055328 304.673401,256.724762 
	C301.943146,257.846191 298.917328,258.248047 296.026855,258.979431 
	C295.556091,258.669617 295.085358,258.359802 294.277649,257.634369 
	C292.359863,255.396347 290.779022,253.573929 288.773132,251.261551 
	C292.126862,248.512436 295.222168,245.519608 298.743408,243.163284 
	C320.300385,228.737915 344.232849,221.806335 370.050262,221.894852 
	C391.159119,221.967224 410.983246,227.574249 429.303040,238.101990 
	C435.729736,241.795197 441.857941,246.007904 448.135345,250.364929 
	C448.121307,251.507401 448.094360,252.268341 448.067383,253.029266 
	C448.067413,253.029266 448.011200,253.002304 447.687378,253.048309 
	C446.140289,254.295090 444.917053,255.495895 443.693817,256.696686 
z"
          />
          <path
            fill="#F5B80C"
            opacity="1.000000"
            stroke="none"
            d="
M421.305786,270.136108 
	C421.006775,270.317474 420.707764,270.498840 419.817474,270.766296 
	C417.971008,270.506073 416.724274,270.124756 415.459290,269.818939 
	C405.470367,267.404083 395.477966,265.003601 385.245911,262.295898 
	C382.354858,261.767548 379.704529,261.542023 376.590729,261.234192 
	C371.086792,261.206970 366.046356,261.262085 360.753601,261.268494 
	C360.339874,261.276978 360.178406,261.334167 359.670776,261.338074 
	C358.860382,261.320953 358.396149,261.357147 357.592865,261.274994 
	C356.142181,261.394501 355.030548,261.632324 353.928589,261.926697 
	C353.938232,261.983246 353.904358,261.867737 353.637207,261.902344 
	C353.092987,262.159027 352.815948,262.381073 352.126526,262.560974 
	C349.483154,262.698669 347.252136,262.878540 344.655457,263.013763 
	C343.028412,263.508667 341.767059,264.048248 340.311829,264.556763 
	C339.848724,264.524841 339.598694,264.593719 339.130524,264.574554 
	C338.212830,264.577545 337.532501,264.738312 336.464355,264.914429 
	C331.687073,266.419312 327.297546,267.908783 322.702881,269.384216 
	C322.219452,269.476318 322.035553,269.671570 321.976990,269.970123 
	C322.007935,269.984344 321.959076,269.935944 321.623444,269.950928 
	C320.533844,270.368713 319.779846,270.771515 319.025879,271.174347 
	C318.046875,271.029907 317.067902,270.885468 315.561707,270.383881 
	C313.769226,269.261444 312.411957,268.613190 311.258423,267.705872 
	C309.044769,265.964691 306.946777,264.076477 304.800781,262.249237 
	C307.036438,260.535797 309.061737,258.383606 311.547516,257.191925 
	C318.768738,253.730011 326.174500,250.653107 333.910889,247.389893 
	C335.939209,247.048630 337.564453,246.742645 339.415985,246.538879 
	C340.048859,246.412811 340.455444,246.184525 341.299377,245.952423 
	C342.575867,245.719040 343.414978,245.489471 344.399719,245.334534 
	C344.779694,245.365356 344.953979,245.238602 345.495850,245.047028 
	C358.896179,244.733963 371.868805,244.402725 385.144226,244.251633 
	C386.592438,244.515213 387.737823,244.598663 389.277161,244.789154 
	C391.292999,244.998917 392.914856,245.101624 394.766724,245.513321 
	C397.000946,246.227600 399.005127,246.632889 401.358948,247.128479 
	C403.096130,247.653915 404.483673,248.089035 406.111084,248.761246 
	C415.566986,252.685623 425.082153,255.789047 433.160889,262.172485 
	C431.428955,263.910583 430.079132,265.265228 428.729309,266.619843 
	C428.729309,266.619843 428.705963,266.689209 428.407837,266.778137 
	C427.399902,267.215973 426.690125,267.564911 425.728577,267.972717 
	C425.252991,268.281555 425.029114,268.531525 424.805267,268.781494 
	C424.114990,268.972534 423.424744,269.163574 422.223633,269.414917 
	C421.346710,269.553345 421.211060,269.773621 421.305786,270.136108 
z"
          />
          <path
            fill="#41416A"
            opacity="1.000000"
            stroke="none"
            d="
M465.181580,330.065643 
	C465.223206,348.596619 465.223206,366.721100 465.223206,385.219604 
	C462.850311,385.219604 460.798859,385.219604 458.447784,385.219604 
	C458.265442,383.622314 458.087524,382.063477 457.901337,380.432648 
	C445.634766,387.797363 434.674957,387.108917 427.856812,378.655640 
	C421.445648,370.706940 421.751740,358.009308 428.533539,350.584381 
	C435.527893,342.926819 447.053925,342.499664 457.793152,349.782440 
	C457.793152,342.816833 457.793152,336.096924 457.793152,328.523529 
	C460.569183,328.952637 462.854584,329.305908 465.181580,330.065643 
M431.119141,368.813477 
	C431.340179,369.438629 431.526917,370.078461 431.787445,370.686676 
	C434.717285,377.527222 441.435272,380.796906 448.452637,378.809509 
	C454.711029,377.037048 458.683044,370.468048 457.909668,363.169312 
	C457.139435,355.900391 451.921722,351.051361 444.727661,350.918671 
	C435.481384,350.748138 430.583740,356.840515 431.119141,368.813477 
z"
          />
          <path
            fill="#F5B70F"
            opacity="1.000000"
            stroke="none"
            d="
M402.493927,277.433197 
	C402.146362,277.520386 401.798828,277.607574 400.968567,277.804443 
	C400.325104,277.961945 400.164368,278.009735 399.750824,278.075745 
	C399.232391,278.288849 398.966797,278.483795 398.701202,278.678741 
	C398.033386,278.766327 397.365570,278.853912 395.949860,278.940765 
	C379.902069,281.764252 364.510406,282.213806 349.135803,279.976654 
	C342.301514,278.982178 335.610107,277.005646 328.853851,275.474884 
	C328.446198,275.351257 328.038574,275.227631 327.130280,274.793121 
	C324.179901,272.832520 325.465973,271.827606 327.325562,271.260468 
	C332.504150,269.681030 337.725586,268.242035 343.265198,266.817078 
	C344.543579,266.422363 345.486877,265.958191 346.720215,265.472748 
	C347.585846,265.368317 348.161346,265.285156 348.908203,265.313232 
	C349.450073,265.331360 349.820648,265.238251 350.609741,265.164124 
	C352.697357,265.037781 354.366455,264.892426 356.424988,264.830994 
	C358.571350,264.635498 360.328339,264.356110 362.539886,264.102661 
	C366.657410,264.110107 370.320343,264.091644 374.224976,264.286194 
	C375.313171,264.587463 376.159668,264.675751 377.452637,264.877136 
	C389.915985,265.070618 401.319580,267.820007 413.300812,272.711029 
	C409.045776,274.570312 405.769867,276.001740 402.493927,277.433197 
z"
          />
          <path
            fill="#3F4068"
            opacity="1.000000"
            stroke="none"
            d="
M303.410400,362.638184 
	C308.581604,357.897003 313.736206,353.651428 318.300598,348.845337 
	C321.791382,345.169678 325.590057,344.053406 330.860535,345.194641 
	C325.201080,350.284546 319.751770,355.185425 314.052155,360.311462 
	C321.039551,369.266815 327.004822,376.912109 333.320129,385.006073 
	C328.052002,386.177795 324.227570,385.844513 321.125977,381.366638 
	C317.639282,376.332703 313.528961,371.730713 309.738068,367.000854 
	C301.583496,370.511108 300.223694,377.164185 301.363678,385.164703 
	C298.563934,385.164703 296.363007,385.164703 293.802185,385.164703 
	C293.802185,366.545868 293.802185,348.002106 293.802185,329.110138 
	C295.986084,329.110138 298.228638,329.110138 301.017914,329.110138 
	C301.017914,339.622375 300.979340,350.131622 301.083405,360.639404 
	C301.092804,361.588745 299.707367,364.708954 303.410400,362.638184 
z"
          />
          <path
            fill="#48476E"
            opacity="1.000000"
            stroke="none"
            d="
M266.693665,385.825867 
	C248.105682,386.322021 239.946777,372.791016 243.685989,358.673340 
	C246.489716,348.087585 256.463043,342.685181 267.983276,344.936035 
	C277.792969,346.852631 283.786011,355.725494 282.839722,367.513245 
	C272.324310,367.513245 261.750366,367.513245 250.938217,367.513245 
	C250.717880,372.641174 253.333923,375.645569 257.025513,377.303101 
	C263.823822,380.355560 270.538788,380.117645 276.581116,374.655579 
	C277.970062,376.114502 279.199554,377.405914 280.581451,378.857391 
	C277.199738,383.785645 272.206146,384.865875 266.693665,385.825867 
M260.101044,350.982574 
	C254.426910,352.310516 251.378006,355.935364 250.443314,361.755432 
	C259.071381,361.755432 267.390228,361.755432 275.783508,361.755432 
	C274.105560,353.462311 269.896423,350.471558 260.101044,350.982574 
z"
          />
          <path
            fill="#47456C"
            opacity="1.000000"
            stroke="none"
            d="
M482.964081,353.446899 
	C478.172119,355.942078 479.275940,351.216766 477.539062,350.035767 
	C482.446930,344.239380 497.305756,341.978668 504.293640,346.733459 
	C507.469177,348.894226 509.192108,353.396790 511.127075,357.072327 
	C511.907562,358.554962 511.593109,360.666595 511.603485,362.492950 
	C511.645844,369.955627 511.621155,377.418701 511.621155,384.772858 
	C505.464722,386.278717 505.361237,386.238556 503.698273,381.768738 
	C497.888428,385.879822 491.530548,387.030426 484.762573,384.908142 
	C479.065735,383.121735 475.971069,378.828949 476.170868,373.439178 
	C476.374634,367.942871 479.496307,363.907837 485.630493,362.898041 
	C491.488770,361.933594 497.515045,361.989868 503.498077,361.595459 
	C504.442627,355.374847 501.501221,351.069305 495.365326,351.094727 
	C491.328369,351.111420 487.296967,352.480347 482.964081,353.446899 
M491.252625,367.394257 
	C489.804230,367.730835 488.258881,367.850250 486.925934,368.445404 
	C483.194092,370.111725 482.552002,375.242676 485.627319,377.962799 
	C488.561310,380.557922 495.319580,380.818604 499.273071,378.403870 
	C503.393341,375.887177 505.239777,372.426514 503.528748,367.288757 
	C499.692657,367.288757 495.907928,367.288757 491.252625,367.394257 
z"
          />
          <path
            fill="#434269"
            opacity="1.000000"
            stroke="none"
            d="
M355.983215,361.991638 
	C359.113464,361.991272 361.765747,361.991272 364.393677,361.991272 
	C365.400421,350.595428 356.281219,347.704224 340.941711,354.587524 
	C340.079254,353.116730 339.192474,351.604401 338.340637,350.151672 
	C344.810425,343.391083 360.347198,341.930298 366.875671,348.036407 
	C369.510712,350.501007 371.557190,354.742096 371.847992,358.338318 
	C372.555450,367.087341 372.078339,375.932129 372.078339,384.699738 
	C366.475128,386.348267 365.994293,386.163177 364.677032,381.682159 
	C359.313904,385.153595 353.607910,386.905090 347.233826,385.310242 
	C340.400604,383.600494 336.943756,379.720276 336.993652,373.691589 
	C337.043945,367.621979 340.512878,363.812897 347.558197,362.514221 
	C350.151184,362.036285 352.853058,362.148804 355.983215,361.991638 
M357.562256,367.167664 
	C354.448517,367.489990 351.201233,367.362915 348.252014,368.239044 
	C343.722870,369.584503 342.879578,375.131683 346.450684,378.134644 
	C349.375916,380.594452 356.159760,380.812408 360.149078,378.333496 
	C364.170807,375.834412 366.041229,372.321533 364.460541,367.166718 
	C362.405975,367.166718 360.447906,367.166718 357.562256,367.167664 
z"
          />
          <path
            fill="#434269"
            opacity="1.000000"
            stroke="none"
            d="
M154.990952,385.240845 
	C147.382019,382.625305 144.431732,378.742126 145.026566,372.553345 
	C145.568985,366.909943 149.539810,363.051605 156.695679,362.328247 
	C161.939575,361.798096 167.280151,362.224396 172.537216,362.224396 
	C173.260178,350.541260 163.910675,347.641083 148.970703,354.595703 
	C148.106842,353.101776 147.225479,351.577545 146.378784,350.113281 
	C153.067123,343.220367 168.936493,341.924103 175.291168,348.349548 
	C177.919205,351.006866 179.630707,355.514282 179.940338,359.327972 
	C180.623734,367.745575 180.152740,376.256866 180.152740,384.985565 
	C176.737274,385.888763 173.414185,387.091492 172.574158,381.590668 
	C167.196350,385.406891 161.581802,386.819366 154.990952,385.240845 
M167.058472,378.898590 
	C171.789978,376.567749 173.991821,373.000488 172.471695,366.925385 
	C167.448547,367.236877 162.280182,367.086395 157.348984,368.095184 
	C155.379410,368.498138 152.705902,371.385345 152.592239,373.276306 
	C152.477524,375.184723 154.769318,378.374512 156.668915,379.003571 
	C159.592422,379.971771 163.081223,379.232971 167.058472,378.898590 
z"
          />
          <path
            fill="#434269"
            opacity="1.000000"
            stroke="none"
            d="
M579.404175,353.597351 
	C571.966675,348.527466 565.210205,351.069672 558.004211,354.736206 
	C557.089539,353.092957 556.164246,351.430481 555.061584,349.449432 
	C563.536255,343.999146 572.232422,342.504700 581.346802,346.272766 
	C586.355530,348.343475 588.952637,352.649780 589.004761,358.158539 
	C589.088257,366.981903 589.027161,375.806610 589.027161,384.570587 
	C583.612732,386.438507 582.976929,386.201294 581.663269,381.704559 
	C575.815491,385.652039 569.517578,387.015686 562.706360,384.938782 
	C556.767883,383.127930 553.813843,379.085876 554.006775,373.149567 
	C554.176880,367.915466 557.224243,363.899689 563.120300,362.935333 
	C569.141724,361.950470 575.332520,362.001434 582.455750,361.532684 
	C581.493774,358.905365 580.578857,356.406647 579.404175,353.597351 
M567.968811,379.932098 
	C578.418701,380.210938 583.132019,375.846558 581.327393,367.459717 
	C577.078308,367.459717 572.801025,367.307617 568.540222,367.507019 
	C565.025024,367.671570 561.762085,368.832916 561.365295,372.965546 
	C560.981445,376.963226 563.628601,378.879822 567.968811,379.932098 
z"
          />
          <path
            fill="#43446A"
            opacity="1.000000"
            stroke="none"
            d="
M409.391937,371.499390 
	C406.185791,378.670654 403.420868,385.640106 400.050781,392.303528 
	C395.468689,401.363586 384.485626,403.428894 376.859650,396.757812 
	C377.584167,395.234894 378.344757,393.636047 379.273102,391.684601 
	C380.624542,392.313690 381.775482,393.322418 382.878754,393.272705 
	C385.972626,393.133362 389.786163,393.537628 391.945007,391.909668 
	C394.755249,389.790497 396.695343,386.368134 394.537598,381.883942 
	C389.711548,371.854370 385.420471,361.567352 380.914001,351.384064 
	C380.058929,349.451813 379.215393,347.514465 378.293488,345.412964 
	C383.697845,343.547394 386.380249,345.191437 388.298492,350.222198 
	C391.590668,358.856262 395.646637,367.199066 399.971161,376.999512 
	C402.074585,372.119781 403.670898,368.418121 405.265839,364.715912 
	C407.369537,359.832672 409.805695,355.059967 411.478485,350.033295 
	C413.047150,345.319397 415.658539,343.649994 420.947296,345.367706 
	C417.138489,353.997772 413.350983,362.579529 409.391937,371.499390 
z"
          />
          <path
            fill="#41416A"
            opacity="1.000000"
            stroke="none"
            d="
M205.789398,353.676910 
	C201.290390,357.700226 201.341705,362.808960 201.232101,367.793091 
	C201.104904,373.577087 201.202454,379.365997 201.202454,385.316162 
	C198.631729,385.316162 196.552338,385.316162 194.222626,385.316162 
	C194.222626,371.783630 194.222626,358.555084 194.222626,345.059784 
	C196.365524,345.059784 198.452103,345.059784 200.860291,345.059784 
	C200.971832,346.586304 201.085403,348.140656 201.245346,350.329620 
	C205.975677,345.442078 211.469559,343.881348 217.439819,344.392212 
	C226.577438,345.174103 231.881683,350.302368 232.380386,359.424255 
	C232.841614,367.860687 232.478653,376.342163 232.478653,385.209991 
	C230.031189,385.209991 227.831055,385.209991 225.142059,385.209991 
	C225.142059,378.219910 225.158493,371.441437 225.138519,364.663055 
	C225.101028,351.950531 217.200027,347.289581 205.789398,353.676910 
z"
          />
          <path
            fill="#3F4068"
            opacity="1.000000"
            stroke="none"
            d="
M106.465836,374.257111 
	C108.784828,375.910370 110.646301,377.779785 112.891235,378.601257 
	C119.522636,381.027710 125.090889,377.989594 125.474388,370.996643 
	C126.064522,360.235474 125.629646,349.418152 125.629646,338.300690 
	C119.389847,338.300690 113.250793,338.300690 106.747833,338.300690 
	C106.747833,336.097260 106.747833,334.212036 106.747833,332.021637 
	C115.514915,332.021637 124.255867,332.021637 132.975128,332.021637 
	C132.975128,345.999390 133.737625,359.819305 132.739395,373.510864 
	C131.906509,384.934753 117.315140,389.832916 106.387810,383.116028 
	C104.569656,381.998474 102.972282,380.521729 100.384666,378.525879 
	C102.525452,376.852966 104.354507,375.423676 106.465836,374.257111 
z"
          />
          <path
            fill="#F8DD86"
            opacity="1.000000"
            stroke="none"
            d="
M448.122437,249.983398 
	C441.857941,246.007904 435.729736,241.795197 429.303040,238.101990 
	C410.983246,227.574249 391.159119,221.967224 370.050262,221.894852 
	C344.232849,221.806335 320.300385,228.737915 298.743408,243.163284 
	C295.222168,245.519608 292.126862,248.512436 288.773132,251.261551 
	C290.779022,253.573929 292.359863,255.396347 294.005707,257.552795 
	C290.446472,255.084564 286.822235,252.282333 283.221558,249.125336 
	C284.852539,248.240540 286.782227,248.112793 288.019928,247.121262 
	C314.119263,226.213150 344.043823,217.126022 377.164276,219.184509 
	C398.679840,220.521759 418.931458,226.735016 436.964447,239.020111 
	C441.321716,241.988525 445.426636,245.327408 449.645630,249.012451 
	C449.136627,249.680466 448.629517,249.831924 448.122437,249.983398 
z"
          />
          <path
            fill="#47456C"
            opacity="1.000000"
            stroke="none"
            d="
M526.249878,373.537598 
	C526.215393,365.822540 526.215393,358.555847 526.215393,351.109070 
	C523.869263,350.950684 522.080872,350.829956 519.705017,350.669556 
	C519.599915,348.981995 519.492126,347.252075 519.354919,345.048767 
	C521.501831,344.927460 523.429749,344.818512 525.924377,344.677582 
	C526.042419,342.016296 526.163269,339.289490 526.301819,336.164490 
	C528.743896,336.164490 531.093994,336.164490 533.637329,336.164490 
	C533.815063,339.073547 533.983948,341.838684 534.168518,344.859802 
	C537.878540,344.859802 541.313599,344.859802 544.994507,344.859802 
	C544.994507,346.883392 544.994507,348.622986 544.994507,350.791840 
	C541.341003,350.791840 537.929199,350.791840 534.078064,350.791840 
	C534.078064,359.122009 533.851990,367.044220 534.185120,374.942841 
	C534.325745,378.277466 537.099792,379.552094 540.305237,379.218445 
	C542.088135,379.032837 543.836121,378.512085 545.865967,378.086578 
	C546.600525,379.612183 547.294189,381.052826 548.107056,382.741180 
	C543.651672,386.295563 538.931396,386.593109 533.978577,385.036469 
	C528.634644,383.356873 526.635925,379.211792 526.249878,373.537598 
z"
          />
          <path
            fill="#F5B80C"
            opacity="1.000000"
            stroke="none"
            d="
M474.667542,222.796143 
	C474.233032,223.364182 473.798553,223.932220 472.987488,224.905457 
	C472.131073,225.685989 471.651245,226.061325 470.793518,226.358459 
	C469.156708,225.354477 467.752258,224.571823 466.670380,223.471909 
	C464.675934,221.444214 462.870941,219.230209 460.833679,216.788330 
	C452.312958,207.543091 442.302704,200.682861 432.577423,193.973495 
	C435.160400,193.698837 437.345062,193.416748 439.363342,193.156143 
	C438.605804,191.013672 438.097260,189.575409 437.588684,188.137146 
	C439.503357,188.435867 441.418030,188.734589 444.113495,189.155121 
	C444.291138,188.526093 444.656372,187.232941 445.328003,186.087189 
	C447.069122,187.316040 448.694916,188.219131 449.902374,189.512711 
	C453.401886,193.261642 456.733307,197.167404 459.813751,200.878525 
	C457.199615,198.650742 454.905334,196.582260 453.031616,201.814224 
	C455.936890,202.046326 457.552216,208.316208 461.497437,202.805420 
	C468.192322,207.111832 472.025452,213.277054 474.976746,220.400818 
	C474.823029,221.425034 474.745300,222.110596 474.667542,222.796143 
z"
          />
          <path
            fill="#F9D060"
            opacity="1.000000"
            stroke="none"
            d="
M315.075134,168.044235 
	C326.951996,162.423218 339.391541,158.793167 352.741333,157.994568 
	C353.004700,158.699417 353.010254,158.881241 352.744446,159.326050 
	C350.883392,160.051208 349.314270,160.819092 347.700775,160.927872 
	C332.909210,161.925247 319.920715,168.339584 307.385193,175.270966 
	C291.642975,183.975433 278.232635,195.609787 267.727142,210.529709 
	C261.749573,219.019058 261.312775,218.711487 266.002563,227.367523 
	C266.024139,227.970734 266.043213,228.314468 266.062256,228.658203 
	C262.377075,222.932480 258.105652,217.490021 255.165268,211.404434 
	C251.982544,204.817291 250.041611,197.630157 247.862946,190.360199 
	C248.433807,190.005798 248.700897,190.000137 249.148163,190.260376 
	C249.870483,190.835983 250.412735,191.149933 251.015442,191.747650 
	C251.442215,192.761047 251.808517,193.490707 252.151993,194.630402 
	C252.027100,198.113556 251.301025,201.390656 253.455276,202.971313 
	C255.654037,201.299561 257.250854,200.085464 258.905914,199.254669 
	C259.013458,200.151627 259.062744,200.665298 259.029419,201.543823 
	C255.796158,206.898865 256.111389,210.811630 260.067535,215.565094 
	C261.718994,213.385162 263.366699,211.210144 265.303314,208.875229 
	C267.062042,206.809601 268.531891,204.903885 270.253845,202.800919 
	C271.341431,201.399567 272.176880,200.195480 273.236450,198.897095 
	C273.737885,198.605194 273.915009,198.340515 273.997742,198.006195 
	C274.003601,198.003632 274.008545,197.992081 274.232056,197.895447 
	C274.735931,197.602478 274.911469,197.336838 274.992920,197.002533 
	C275.003754,197.003159 275.001465,196.981674 275.221741,196.883636 
	C275.718384,196.591446 275.894897,196.329895 275.987793,196.002457 
	C276.004059,196.003998 276.000580,195.971512 276.222229,195.876892 
	C276.720490,195.590057 276.901062,195.330917 276.994568,195.003998 
	C277.003510,195.003174 277.001465,194.985504 277.254272,194.833633 
	C277.726044,194.142075 277.945007,193.602386 278.424805,192.917175 
	C279.450684,192.138550 280.215698,191.505417 281.292603,190.762512 
	C283.804382,188.152924 286.004242,185.653107 288.551819,183.140198 
	C290.603699,182.089508 292.307831,181.051941 294.235718,179.917084 
	C294.748779,179.635864 294.935913,179.377792 295.026306,179.029892 
	C295.031708,179.014191 295.056793,179.036148 295.371979,179.005402 
	C296.472015,178.334747 297.256836,177.694870 298.034668,177.030029 
	C298.027649,177.005081 298.055481,177.055771 298.403320,177.007355 
	C302.811066,174.630829 306.871002,172.302704 311.157532,169.886353 
	C311.684967,169.644211 311.906097,169.413834 312.380554,169.078781 
	C313.500763,168.715149 314.287933,168.379684 315.075134,168.044235 
z"
          />
          <path
            fill="#F9DC83"
            opacity="1.000000"
            stroke="none"
            d="
M329.002899,206.995636 
	C328.337952,207.094589 327.672974,207.193558 326.470459,207.168121 
	C326.288330,206.395111 326.643799,205.746475 326.999268,205.097839 
	C332.918579,203.612656 338.769409,201.690369 344.774048,200.749924 
	C352.762634,199.498764 360.855225,198.588623 368.931274,198.288559 
	C384.200256,197.721207 398.873749,201.124008 413.171478,206.238419 
	C431.779694,212.894730 447.536621,223.933243 461.575989,238.246277 
	C461.500336,239.025253 461.250000,239.401947 460.999695,239.778656 
	C460.999695,239.778671 460.636688,239.979309 460.175049,240.021011 
	C459.528595,240.036285 459.343811,240.009872 459.159058,239.983475 
	C451.664124,233.771637 444.656097,226.821228 436.577148,221.495270 
	C417.266632,208.765015 395.919861,201.989822 372.612732,200.891144 
	C358.948364,200.247025 345.985596,203.541061 332.093689,205.783951 
	C330.615570,206.326553 329.809235,206.661087 329.002899,206.995636 
z"
          />
          <path
            fill="#F9D060"
            opacity="1.000000"
            stroke="none"
            d="
M475.052673,220.062164 
	C472.025452,213.277054 468.192322,207.111832 461.378113,202.587128 
	C460.654510,201.863724 460.392609,201.437683 460.130707,201.011642 
	C456.733307,197.167404 453.401886,193.261642 449.902374,189.512711 
	C448.694916,188.219131 447.069122,187.316040 445.140198,185.842148 
	C443.524841,184.917542 442.403717,184.385391 441.173523,183.521484 
	C438.421417,181.432281 435.778412,179.674820 432.976227,177.635300 
	C431.900452,176.864273 430.983826,176.375336 429.904907,175.616577 
	C428.847687,174.852051 427.952789,174.357361 426.802856,173.629471 
	C415.346588,169.409119 404.155396,165.393448 392.936676,161.456085 
	C390.337189,160.543793 387.643829,159.898880 384.791473,158.833450 
	C383.059326,158.036789 381.529388,157.537613 379.999451,157.038422 
	C388.742737,158.604614 397.770294,159.284149 406.153992,161.972000 
	C415.632538,165.010864 425.038422,169.007645 433.567596,174.107101 
	C446.759949,181.994598 458.783813,191.593445 468.083527,204.179550 
	C470.975128,208.093002 474.378052,211.628677 477.681183,215.837601 
	C477.245789,217.234253 476.676117,218.130615 476.106445,219.026978 
	C476.095093,219.195343 476.083740,219.363708 475.800781,219.781982 
	C475.529144,220.031906 475.052673,220.062164 475.052673,220.062164 
z"
          />
          <path
            fill="#EFBD2D"
            opacity="1.000000"
            stroke="none"
            d="
M125.287483,444.749634 
	C129.156555,445.875183 130.201889,449.060150 129.154251,451.533966 
	C128.142441,453.923279 125.444771,456.375824 122.966003,457.167725 
	C119.949127,458.131531 116.344261,457.685791 113.084129,457.218811 
	C111.973640,457.059753 111.136963,454.989136 110.175171,453.791931 
	C111.650223,452.919067 113.041618,451.578156 114.622200,451.295654 
	C116.465118,450.966217 118.473404,451.561981 120.409935,451.756287 
	C120.498856,451.159821 120.587776,450.563385 120.676697,449.966919 
	C119.541389,449.473724 118.445137,448.838745 117.263618,448.513336 
	C113.416336,447.453705 111.909798,444.527832 113.190971,441.290649 
	C114.120918,438.940948 116.571373,436.520599 118.921082,435.591309 
	C121.349091,434.631012 124.535347,435.108307 127.266975,435.616913 
	C128.462433,435.839539 129.352341,437.703125 130.380981,438.821564 
	C128.988373,439.546295 127.665199,440.579468 126.184479,440.912750 
	C124.563744,441.277557 122.797462,440.995758 121.094620,440.995758 
	C120.959633,441.427979 120.824646,441.860168 120.689667,442.292389 
	C122.102356,443.070068 123.515045,443.847748 125.287483,444.749634 
z"
          />
          <path
            fill="#EEBB28"
            opacity="1.000000"
            stroke="none"
            d="
M580.081543,451.799011 
	C577.772339,456.845032 573.999817,457.956726 569.206299,457.259369 
	C566.628845,456.884430 563.982910,456.980560 560.183289,456.811340 
	C561.741211,449.602142 563.144409,442.561829 564.917908,435.616089 
	C565.132263,434.776794 567.458130,433.860687 568.600464,434.074524 
	C569.471802,434.237671 570.130005,436.083221 570.667236,437.277405 
	C570.976501,437.964874 570.856567,438.845459 570.923340,439.568024 
	C581.221985,441.681610 581.730408,442.344391 580.081543,451.799011 
M574.441406,447.029297 
	C572.646301,446.844177 570.718384,446.216461 569.122681,446.695526 
	C568.499329,446.882660 568.330933,449.600098 568.548401,451.077850 
	C568.636292,451.675262 570.937195,452.641968 571.158813,452.411102 
	C572.504761,451.009460 573.541138,449.310669 574.441406,447.029297 
z"
          />
          <path
            fill="#EEBB28"
            opacity="1.000000"
            stroke="none"
            d="
M602.414307,443.421478 
	C602.656616,443.215149 602.887390,442.996643 603.530273,442.515167 
	C604.330200,441.886871 604.706543,441.509308 605.082886,441.131775 
	C605.082886,441.131775 604.992676,441.025085 605.479492,441.071777 
	C609.198486,441.253906 612.564941,442.024597 615.631775,441.376862 
	C620.305786,440.389618 620.380676,442.376190 619.585327,445.796265 
	C619.333069,446.880951 619.410461,448.042297 619.336914,449.168579 
	C619.255615,449.873596 619.174316,450.578583 618.821655,451.863831 
	C618.305054,453.552277 618.059875,454.660492 617.814697,455.768677 
	C617.814697,455.768677 617.784546,455.826050 617.588806,455.997070 
	C617.180054,456.445892 617.126770,456.751587 617.233398,457.085175 
	C617.128113,457.461487 617.022888,457.837769 616.539917,458.595459 
	C614.988342,460.162933 613.814514,461.349030 612.640686,462.535095 
	C610.055664,462.691803 607.470581,462.848480 604.230957,462.727417 
	C602.872620,461.373199 602.168884,460.296692 601.465149,459.220184 
	C602.630554,458.972504 603.869873,458.342987 604.946899,458.551605 
	C607.734070,459.091492 610.202087,459.419678 611.596802,456.197357 
	C608.136475,455.296814 605.226440,454.539429 602.316345,453.782043 
	C602.016235,453.316162 601.716187,452.850250 601.262024,451.766937 
	C601.543396,448.573486 601.978821,445.997498 602.414307,443.421478 
z"
          />
          <path
            fill="#EDBD31"
            opacity="1.000000"
            stroke="none"
            d="
M176.041092,462.243439 
	C174.749573,457.012451 178.825058,457.737366 182.097427,457.001038 
	C181.119171,452.826263 179.998138,448.889435 179.364182,444.875671 
	C179.143387,443.477600 179.711395,440.993286 180.640121,440.563660 
	C183.005722,439.469391 185.181793,440.391296 186.030151,443.228760 
	C186.388565,444.427460 186.780090,445.616272 187.446259,447.727783 
	C189.194824,445.168671 190.182861,443.016907 191.807510,441.589996 
	C192.912964,440.619110 194.909775,440.663086 196.507996,440.253296 
	C196.652527,441.866394 197.492554,443.892242 196.822189,445.021698 
	C193.789062,450.132294 190.437576,455.066742 186.988220,459.913391 
	C184.259445,463.747467 180.452866,464.103363 176.041092,462.243439 
z"
          />
          <path
            fill="#EFBD2D"
            opacity="1.000000"
            stroke="none"
            d="
M165.722229,440.582703 
	C173.679810,439.361328 174.082184,439.785767 172.326691,447.113586 
	C172.210754,447.597595 172.032822,448.066864 171.919388,448.551361 
	C169.935028,457.027466 169.936249,457.027710 161.439713,457.463226 
	C156.384811,457.722351 152.905319,455.128967 152.598663,450.873657 
	C152.215958,445.563049 155.599976,440.825867 160.337708,440.306580 
	C161.958771,440.128876 163.637054,440.473389 165.722229,440.582703 
M159.119781,450.392029 
	C160.786118,450.803314 162.608200,451.791473 164.057861,451.400177 
	C164.871765,451.180481 165.468475,448.655792 165.381607,447.216736 
	C165.336472,446.469086 162.917877,445.077545 162.725174,445.251923 
	C161.326614,446.517365 160.222244,448.107941 159.119781,450.392029 
z"
          />
          <path
            fill="#EFBD2D"
            opacity="1.000000"
            stroke="none"
            d="
M299.275940,454.551727 
	C299.462891,452.672333 299.828156,451.175354 299.718323,449.714081 
	C299.637146,448.633789 298.937042,447.599976 298.512665,446.545471 
	C297.492401,447.190674 296.188782,447.631775 295.534241,448.540405 
	C294.920593,449.392242 294.920563,450.729645 294.777191,451.869293 
	C294.034882,457.768921 292.944611,458.374634 287.150848,456.164398 
	C287.719391,452.636505 288.295990,449.147369 288.840973,445.653320 
	C289.444183,441.786285 291.343231,439.612305 295.580780,440.288940 
	C296.554199,440.444366 297.617004,440.179504 298.547607,440.437103 
	C301.215057,441.175446 305.970337,441.841339 306.097443,442.966003 
	C306.591888,447.341034 306.385834,452.158600 304.920746,456.269958 
	C303.858978,459.249329 300.777069,458.388794 299.275940,454.551727 
z"
          />
          <path
            fill="#EEBB28"
            opacity="1.000000"
            stroke="none"
            d="
M262.556030,452.274292 
	C262.674255,451.762268 262.727356,451.600372 262.737335,451.435822 
	C263.398682,440.541870 265.738007,438.670074 276.611176,440.324036 
	C279.815430,440.811432 281.915039,442.250916 281.486908,445.902313 
	C281.216705,448.206635 281.045074,450.529114 280.639282,452.809631 
	C279.773560,457.675079 279.133636,457.986084 273.624329,456.418762 
	C273.950317,453.054260 274.281219,449.638977 274.654053,445.790955 
	C269.800354,446.299133 269.610352,449.371796 269.361755,452.012207 
	C268.889893,457.024658 266.352600,458.098267 261.629303,456.435913 
	C261.922913,455.105591 262.196838,453.864349 262.556030,452.274292 
z"
          />
          <path
            fill="#EFBD2D"
            opacity="1.000000"
            stroke="none"
            d="
M238.937729,444.090027 
	C241.865128,440.406891 245.586487,439.371796 249.457703,440.414246 
	C251.893097,441.070038 255.299835,442.702637 255.869324,444.613007 
	C256.692108,447.372955 256.226471,451.326508 254.715286,453.793640 
	C252.085861,458.086365 245.059753,458.895996 240.797302,456.346039 
	C237.287979,454.246674 236.422424,449.344452 238.937729,444.090027 
M247.308792,452.116760 
	C248.222046,450.436554 249.273911,448.806183 249.913864,447.027649 
	C250.022141,446.726776 247.975342,445.066833 247.581192,445.275543 
	C246.268005,445.970856 244.521561,447.115906 244.334625,448.307251 
	C244.154068,449.457855 245.699448,450.879242 247.308792,452.116760 
z"
          />
          <path
            fill="#F8EBC6"
            opacity="1.000000"
            stroke="none"
            d="
M352.032776,456.126556 
	C350.726349,457.079254 349.442139,458.796204 348.109100,458.834930 
	C344.716705,458.933533 341.255676,458.500519 337.927246,457.788818 
	C337.003174,457.591217 336.421967,455.691162 335.727722,454.545349 
	C335.033997,453.400421 334.395691,452.221924 333.733093,451.058136 
	C332.913269,452.204010 332.098389,453.353455 331.272217,454.494690 
	C330.463165,455.612305 329.871429,457.115570 328.778229,457.750580 
	C326.646973,458.988586 324.232208,459.738586 321.548065,460.850922 
	C318.815948,456.320465 314.752350,459.818359 310.874847,460.872711 
	C310.531372,460.488708 310.187866,460.104736 309.844391,459.720734 
	C310.997040,458.380737 312.149719,457.040741 313.688843,455.251465 
	C312.541412,452.977997 311.136261,450.193939 309.986420,447.915649 
	C311.292267,446.385254 312.579834,444.516388 314.224609,443.047546 
	C315.950928,441.505920 317.971863,440.268402 319.966156,439.067474 
	C320.612122,438.678528 321.786224,439.106873 322.368774,438.681793 
	C327.364136,435.036804 330.051544,438.696625 332.274078,442.123779 
	C334.000519,444.786011 335.681366,445.459015 338.694458,444.459229 
	C337.452850,448.385010 336.811493,452.594360 340.054718,454.762756 
	C342.649811,456.497864 346.712891,456.063965 350.135162,456.510559 
	C350.740692,456.589508 351.398895,456.264832 352.032776,456.126556 
M313.956696,450.356720 
	C315.706116,456.434174 321.751404,459.235382 327.121277,456.358978 
	C327.836914,455.975647 328.219147,454.969818 328.755768,454.252258 
	C327.763733,453.592102 326.846832,452.597931 325.761200,452.353607 
	C324.297089,452.024109 322.703094,452.271759 321.164825,452.271759 
	C321.149811,451.721954 321.134796,451.172150 321.119751,450.622345 
	C323.447327,450.488068 325.774261,450.257263 328.102631,450.242096 
	C331.619598,450.219147 332.777924,448.389862 331.226471,445.507782 
	C330.254883,443.702881 328.477631,441.671600 326.642853,441.109497 
	C319.884979,439.039032 315.660187,442.070404 313.956696,450.356720 
z"
          />
          <path
            fill="#F6C232"
            opacity="1.000000"
            stroke="none"
            d="
M251.004654,129.019791 
	C253.050797,124.197067 254.787415,119.209435 257.241211,114.603912 
	C259.196381,110.934296 261.786224,107.522835 264.519836,104.365120 
	C269.755371,98.317291 275.293396,92.531296 281.077301,86.222015 
	C281.722534,85.605057 281.911957,85.341965 282.016998,85.018105 
	C282.016998,85.018105 282.004639,85.000305 282.332062,84.866997 
	C284.528778,83.805054 286.398041,82.876419 288.267334,81.947784 
	C288.587311,82.396706 288.907288,82.845627 289.227264,83.294548 
	C288.170593,84.617744 287.272827,86.118439 286.028748,87.232368 
	C281.466858,91.316994 276.452545,94.953255 272.259857,99.375748 
	C268.719208,103.110466 265.573639,107.430054 263.151550,111.970055 
	C258.438538,120.804153 254.355148,129.974152 249.731232,139.254456 
	C249.169174,142.166687 249.036545,144.860168 248.501282,147.471115 
	C248.364166,148.139893 246.955002,148.547897 245.954620,148.747101 
	C245.401337,147.491837 245.023697,146.565155 244.646057,145.638489 
	C244.743637,144.022156 244.841202,142.405823 245.245728,140.154922 
	C246.363480,137.684662 247.174286,135.848953 248.328186,133.953156 
	C249.449081,132.268631 250.226868,130.644211 251.004654,129.019791 
z"
          />
          <path
            fill="#EEBB28"
            opacity="1.000000"
            stroke="none"
            d="
M420.976685,451.004578 
	C420.688507,451.789398 420.400299,452.574219 419.919250,453.973572 
	C418.152252,455.448547 416.578094,456.308990 415.003967,457.169434 
	C414.253174,457.095642 413.502411,457.021820 411.963501,456.989410 
	C409.770081,457.068359 408.364777,457.105865 406.959442,457.143372 
	C406.651428,457.003265 406.343384,456.863129 405.621826,456.373535 
	C402.154846,452.999725 401.506348,449.452698 403.367493,445.652679 
	C406.106415,440.060455 411.189423,440.013000 416.777069,441.161682 
	C416.536713,436.333038 418.575562,434.123199 422.870758,434.233398 
	C422.967743,434.620422 423.064728,435.007446 423.149902,436.074646 
	C422.417633,441.504761 421.697174,446.254669 420.976685,451.004578 
M414.471802,450.634521 
	C414.126678,448.867706 413.781555,447.100861 413.436401,445.334015 
	C411.987549,445.782410 409.967804,445.811401 409.246063,446.793915 
	C408.483093,447.832611 408.956299,449.779358 408.889496,451.329468 
	C410.606293,451.302460 412.323059,451.275421 414.471802,450.634521 
z"
          />
          <path
            fill="#EDBD31"
            opacity="1.000000"
            stroke="none"
            d="
M510.880005,456.993011 
	C505.983002,458.320679 503.615509,456.595642 503.146637,451.850220 
	C502.870667,449.057617 501.826721,446.323578 501.717896,443.540100 
	C501.672485,442.378845 502.949066,440.503387 504.013855,440.160126 
	C504.953094,439.857269 507.001892,440.910889 507.579010,441.924500 
	C510.320007,446.738464 510.391449,446.650848 513.953369,442.233887 
	C515.057007,440.865295 517.672485,440.715912 519.595825,440.008362 
	C519.454285,441.615356 519.813660,443.513733 519.079346,444.775879 
	C516.668640,448.919678 513.860901,452.832458 510.880005,456.993011 
z"
          />
          <path
            fill="#EEBB28"
            opacity="1.000000"
            stroke="none"
            d="
M479.056061,456.885284 
	C478.219421,456.045898 477.382812,455.206543 476.329285,453.827820 
	C475.716217,452.522888 475.320038,451.757233 474.923859,450.991608 
	C474.969299,450.552185 475.014740,450.112762 475.300598,449.117889 
	C476.852814,446.138336 478.164581,443.714203 479.476379,441.290100 
	C479.476410,441.290100 479.767944,441.267944 480.243713,441.180542 
	C481.817719,440.740265 482.915985,440.387360 484.014221,440.034424 
	C484.454468,440.050751 484.894714,440.067047 485.925964,440.316132 
	C489.260498,440.504456 492.004059,440.459991 494.747620,440.415527 
	C494.747620,440.415527 494.845581,440.741730 494.919373,441.287537 
	C493.982361,446.882019 492.971527,451.930725 491.960693,456.979401 
	C491.960693,456.979401 491.492493,457.045502 490.868530,457.059296 
	C489.142853,457.107544 488.041138,457.141998 486.939423,457.176453 
	C486.202698,457.150787 485.465973,457.125122 483.960785,457.121368 
	C481.813568,457.057281 480.434814,456.971283 479.056061,456.885284 
M482.627258,445.999420 
	C482.464355,447.884430 482.301483,449.769440 482.138611,451.654449 
	C483.466217,451.761749 485.396790,452.456757 485.989929,451.848206 
	C487.068451,450.741669 487.352325,448.860626 487.967743,447.302734 
	C486.396912,446.717651 484.826050,446.132538 482.627258,445.999420 
z"
          />
          <path
            fill="#F9E7AB"
            opacity="1.000000"
            stroke="none"
            d="
M373.983307,264.073242 
	C370.320343,264.091644 366.657410,264.110107 362.137573,264.054779 
	C359.532318,264.236389 357.783966,264.491730 356.035583,264.747101 
	C354.366455,264.892426 352.697357,265.037781 350.244263,265.138977 
	C349.219147,265.130554 348.977997,265.166260 348.736877,265.202026 
	C348.161346,265.285156 347.585846,265.368317 346.429474,265.234985 
	C345.572784,264.365143 345.296967,263.711761 345.021118,263.058411 
	C347.252136,262.878540 349.483154,262.698669 352.417297,262.582764 
	C353.381744,262.387085 353.643066,262.127411 353.904358,261.867737 
	C353.904358,261.867737 353.938232,261.983246 354.280090,261.983246 
	C355.725281,261.786621 356.828583,261.589996 357.931915,261.393372 
	C358.396149,261.357147 358.860382,261.320953 359.780151,261.441772 
	C360.235687,261.598785 360.671021,261.614716 360.671021,261.614716 
	C360.671021,261.614716 361.005920,261.317230 361.005920,261.317230 
	C366.046356,261.262085 371.086792,261.206970 376.886963,261.451050 
	C380.260040,262.033081 382.873322,262.315887 385.486633,262.598694 
	C395.477966,265.003601 405.470367,267.404083 415.459290,269.818939 
	C416.724274,270.124756 417.971008,270.506073 419.601624,270.915405 
	C417.421082,272.408112 414.973816,274.107147 412.279602,275.194214 
	C409.405151,276.354004 406.297333,276.935547 402.892670,277.601349 
	C405.769867,276.001740 409.045776,274.570312 413.300812,272.711029 
	C401.319580,267.820007 389.915985,265.070618 377.307861,264.631470 
	C375.805481,264.206177 374.894379,264.139709 373.983307,264.073242 
z"
          />
          <path
            fill="#EEBB28"
            opacity="1.000000"
            stroke="none"
            d="
M136.482758,449.162476 
	C135.303207,445.092529 135.692719,441.951996 138.386414,438.854919 
	C140.488251,436.438232 143.058395,435.937073 145.007782,438.479919 
	C145.593811,439.244385 146.015671,440.230652 146.764587,440.757019 
	C149.436096,442.634552 148.919235,444.384064 146.462585,445.659546 
	C142.667465,447.629974 141.964752,449.699280 145.307724,452.824951 
	C146.020752,453.491608 146.356232,455.910278 145.985992,456.147217 
	C144.595551,457.037018 142.752136,457.988617 141.239624,457.775146 
	C136.508438,457.107361 135.873520,453.605682 136.482758,449.162476 
z"
          />
          <path
            fill="#F9DC83"
            opacity="1.000000"
            stroke="none"
            d="
M320.882812,209.966675 
	C307.251801,216.853653 293.885468,224.064346 283.036346,235.207947 
	C277.181976,241.221222 276.926788,240.972763 282.789764,247.797745 
	C281.684967,247.611450 280.201172,247.374557 279.442169,246.505020 
	C276.593658,243.241791 273.981750,239.772018 271.393646,236.018692 
	C272.488617,236.399414 273.471161,237.141098 275.052399,237.939896 
	C276.098755,237.683975 276.546417,237.370972 276.994080,237.057983 
	C287.299805,224.789490 300.571960,216.621017 314.803162,209.810333 
	C316.099976,209.189697 317.666046,209.131592 319.609070,209.018188 
	C320.368347,209.471634 320.625580,209.719147 320.882812,209.966675 
z"
          />
          <path
            fill="#F7E099"
            opacity="1.000000"
            stroke="none"
            d="
M333.507843,247.425186 
	C326.174500,250.653107 318.768738,253.730011 311.547516,257.191925 
	C309.061737,258.383606 307.036438,260.535797 304.800781,262.249237 
	C306.946777,264.076477 309.044769,265.964691 311.258423,267.705872 
	C312.411957,268.613190 313.769226,269.261444 315.196411,270.317627 
	C310.557983,268.490051 305.659607,266.561462 301.005188,264.160126 
	C299.123962,263.189545 297.775635,261.185974 296.105835,259.315948 
	C298.917328,258.248047 301.943146,257.846191 304.673401,256.724762 
	C313.606964,253.055328 322.432953,249.123962 331.731110,245.638931 
	C332.610535,246.464767 333.059204,246.944962 333.507843,247.425186 
z"
          />
          <path
            fill="#EDBD31"
            opacity="1.000000"
            stroke="none"
            d="
M232.839569,444.989685 
	C232.650772,445.190826 232.455795,445.385803 231.755676,445.740723 
	C229.690826,445.831299 228.120560,445.648590 226.560440,445.712799 
	C225.208359,445.768463 223.190201,445.653961 222.680557,446.421143 
	C221.911133,447.579437 221.725204,449.620544 222.226669,450.927551 
	C222.493988,451.624329 224.722580,451.774017 226.070908,451.791534 
	C227.356949,451.808258 228.649368,451.332764 229.938995,451.073669 
	C229.938995,451.073669 230.002045,451.020905 230.057587,451.305664 
	C230.649994,452.084167 231.186874,452.577911 231.723755,453.071655 
	C231.211411,453.839355 230.699051,454.607025 229.656784,455.697937 
	C225.374268,455.829010 221.204132,456.541260 218.037766,455.079407 
	C216.347427,454.299011 215.413071,449.594635 215.871948,446.963806 
	C216.621368,442.667175 220.401825,440.389526 224.649826,440.384125 
	C227.913956,440.379974 231.854950,440.392975 232.839569,444.989685 
z"
          />
          <path
            fill="#F8EBC6"
            opacity="1.000000"
            stroke="none"
            d="
M348.993713,440.001282 
	C352.200928,439.052368 355.367767,437.880463 358.639557,437.288452 
	C359.640015,437.107422 360.928589,438.519073 362.443756,439.412842 
	C362.658112,438.111603 362.894135,436.678864 363.154541,435.097961 
	C364.995819,435.540710 366.750031,435.962494 368.377808,436.708618 
	C363.950928,436.890656 361.871155,440.989075 361.588196,443.292877 
	C361.059784,447.595032 362.735046,452.167816 363.493347,456.627991 
	C362.347290,454.884125 360.673157,453.273682 360.190277,451.362244 
	C359.600586,449.027924 360.214020,446.414825 359.899750,443.974731 
	C359.750275,442.814362 358.657104,441.775543 357.821991,440.403137 
	C356.281128,444.333435 355.111267,447.317505 353.831299,450.582397 
	C354.864655,450.837830 356.210663,451.170532 357.492188,451.487305 
	C357.722473,454.005188 357.929291,456.266510 358.194061,459.161438 
	C356.395874,457.943298 354.899536,456.929626 353.168335,455.519623 
	C354.544678,450.920532 352.063080,451.221252 349.183289,451.519043 
	C347.578094,451.685059 345.717987,451.569550 344.364777,450.846893 
	C343.867584,450.581390 343.977142,447.554565 344.738861,446.941711 
	C345.850983,446.046844 347.798401,446.177338 349.394897,445.900391 
	C351.238190,445.580688 353.087036,445.293121 354.933472,444.991608 
	C354.904663,444.436768 354.875824,443.881927 354.846985,443.327057 
	C352.895874,442.218475 350.944794,441.109894 348.993713,440.001282 
z"
          />
          <path
            fill="#EFBD2D"
            opacity="1.000000"
            stroke="none"
            d="
M348.665863,440.043793 
	C350.944794,441.109894 352.895874,442.218475 354.846985,443.327057 
	C354.875824,443.881927 354.904663,444.436768 354.933472,444.991608 
	C353.087036,445.293121 351.238190,445.580688 349.394897,445.900391 
	C347.798401,446.177338 345.850983,446.046844 344.738861,446.941711 
	C343.977142,447.554565 343.867584,450.581390 344.364777,450.846893 
	C345.717987,451.569550 347.578094,451.685059 349.183289,451.519043 
	C352.063080,451.221252 354.544678,450.920532 352.802917,455.428436 
	C352.672363,455.733551 352.324890,455.884460 352.178833,456.005493 
	C351.398895,456.264832 350.740692,456.589508 350.135162,456.510559 
	C346.712891,456.063965 342.649811,456.497864 340.054718,454.762756 
	C336.811493,452.594360 337.452850,448.385010 339.064667,444.434082 
	C339.314331,443.803314 339.509277,443.567413 340.173767,443.040833 
	C342.771637,441.853333 344.894409,440.951904 347.017212,440.050537 
	C347.457489,440.062469 347.897736,440.074371 348.665863,440.043793 
z"
          />
          <path
            fill="#F7E099"
            opacity="1.000000"
            stroke="none"
            d="
M429.095398,266.710754 
	C430.079132,265.265228 431.428955,263.910583 433.160889,262.172485 
	C425.082153,255.789047 415.566986,252.685623 406.141357,248.403992 
	C405.944366,247.114441 405.956970,246.419220 405.969604,245.723999 
	C414.993988,249.533478 424.201050,252.975662 432.954956,257.329071 
	C436.573669,259.128662 439.223938,259.618500 442.693298,257.140442 
	C442.650696,258.154449 442.528625,259.689911 441.765594,260.140900 
	C437.753479,262.512146 433.579376,264.609344 429.095398,266.710754 
z"
          />
          <path
            fill="#E8C152"
            opacity="1.000000"
            stroke="none"
            d="
M588.294067,457.475464 
	C585.221802,456.839844 585.311707,455.309906 585.892090,452.943115 
	C587.275085,447.302856 588.115540,441.530579 589.448181,435.876007 
	C589.734375,434.661591 591.026428,433.063507 592.120728,432.815979 
	C593.518066,432.499847 595.399109,433.053894 596.639404,433.881104 
	C597.204590,434.258087 596.927917,436.226837 596.698364,437.408569 
	C595.699707,442.549957 594.206055,447.626190 593.631104,452.808075 
	C593.246643,456.272797 591.847534,457.565826 588.294067,457.475464 
M590.837036,454.993011 
	C591.502991,450.782806 592.168884,446.572632 592.908203,441.898743 
	C591.515076,442.268707 590.711914,442.309235 590.664307,442.520264 
	C589.765015,446.504883 588.916382,450.502991 588.226807,454.527405 
	C588.187195,454.758759 589.596008,455.238342 590.837036,454.993011 
z"
          />
          <path
            fill="#F8EBC6"
            opacity="1.000000"
            stroke="none"
            d="
M455.317780,443.263245 
	C456.751129,441.850830 458.060669,439.425995 459.643066,439.232452 
	C463.155304,438.802917 469.427246,438.556641 469.906464,439.900360 
	C471.351654,443.952362 471.093201,448.872131 470.484802,453.314667 
	C469.991730,456.915222 462.614075,460.058380 458.503967,459.110504 
	C456.899048,458.740387 455.103394,458.530731 453.817322,457.638611 
	C452.609985,456.801117 451.023529,454.972534 451.236511,453.906036 
	C451.949158,450.337006 453.392792,446.914001 454.902527,443.762146 
	C453.860870,450.013031 459.227539,450.114105 462.438446,452.356018 
	C460.368164,452.735809 458.478058,452.505829 456.613434,452.637146 
	C455.795349,452.694733 454.582306,453.340912 454.366089,453.991516 
	C454.153076,454.632538 454.746185,455.919495 455.381195,456.397522 
	C457.858765,458.262604 464.981323,456.867035 467.362244,454.398804 
	C470.957062,450.672180 466.969757,448.966461 465.500854,446.840790 
	C466.860138,445.231049 468.126923,443.730774 469.552063,442.042999 
	C463.859985,439.929321 461.561829,440.180206 455.317780,443.263245 
z"
          />
          <path
            fill="#F9D365"
            opacity="1.000000"
            stroke="none"
            d="
M526.180725,443.040985 
	C529.297607,439.295013 533.245178,438.622467 537.617371,440.203461 
	C540.635681,441.294922 543.950195,442.714691 542.712219,446.807068 
	C541.642090,450.344543 540.628174,454.954193 538.007263,456.662415 
	C535.524414,458.280640 530.985535,456.744232 527.708740,456.233826 
	C535.668213,456.735840 535.668213,456.735840 536.550415,453.898651 
	C534.092407,453.498383 531.575867,453.088593 529.608459,452.768219 
	C529.963684,449.610931 530.194153,447.562378 530.427307,445.490540 
	C532.239075,445.490540 534.420715,445.490540 537.271118,445.490540 
	C536.279968,446.939606 535.601807,447.931030 534.923645,448.922485 
	C535.104126,449.291168 535.284607,449.659851 535.465088,450.028534 
	C537.081238,449.433197 539.791565,449.137085 540.055481,448.171967 
	C540.445374,446.746429 539.564270,444.353119 538.396606,443.240967 
	C535.205994,440.201843 531.530212,440.460968 527.681030,442.969360 
	C526.980408,443.003448 526.580566,443.022217 526.180725,443.040985 
z"
          />
          <path
            fill="#F0B813"
            opacity="1.000000"
            stroke="none"
            d="
M492.562805,158.040497 
	C492.687836,159.414169 492.812866,160.787857 492.671387,162.775940 
	C492.134979,163.947220 491.865082,164.504089 491.445068,165.370590 
	C489.528656,160.480499 487.852386,155.805862 485.854004,151.273239 
	C484.870209,149.041870 483.290436,147.073303 481.980347,144.985825 
	C481.980347,144.985825 482.003723,144.997513 481.996155,144.628021 
	C480.984985,141.183350 479.981384,138.108170 479.336090,135.057587 
	C483.348572,136.737228 482.999237,134.115799 483.408417,132.020203 
	C485.831207,136.408234 487.905090,140.707932 489.987976,145.004089 
	C489.996979,145.000565 489.989594,145.017151 490.090271,145.246246 
	C490.441833,145.692108 490.692657,145.908890 490.940460,146.311874 
	C490.937408,146.498093 490.951324,146.870331 490.945740,146.893921 
	C490.940186,146.917511 490.910950,146.951691 490.615021,146.996506 
	C490.223724,148.032181 490.128357,149.023026 490.026245,150.359100 
	C490.601288,151.513702 491.183075,152.323090 491.806427,153.514633 
	C491.913269,154.920166 491.978546,155.943527 492.056946,157.176514 
	C492.141388,157.674240 492.305634,157.892365 492.562805,158.040497 
z"
          />
          <path
            fill="#EEBB28"
            opacity="1.000000"
            stroke="none"
            d="
M363.637512,456.677612 
	C362.735046,452.167816 361.059784,447.595032 361.588196,443.292877 
	C361.871155,440.989075 363.950928,436.890656 368.633728,436.953857 
	C369.016022,436.874847 369.443085,437.033356 369.708191,437.432312 
	C370.943237,438.927887 371.913208,440.024536 372.883179,441.121185 
	C372.883179,441.121185 372.997559,440.998779 372.949097,441.439636 
	C372.113770,443.043549 371.553650,444.500488 370.497894,445.315033 
	C367.215027,447.847900 367.318115,450.989014 370.956299,453.020813 
	C370.956299,453.020813 370.988953,453.010223 371.001007,453.423584 
	C371.016205,454.896332 371.019348,455.955719 371.022522,457.015106 
	C371.022522,457.015106 371.000946,456.998779 370.569214,457.008240 
	C368.098419,456.984100 366.059357,456.950531 364.020294,456.916931 
	C364.020294,456.916931 363.781647,456.727234 363.637512,456.677612 
z"
          />
          <path
            fill="#F7E6A5"
            opacity="1.000000"
            stroke="none"
            d="
M328.984802,275.779114 
	C335.610107,277.005646 342.301514,278.982178 349.135803,279.976654 
	C364.510406,282.213806 379.902069,281.764252 395.624268,279.028259 
	C394.595184,280.008545 393.195099,281.572144 391.684418,281.687592 
	C378.221466,282.716248 364.894257,284.714447 351.194183,282.307648 
	C343.488922,280.953979 336.089661,279.567688 328.984802,275.779114 
z"
          />
          <path
            fill="#EEBA1A"
            opacity="1.000000"
            stroke="none"
            d="
M377.960632,448.983398 
	C378.221954,448.474060 378.483276,447.964752 379.138184,447.093872 
	C379.604004,446.142792 379.676239,445.553284 379.876526,444.703552 
	C380.439270,443.803986 380.873993,443.164581 381.644226,442.424866 
	C383.018433,441.781647 384.057129,441.238708 385.095856,440.695770 
	C385.492645,440.671234 385.889435,440.646729 386.931030,440.720337 
	C390.102509,442.196594 392.629242,443.574738 395.093262,445.355194 
	C395.162018,449.213348 393.813110,450.575226 390.190857,449.703278 
	C387.533722,449.063660 384.449493,448.492828 383.918640,452.997253 
	C383.075439,453.789795 382.524963,454.434631 381.672485,454.924377 
	C380.233826,452.840637 379.097229,450.912018 377.960632,448.983398 
z"
          />
          <path
            fill="#F0B813"
            opacity="1.000000"
            stroke="none"
            d="
M527.981750,442.953979 
	C531.530212,440.460968 535.205994,440.201843 538.396606,443.240967 
	C539.564270,444.353119 540.445374,446.746429 540.055481,448.171967 
	C539.791565,449.137085 537.081238,449.433197 535.465088,450.028534 
	C535.284607,449.659851 535.104126,449.291168 534.923645,448.922485 
	C535.601807,447.931030 536.279968,446.939606 537.271118,445.490540 
	C534.420715,445.490540 532.239075,445.490540 530.427307,445.490540 
	C530.194153,447.562378 529.963684,449.610931 529.608459,452.768219 
	C531.575867,453.088593 534.092407,453.498383 536.550415,453.898651 
	C535.668213,456.735840 535.668213,456.735840 527.675415,455.857910 
	C526.973328,455.685303 526.662476,455.527313 526.182007,454.842224 
	C525.651428,453.557007 525.290466,452.798828 524.927246,451.770630 
	C524.892395,451.323608 524.859802,451.146606 524.827393,450.644775 
	C524.924072,448.612030 525.020508,446.904083 525.116943,445.196136 
	C525.296326,444.916168 525.484741,444.642517 526.145081,444.185608 
	C527.065735,443.648682 527.523743,443.301331 527.981750,442.953979 
z"
          />
          <path
            fill="#F8EBC6"
            opacity="1.000000"
            stroke="none"
            d="
M422.867981,433.863098 
	C418.575562,434.123199 416.536713,436.333038 416.777069,441.161682 
	C411.189423,440.013000 406.106415,440.060455 403.367493,445.652679 
	C401.506348,449.452698 402.154846,452.999725 405.370117,456.336029 
	C404.191864,455.755005 402.823120,454.901703 401.519592,453.958252 
	C400.153442,452.969482 398.849915,451.894226 397.192444,450.389893 
	C396.901184,448.289734 396.936462,446.656464 396.971771,445.023254 
	C398.887665,444.290802 401.177521,443.995636 402.645905,442.739929 
	C406.331299,439.588318 410.189484,437.612122 415.608612,438.903137 
	C415.180878,437.545349 414.874756,436.573547 414.456116,435.244598 
	C417.470215,435.273621 418.955566,429.602264 422.867981,433.863098 
z"
          />
          <path
            fill="#F8EBC6"
            opacity="1.000000"
            stroke="none"
            d="
M233.140854,444.779205 
	C231.854950,440.392975 227.913956,440.379974 224.649826,440.384125 
	C220.401825,440.389526 216.621368,442.667175 215.871948,446.963806 
	C215.413071,449.594635 216.347427,454.299011 218.037766,455.079407 
	C221.204132,456.541260 225.374268,455.829010 229.525681,455.946289 
	C228.578201,458.562134 222.073563,460.524628 219.040024,458.768677 
	C210.884827,454.048157 210.428680,446.918488 217.048798,441.288605 
	C221.249161,437.716431 226.780746,437.225037 231.807083,440.276184 
	C232.840057,440.903198 232.921890,443.097046 233.140854,444.779205 
z"
          />
          <path
            fill="#F9D365"
            opacity="1.000000"
            stroke="none"
            d="
M244.342590,145.879639 
	C245.023697,146.565155 245.401337,147.491837 245.935104,149.138123 
	C246.094162,150.907608 246.097061,151.957474 245.847290,153.167038 
	C245.448730,153.886185 245.302872,154.445633 245.113449,155.405319 
	C245.099243,156.876297 245.128601,157.947052 245.128769,159.342087 
	C245.078445,160.099380 245.057297,160.532410 244.991394,161.372406 
	C245.291519,163.550720 245.636414,165.322052 246.005692,167.372955 
	C246.326294,168.105682 246.622513,168.558853 246.923798,169.417358 
	C246.975464,173.535004 247.022095,177.247314 247.158783,181.232880 
	C247.807205,182.013763 248.365585,182.521393 248.935791,183.463470 
	C248.954437,185.931534 248.961258,187.965118 248.968063,189.998718 
	C248.700897,190.000137 248.433807,190.005798 247.974136,190.029083 
	C243.669067,177.128006 242.899612,163.826233 243.256744,150.405533 
	C243.294891,148.971359 243.767853,147.548752 244.342590,145.879639 
z"
          />
          <path
            fill="#F7E6A5"
            opacity="1.000000"
            stroke="none"
            d="
M342.930084,266.747620 
	C337.725586,268.242035 332.504150,269.681030 327.325562,271.260468 
	C325.465973,271.827606 324.179901,272.832520 326.811462,274.760193 
	C325.029205,274.236786 323.065063,273.435425 321.167542,272.030457 
	C321.475800,270.929871 321.717438,270.432922 321.959076,269.935944 
	C321.959076,269.935944 322.007935,269.984344 322.166016,269.929291 
	C322.570801,269.779388 322.765472,269.620728 322.908051,269.398254 
	C327.297546,267.908783 331.687073,266.419312 336.885620,264.940887 
	C338.252441,264.878754 338.810150,264.805542 339.367889,264.732330 
	C339.598694,264.593719 339.848724,264.524841 340.655945,264.682617 
	C341.772675,265.475525 342.351379,266.111572 342.930084,266.747620 
z"
          />
          <path
            fill="#EDBD31"
            opacity="1.000000"
            stroke="none"
            d="
M427.088593,462.088318 
	C426.713623,459.159637 426.119934,456.229187 426.813751,453.644348 
	C427.184418,452.263458 429.776581,451.478912 431.366119,450.425232 
	C432.103485,451.847229 433.731934,453.527344 433.409058,454.642273 
	C432.485901,457.829712 431.839294,461.662933 427.088593,462.088318 
z"
          />
          <path
            fill="#F9CD4E"
            opacity="1.000000"
            stroke="none"
            d="
M481.997101,145.380493 
	C483.290436,147.073303 484.870209,149.041870 485.854004,151.273239 
	C487.852386,155.805862 489.528656,160.480499 491.445068,165.370590 
	C491.865082,164.504089 492.134979,163.947220 492.619995,163.189117 
	C492.918060,164.047943 493.001038,165.107986 492.990051,166.876862 
	C492.882324,168.006104 492.868561,168.426514 492.854828,168.846924 
	C492.854828,168.846924 492.850739,168.893448 492.605225,169.135818 
	C491.893280,170.594635 491.426819,171.811081 490.960327,173.027527 
	C487.978180,163.943420 484.996033,154.859299 481.997101,145.380493 
z"
          />
          <path
            fill="#EEBB28"
            opacity="1.000000"
            stroke="none"
            d="
M311.053436,48.059761 
	C309.218201,50.617393 308.629944,53.702568 303.795441,53.733791 
	C300.653229,53.754086 297.572357,57.980282 294.395050,60.224564 
	C292.926849,61.261627 291.293884,62.065468 289.362030,62.660553 
	C289.015594,61.940105 289.042755,61.534145 289.069885,61.128189 
	C294.253723,57.548309 299.437531,53.968433 305.083282,50.150059 
	C305.747162,49.798195 305.890381,49.633709 305.974854,49.418095 
	C306.357208,49.253002 306.739532,49.087910 307.779846,48.922874 
	C309.309662,48.635208 310.181549,48.347485 311.053436,48.059761 
z"
          />
          <path
            fill="#F8EBC6"
            opacity="1.000000"
            stroke="none"
            d="
M381.308716,442.525177 
	C380.873993,443.164581 380.439270,443.803986 379.652710,444.714783 
	C379.300873,444.986176 378.853485,445.025482 378.853485,445.025482 
	C377.571442,445.025482 376.289368,445.025482 375.686890,445.025482 
	C373.921783,447.769653 372.470764,450.025482 370.988037,452.651062 
	C367.318115,450.989014 367.215027,447.847900 370.497894,445.315033 
	C371.553650,444.500488 372.113770,443.043549 372.889404,441.498718 
	C375.297668,441.086975 377.717194,441.057007 380.631897,441.411926 
	C381.187622,442.039612 381.248169,442.282410 381.308716,442.525177 
z"
          />
          <path
            fill="#EEBB28"
            opacity="1.000000"
            stroke="none"
            d="
M490.951324,146.870331 
	C490.951324,146.870331 490.937408,146.498093 490.861511,146.066025 
	C490.520264,145.428345 490.254944,145.222748 489.989594,145.017151 
	C489.989594,145.017151 489.996979,145.000565 489.994202,144.600098 
	C487.661804,137.137466 485.332214,130.075302 483.002625,123.013123 
	C483.977203,123.407608 484.951813,123.802094 486.389313,124.049774 
	C487.232788,123.936287 487.613342,123.969604 488.036469,124.303612 
	C488.128998,125.006081 488.178986,125.407867 488.181976,126.122269 
	C488.411835,126.964783 488.688629,127.494675 489.002502,128.241211 
	C489.039581,128.457840 489.095001,128.893860 489.068298,129.269012 
	C489.387787,130.752792 489.734009,131.861435 490.272217,133.030029 
	C490.464203,133.089981 490.863129,133.141510 490.863129,133.141510 
	C490.919250,133.534042 490.975372,133.926575 491.009827,135.037018 
	C491.368652,139.151566 491.749146,142.548172 491.977417,146.154785 
	C491.533875,146.533295 491.242615,146.701813 490.951324,146.870331 
z"
          />
          <path
            fill="#F7E099"
            opacity="1.000000"
            stroke="none"
            d="
M401.795013,38.095757 
	C400.754517,37.809914 399.714020,37.524071 398.016052,37.138546 
	C396.911316,37.033188 396.464081,37.027515 396.016846,37.021839 
	C396.016846,37.021839 396.061066,36.979385 395.905945,36.749580 
	C395.469757,36.286888 395.156891,36.230896 394.812317,36.351803 
	C394.428070,36.291946 394.043793,36.232090 393.147705,36.033173 
	C392.635834,35.894115 392.162598,35.794415 391.810425,35.606148 
	C386.678284,34.721027 381.898315,34.024181 376.754883,33.241127 
	C375.896210,33.184273 375.401093,33.213627 374.477783,33.204350 
	C372.329346,33.045486 370.609131,32.925251 368.888885,32.805012 
	C375.773071,33.023785 382.737244,32.676090 389.517212,33.633148 
	C395.377197,34.460339 401.027863,36.770157 406.237915,38.694649 
	C405.129791,38.951290 404.554810,38.937855 403.979828,38.924419 
	C403.979828,38.924419 403.592804,38.741631 403.234009,38.522495 
	C402.635223,38.108719 402.362152,37.990459 401.979309,37.965141 
	C401.902649,37.981709 401.795013,38.095760 401.795013,38.095757 
z"
          />
          <path
            fill="#F7E099"
            opacity="1.000000"
            stroke="none"
            d="
M443.083557,58.791580 
	C442.499756,58.260918 441.915955,57.730255 441.068512,56.896866 
	C440.804871,56.594147 440.865723,56.133156 440.865723,56.133156 
	C443.841553,57.590954 447.317078,58.495213 449.681732,60.629982 
	C453.720276,64.275894 457.062164,68.693474 460.562622,73.144859 
	C459.919250,73.319092 459.409912,73.135406 458.490814,72.633591 
	C457.785065,72.087212 457.489014,71.858948 457.192963,71.630692 
	C455.991760,70.185074 454.790558,68.739456 453.188904,66.846680 
	C451.916351,65.874695 451.044250,65.349861 450.172150,64.825035 
	C450.172150,64.825035 450.209473,64.691383 450.075348,64.469917 
	C449.772247,64.044258 449.566498,63.888634 449.324005,63.781578 
	C449.324005,63.781578 449.093536,63.417263 448.840271,62.967361 
	C447.697601,62.010193 446.808167,61.502934 445.918732,60.995667 
	C445.799072,60.687134 445.599884,60.443665 444.988129,59.891235 
	C444.131226,59.275333 443.607391,59.033455 443.083557,58.791580 
z"
          />
          <path
            fill="#F8DD86"
            opacity="1.000000"
            stroke="none"
            d="
M396.771240,444.971191 
	C396.936462,446.656464 396.901184,448.289734 396.808777,450.315430 
	C395.859192,451.832001 394.909607,452.916229 394.089935,454.091125 
	C393.237061,455.313599 392.509705,456.623657 391.354675,457.568085 
	C390.963562,457.033478 390.945190,456.826233 391.129211,456.377014 
	C393.188324,454.470917 392.731934,453.346130 390.423004,453.061920 
	C388.374786,452.809814 386.283630,452.906708 384.211426,452.849548 
	C384.449493,448.492828 387.533722,449.063660 390.190857,449.703278 
	C393.813110,450.575226 395.162018,449.213348 395.346375,445.350525 
	C395.828552,444.913971 395.994995,444.884369 396.161407,444.854736 
	C396.161407,444.854736 396.570709,444.919128 396.771240,444.971191 
z"
          />
          <path
            fill="#F7E099"
            opacity="1.000000"
            stroke="none"
            d="
M491.035278,180.990509 
	C490.663666,184.084915 490.922058,187.436478 489.786346,190.219025 
	C487.572968,195.641876 489.157104,202.524139 483.258118,206.423462 
	C483.373962,204.873108 483.800781,203.526657 484.462982,201.898209 
	C485.504242,198.487610 486.310120,195.358978 487.324219,192.000793 
	C487.685699,191.172943 487.838928,190.574661 488.276764,189.726974 
	C488.387604,187.312546 488.213837,185.147507 488.024841,182.734589 
	C488.009583,182.486725 487.968842,181.991714 488.296570,181.974594 
	C489.427979,181.635147 490.231628,181.312820 491.035278,180.990509 
z"
          />
          <path
            fill="#F7E099"
            opacity="1.000000"
            stroke="none"
            d="
M602.204163,454.161072 
	C605.226440,454.539429 608.136475,455.296814 611.596802,456.197357 
	C610.202087,459.419678 607.734070,459.091492 604.946899,458.551605 
	C603.869873,458.342987 602.630554,458.972504 601.465149,459.220184 
	C602.168884,460.296692 602.872620,461.373199 603.791077,462.711487 
	C602.851257,463.405151 601.696716,463.837036 600.542114,464.268921 
	C600.275269,461.995453 599.737549,459.706696 599.864563,457.455444 
	C599.921570,456.444977 601.308533,455.509583 602.204163,454.161072 
z"
          />
          <path
            fill="#F9D060"
            opacity="1.000000"
            stroke="none"
            d="
M460.987000,217.093842 
	C462.870941,219.230209 464.675934,221.444214 466.670380,223.471909 
	C467.752258,224.571823 469.156708,225.354477 470.724915,226.648346 
	C470.426636,228.089798 469.819092,229.163147 469.211548,230.236511 
	C468.866974,230.961136 468.522400,231.685745 467.765564,232.784393 
	C467.185486,233.285782 467.017731,233.413101 466.850006,233.540421 
	C466.850006,233.540421 466.681091,233.630737 466.593292,233.597992 
	C467.773315,230.182312 466.192993,227.618561 464.164948,225.126465 
	C463.038574,223.742294 462.079681,222.221832 461.014313,220.035706 
	C460.984558,218.570343 460.985779,217.832092 460.987000,217.093842 
z"
          />
          <path
            fill="#F8EBC6"
            opacity="1.000000"
            stroke="none"
            d="
M229.971466,450.706451 
	C228.649368,451.332764 227.356949,451.808258 226.070908,451.791534 
	C224.722580,451.774017 222.493988,451.624329 222.226669,450.927551 
	C221.725204,449.620544 221.911133,447.579437 222.680557,446.421143 
	C223.190201,445.653961 225.208359,445.768463 226.560440,445.712799 
	C228.120560,445.648590 229.690826,445.831299 231.607574,445.885193 
	C231.306946,447.355469 230.655441,448.847351 229.971466,450.706451 
z"
          />
          <path
            fill="#F7E099"
            opacity="1.000000"
            stroke="none"
            d="
M619.655762,449.017212 
	C619.410461,448.042297 619.333069,446.880951 619.585327,445.796265 
	C620.380676,442.376190 620.305786,440.389618 615.631775,441.376862 
	C612.564941,442.024597 609.198486,441.253906 605.530762,441.118408 
	C607.360535,440.603394 609.609436,439.990326 611.897034,439.609772 
	C613.297485,439.376801 614.851379,439.810730 616.173828,439.409424 
	C621.262573,437.865265 622.773376,439.059753 621.790649,444.356720 
	C621.500977,445.918335 620.596985,447.365997 619.655762,449.017212 
z"
          />
          <path
            fill="#FACD57"
            opacity="1.000000"
            stroke="none"
            d="
M266.203278,228.964996 
	C266.043213,228.314468 266.024139,227.970734 266.304626,227.214233 
	C269.583954,224.161377 272.563782,221.521255 275.956390,218.938660 
	C276.641754,219.038666 276.914337,219.081146 277.186890,219.123627 
	C274.988281,222.025848 272.626617,224.824097 270.675079,227.883942 
	C269.943512,229.031006 270.165802,230.786453 269.751892,232.614899 
	C269.382111,232.967346 269.213593,232.969452 269.045044,232.971542 
	C268.144775,231.738297 267.244537,230.505035 266.203278,228.964996 
z"
          />
          <path
            fill="#F7E099"
            opacity="1.000000"
            stroke="none"
            d="
M492.129639,145.944794 
	C491.749146,142.548172 491.368652,139.151566 491.067322,135.328857 
	C492.038422,137.850403 493.439667,140.754074 493.698975,143.756363 
	C494.059570,147.930878 493.564301,152.179337 492.947662,156.697571 
	C492.459961,156.998993 492.043793,156.966904 492.043793,156.966904 
	C491.978546,155.943527 491.913269,154.920166 491.894653,153.125366 
	C492.004059,150.217560 492.066864,148.081177 492.129639,145.944794 
z"
          />
          <path
            fill="#F0B813"
            opacity="1.000000"
            stroke="none"
            d="
M383.918640,452.997253 
	C386.283630,452.906708 388.374786,452.809814 390.423004,453.061920 
	C392.731934,453.346130 393.188324,454.470917 390.788635,456.443726 
	C389.813965,456.915680 389.382263,457.078979 388.950562,457.242249 
	C388.542664,457.238586 388.134735,457.234924 387.059387,457.160400 
	C384.919464,456.419556 383.446991,455.749512 381.974548,455.079468 
	C382.524963,454.434631 383.075439,453.789795 383.918640,452.997253 
z"
          />
          <path
            fill="#F9E7AB"
            opacity="1.000000"
            stroke="none"
            d="
M381.672485,454.924377 
	C383.446991,455.749512 384.919464,456.419556 386.755798,457.200928 
	C384.487122,459.487152 381.727112,459.156067 379.802551,456.725800 
	C378.160370,454.652222 374.056000,452.962646 377.613525,449.054260 
	C379.097229,450.912018 380.233826,452.840637 381.672485,454.924377 
z"
          />
          <path
            fill="#F8EBC6"
            opacity="1.000000"
            stroke="none"
            d="
M421.329163,450.953430 
	C421.697174,446.254669 422.417633,441.504761 423.264282,436.351868 
	C425.577576,439.350708 424.644531,447.515289 421.329163,450.953430 
z"
          />
          <path
            fill="#F8EBC6"
            opacity="1.000000"
            stroke="none"
            d="
M492.365051,456.990356 
	C492.971527,451.930725 493.982361,446.882019 495.035217,441.412415 
	C495.916107,441.494598 497.535034,442.129608 497.475647,442.480896 
	C496.717834,446.964020 495.774323,451.419708 494.708008,455.841370 
	C494.584381,456.354004 493.440338,456.620636 492.365051,456.990356 
z"
          />
          <path
            fill="#F9E7AB"
            opacity="1.000000"
            stroke="none"
            d="
M396.126923,444.501892 
	C395.994995,444.884369 395.828552,444.913971 395.409058,444.948242 
	C392.629242,443.574738 390.102509,442.196594 387.219604,440.644257 
	C391.572968,437.246674 393.211548,437.899902 396.126923,444.501892 
z"
          />
          <path
            fill="#F9D060"
            opacity="1.000000"
            stroke="none"
            d="
M484.227600,202.180206 
	C483.800781,203.526657 483.373962,204.873108 482.951355,206.575363 
	C482.776398,207.490204 482.597168,208.049240 482.002869,208.945923 
	C481.345856,209.552521 481.276276,209.860489 481.379059,210.207489 
	C481.379059,210.207489 481.228119,210.654968 481.068634,210.828888 
	C480.260040,208.676285 479.610931,206.349731 479.010986,203.375977 
	C479.372284,202.201218 479.684448,201.673660 480.241272,201.312561 
	C481.656921,201.652435 482.827881,201.825836 484.055786,202.044830 
	C484.112671,202.090439 484.227631,202.180206 484.227600,202.180206 
z"
          />
          <path
            fill="#F8EBC6"
            opacity="1.000000"
            stroke="none"
            d="
M494.822754,439.999084 
	C492.004059,440.459991 489.260498,440.504456 486.253601,440.269562 
	C488.756134,438.697449 491.521942,437.404694 494.647949,435.943573 
	C494.710876,436.859558 494.804382,438.221100 494.822754,439.999084 
z"
          />
          <path
            fill="#EEBB28"
            opacity="1.000000"
            stroke="none"
            d="
M434.963623,53.025558 
	C431.221771,54.583485 428.606354,53.444729 427.302216,49.353516 
	C427.723145,48.953232 427.910034,48.926743 428.433563,48.981186 
	C429.873199,49.345512 430.976227,49.628906 432.079254,49.912296 
	C432.079254,49.912296 432.393311,50.122574 432.664154,50.437725 
	C433.143951,51.030876 433.421539,51.185947 433.767761,51.218082 
	C433.767761,51.218082 434.159119,51.420029 434.439636,51.734489 
	C434.801331,52.374489 434.882477,52.700024 434.963623,53.025558 
z"
          />
          <path
            fill="#F9D365"
            opacity="1.000000"
            stroke="none"
            d="
M249.240723,124.103508 
	C249.172272,124.517479 249.103836,124.931450 248.769104,125.885994 
	C248.332321,127.286034 248.161819,128.145508 247.756683,129.010117 
	C247.522049,129.015244 247.053009,128.997864 247.053009,128.997864 
	C248.371597,124.338722 249.690170,119.679581 251.404327,115.229355 
	C250.946854,118.326675 250.093796,121.215096 249.240723,124.103508 
z"
          />
          <path
            fill="#F7E099"
            opacity="1.000000"
            stroke="none"
            d="
M490.936188,132.699860 
	C490.863129,133.141510 490.464203,133.089981 490.271545,132.665421 
	C489.750916,131.125198 489.422943,130.009537 489.094971,128.893860 
	C489.095001,128.893860 489.039581,128.457840 489.011292,127.936600 
	C488.731659,126.880119 488.480316,126.344879 488.228943,125.809647 
	C488.178986,125.407867 488.128998,125.006081 488.050568,123.976318 
	C487.734680,122.566101 487.447296,121.783867 487.106995,120.635025 
	C486.933441,119.299156 486.812805,118.329910 486.692139,117.360657 
	C488.131195,122.326508 489.570221,127.292358 490.936188,132.699860 
z"
          />
          <path
            fill="#F9D365"
            opacity="1.000000"
            stroke="none"
            d="
M273.303101,78.137627 
	C273.303101,78.137627 273.150452,78.574982 272.842529,78.953415 
	C272.333557,79.863533 272.132507,80.395226 271.931488,80.926918 
	C271.931488,80.926918 271.994659,81.001389 271.679108,80.998505 
	C271.018463,81.379288 270.673370,81.762955 270.328247,82.146622 
	C270.237244,82.542786 270.146210,82.938957 269.787292,83.748650 
	C269.324982,84.428574 269.130524,84.694969 268.936096,84.961365 
	C268.639801,85.085960 268.393341,85.275398 267.861847,85.852615 
	C267.259796,86.395401 267.095123,86.675507 267.032959,87.015862 
	C266.858887,87.035370 266.684814,87.054886 266.252258,87.027130 
	C268.113251,83.695999 270.232697,80.412140 272.757446,77.365067 
	C273.209595,77.780441 273.256348,77.959030 273.303101,78.137627 
z"
          />
          <path
            fill="#EEBB28"
            opacity="1.000000"
            stroke="none"
            d="
M249.564056,123.985252 
	C250.093796,121.215096 250.946854,118.326675 251.703247,115.059402 
	C251.832504,113.709503 252.058426,112.738472 252.597198,111.282310 
	C253.267700,110.527122 253.625351,110.257072 254.015427,110.017502 
	C254.047852,110.047981 254.111481,110.110222 254.111481,110.110222 
	C252.703445,114.695816 251.295410,119.281403 249.564056,123.985252 
z"
          />
          <path
            fill="#F8EBC6"
            opacity="1.000000"
            stroke="none"
            d="
M479.099243,441.228760 
	C478.164581,443.714203 476.852814,446.138336 475.273926,448.786774 
	C472.598358,445.108124 473.203003,443.831665 479.099243,441.228760 
z"
          />
          <path
            fill="#F7E099"
            opacity="1.000000"
            stroke="none"
            d="
M432.007996,49.579990 
	C430.976227,49.628906 429.873199,49.345512 428.254791,48.748398 
	C427.197266,48.246193 426.655151,48.057709 426.113007,47.869225 
	C426.113007,47.869221 425.651520,47.705845 425.278748,47.475971 
	C424.693542,47.048676 424.445496,46.919785 423.903809,46.805283 
	C423.470337,46.740768 423.294861,46.730392 423.119415,46.720013 
	C423.119415,46.720013 423.010437,46.421768 422.946899,46.048668 
	C422.883392,45.675564 422.790344,45.213112 422.877563,45.124874 
	C422.964813,45.036636 423.139679,44.860554 423.139679,44.860558 
	C426.072021,46.322937 429.004364,47.785309 432.007996,49.579990 
z"
          />
          <path
            fill="#EEBB28"
            opacity="1.000000"
            stroke="none"
            d="
M247.991318,129.004990 
	C248.161819,128.145508 248.332321,127.286034 248.743225,126.199707 
	C249.490219,126.860840 249.996811,127.748833 250.754028,128.828308 
	C250.226868,130.644211 249.449081,132.268631 248.189850,133.644348 
	C247.340988,132.822784 246.973587,132.249939 246.606186,131.677078 
	C246.606186,131.677078 246.660889,131.327682 247.025177,131.018311 
	C247.590103,130.140961 247.790710,129.572983 247.991318,129.004990 
z"
          />
          <path
            fill="#F9D365"
            opacity="1.000000"
            stroke="none"
            d="
M524.929504,452.040619 
	C525.290466,452.798828 525.651428,453.557007 526.023804,454.630463 
	C521.303894,452.448303 522.869080,448.864868 524.743591,445.244324 
	C525.020508,446.904083 524.924072,448.612030 524.697510,450.762085 
	C524.688110,451.483002 524.808838,451.761810 524.929504,452.040619 
z"
          />
          <path
            fill="#EEBB28"
            opacity="1.000000"
            stroke="none"
            d="
M479.980621,103.164124 
	C478.411713,102.765457 476.842773,102.366783 475.273865,101.968124 
	C475.708679,101.166634 476.143494,100.365143 477.084717,99.273132 
	C477.794220,98.948746 477.997314,98.914864 478.200439,98.880966 
	C478.556580,99.244576 478.912720,99.608185 479.476532,100.516342 
	C479.783020,101.761963 479.881805,102.463043 479.980621,103.164124 
z"
          />
          <path
            fill="#F7E099"
            opacity="1.000000"
            stroke="none"
            d="
M351.894470,33.878197 
	C356.399078,33.377083 360.903656,32.875969 365.387543,32.699501 
	C364.606750,33.082573 363.846649,33.140999 362.720825,33.148689 
	C361.868469,33.154888 361.381866,33.211823 360.507263,33.249165 
	C358.061523,33.460632 356.003815,33.691692 353.603516,33.928192 
	C352.805420,33.915154 352.349945,33.896671 351.894470,33.878197 
z"
          />
          <path
            fill="#F7E099"
            opacity="1.000000"
            stroke="none"
            d="
M349.941559,34.964645 
	C349.434235,35.131699 348.926880,35.298752 347.892761,35.557915 
	C346.981842,35.666615 346.597687,35.683197 345.998749,35.543991 
	C345.181000,35.582443 344.578064,35.776691 343.980652,35.979164 
	C343.986176,35.987389 343.968445,35.964741 343.604553,35.937355 
	C342.763123,35.824608 342.285553,35.739250 341.808014,35.653893 
	C344.368164,34.938499 346.928345,34.223106 349.734283,33.998779 
	C349.980072,34.489849 349.941559,34.964642 349.941559,34.964645 
z"
          />
          <path
            fill="#F9D365"
            opacity="1.000000"
            stroke="none"
            d="
M246.316681,131.871490 
	C246.973587,132.249939 247.340988,132.822784 247.846741,133.704437 
	C247.174286,135.848953 246.363480,137.684662 245.308624,139.753479 
	C245.385468,137.346390 245.706329,134.706146 246.316681,131.871490 
z"
          />
          <path
            fill="#F7E099"
            opacity="1.000000"
            stroke="none"
            d="
M448.135345,250.364929 
	C448.629517,249.831924 449.136627,249.680466 449.802856,249.309479 
	C450.944763,248.965912 451.927582,248.841843 453.275726,248.668579 
	C453.075439,249.536484 452.678375,250.649979 451.899445,251.318649 
	C451.021515,252.072327 449.799683,252.425385 448.397400,252.990997 
	C448.094360,252.268341 448.121307,251.507401 448.135345,250.364929 
z"
          />
          <path
            fill="#EEBB28"
            opacity="1.000000"
            stroke="none"
            d="
M470.294861,86.380592 
	C468.735138,86.376457 467.152527,86.050293 465.569916,85.724121 
	C465.941803,84.959969 466.313690,84.195816 467.143311,83.160660 
	C467.776611,82.860413 467.952209,82.831161 468.127777,82.801903 
	C468.842529,83.887451 469.557251,84.973000 470.294861,86.380592 
z"
          />
          <path
            fill="#F7E099"
            opacity="1.000000"
            stroke="none"
            d="
M476.779175,96.970245 
	C476.050629,95.742638 475.322113,94.515022 474.326263,92.791176 
	C473.714203,91.856308 473.369446,91.417671 472.952148,90.971626 
	C472.879547,90.964226 472.749725,91.030754 472.749725,91.030754 
	C472.722260,90.852638 472.694824,90.674530 472.694702,90.033958 
	C472.721985,89.571487 472.863525,89.131287 472.863525,89.131287 
	C474.570648,91.523239 476.277771,93.915199 477.675049,96.663490 
	C477.169922,97.003296 476.974548,96.986771 476.779175,96.970245 
z"
          />
          <path
            fill="#F8EBC6"
            opacity="1.000000"
            stroke="none"
            d="
M415.223083,457.407135 
	C416.578094,456.308990 418.152252,455.448547 419.941284,454.289978 
	C420.496857,455.037323 420.837585,456.082764 421.178314,457.128235 
	C419.266296,457.300446 417.354248,457.472656 415.223083,457.407135 
z"
          />
          <path
            fill="#F7E099"
            opacity="1.000000"
            stroke="none"
            d="
M490.979004,173.443253 
	C491.426819,171.811081 491.893280,170.594635 492.596069,169.157288 
	C492.569214,171.559967 492.306030,174.183533 491.716919,176.977631 
	C491.259857,176.051758 491.128754,174.955368 490.979004,173.443253 
z"
          />
          <path
            fill="#F8EBC6"
            opacity="1.000000"
            stroke="none"
            d="
M364.085449,457.297852 
	C366.059357,456.950531 368.098419,456.984100 370.575684,457.019592 
	C369.034882,460.464996 366.618713,459.342957 364.085449,457.297852 
z"
          />
          <path
            fill="#F7E099"
            opacity="1.000000"
            stroke="none"
            d="
M602.036133,443.447021 
	C601.978821,445.997498 601.543396,448.573486 601.075134,451.561157 
	C598.432434,448.935455 599.928345,446.195526 602.036133,443.447021 
z"
          />
          <path
            fill="#F7E099"
            opacity="1.000000"
            stroke="none"
            d="
M417.992310,42.944836 
	C419.632019,43.371136 421.271729,43.797428 423.025574,44.542137 
	C423.139679,44.860554 422.964813,45.036636 422.554047,45.056091 
	C421.485077,44.994255 420.826843,44.912968 419.901367,44.703346 
	C419.086853,44.031612 418.539581,43.488220 417.992310,42.944836 
z"
          />
          <path
            fill="#E8C152"
            opacity="1.000000"
            stroke="none"
            d="
M311.338257,47.911968 
	C310.181549,48.347485 309.309662,48.635208 308.091248,48.882980 
	C309.928497,47.590893 312.112274,46.338760 314.675720,45.338577 
	C315.055359,45.590530 315.078400,46.054722 315.078400,46.054726 
	C313.926605,46.624542 312.774841,47.194359 311.338257,47.911968 
z"
          />
          <path
            fill="#F7E099"
            opacity="1.000000"
            stroke="none"
            d="
M469.550354,230.158081 
	C469.819092,229.163147 470.426636,228.089798 471.102814,226.726562 
	C471.651245,226.061325 472.131073,225.685989 472.865601,225.145905 
	C474.838318,228.452011 472.204895,229.165161 469.550354,230.158081 
z"
          />
          <path
            fill="#F8EBC6"
            opacity="1.000000"
            stroke="none"
            d="
M346.792236,439.790344 
	C344.894409,440.951904 342.771637,441.853333 340.309814,442.855652 
	C340.347656,438.306763 344.262939,440.469177 346.792236,439.790344 
z"
          />
          <path
            fill="#EEBB28"
            opacity="1.000000"
            stroke="none"
            d="
M363.086578,33.199429 
	C363.846649,33.140999 364.606750,33.082573 365.734924,32.922241 
	C366.799805,32.828712 367.496582,32.837093 368.541138,32.825241 
	C370.609131,32.925251 372.329346,33.045486 374.517731,33.381985 
	C374.985870,33.598248 375.037048,33.960430 375.037048,33.960430 
	C371.313110,33.967205 367.589203,33.973984 363.432373,33.771149 
	C362.999512,33.561535 363.086578,33.199429 363.086578,33.199429 
z"
          />
          <path
            fill="#F9CD4E"
            opacity="1.000000"
            stroke="none"
            d="
M491.065552,180.654114 
	C490.231628,181.312820 489.427979,181.635147 488.038940,181.767181 
	C487.276154,180.722275 487.098724,179.867676 487.157471,178.895538 
	C487.605682,178.501770 487.817688,178.225555 488.029694,177.949341 
	C488.793488,178.284439 489.557281,178.619522 490.638062,178.969513 
	C491.001953,179.428864 491.048889,179.873291 491.065552,180.654114 
z"
          />
          <path
            fill="#EEBB28"
            opacity="1.000000"
            stroke="none"
            d="
M285.569702,64.956894 
	C286.169128,68.024437 284.745697,69.724731 281.186127,69.863045 
	C282.282135,68.208427 283.765839,66.624954 285.569702,64.956894 
z"
          />
          <path
            fill="#F7E099"
            opacity="1.000000"
            stroke="none"
            d="
M331.515076,39.650757 
	C331.515076,39.650761 331.216339,39.594238 330.737732,39.657169 
	C329.805359,39.774872 329.351593,39.829643 328.550049,39.886139 
	C327.740936,39.848797 327.279602,39.809734 326.818268,39.770676 
	C328.427643,39.064320 330.037018,38.357964 331.796722,38.165695 
	C331.803070,39.003437 331.659058,39.327095 331.515076,39.650757 
z"
          />
          <path
            fill="#EEBB28"
            opacity="1.000000"
            stroke="none"
            d="
M273.642090,78.089966 
	C273.256348,77.959030 273.209595,77.780441 273.104340,77.331375 
	C273.877380,76.185089 274.708893,75.309273 276.016693,74.132690 
	C276.995941,73.555077 277.498840,73.278229 278.039886,73.042618 
	C278.078003,73.083847 278.152130,73.168198 278.152130,73.168198 
	C276.761780,74.792900 275.371429,76.417595 273.642090,78.089966 
z"
          />
          <path
            fill="#F7E099"
            opacity="1.000000"
            stroke="none"
            d="
M476.493286,218.999115 
	C476.676117,218.130615 477.245789,217.234253 477.933990,216.050629 
	C478.366455,215.484375 478.680450,215.205368 479.178467,214.827942 
	C480.347931,217.204376 479.986664,218.891098 476.493286,218.999115 
z"
          />
          <path
            fill="#EEBB28"
            opacity="1.000000"
            stroke="none"
            d="
M257.008270,103.992706 
	C256.266846,105.958389 255.525452,107.924065 254.447769,109.999985 
	C254.111481,110.110222 254.047852,110.047981 254.031906,109.680794 
	C254.001419,108.865036 253.986877,108.416458 253.972321,107.967880 
	C254.543488,106.822166 255.114655,105.676453 256.077484,104.264717 
	C256.648895,103.996704 256.828583,103.994705 257.008270,103.992706 
z"
          />
          <path
            fill="#F7E099"
            opacity="1.000000"
            stroke="none"
            d="
M339.937714,36.943703 
	C338.860046,37.154449 337.782349,37.365200 336.066345,37.772270 
	C334.906403,37.882839 334.384735,37.797089 333.863098,37.711342 
	C335.759033,36.966896 337.654999,36.222454 339.770325,35.959831 
	C339.972382,36.609005 339.955048,36.776356 339.937714,36.943703 
z"
          />
          <path
            fill="#F8EBC6"
            opacity="1.000000"
            stroke="none"
            d="
M372.916077,440.746033 
	C371.913208,440.024536 370.943237,438.927887 369.934113,437.460938 
	C370.912964,438.184082 371.931000,439.277466 372.916077,440.746033 
z"
          />
          <path
            fill="#F7E099"
            opacity="1.000000"
            stroke="none"
            d="
M444.046844,256.752808 
	C444.917053,255.495895 446.140289,254.295090 447.695190,253.078461 
	C446.817841,254.311401 445.608856,255.560181 444.046844,256.752808 
z"
          />
          <path
            fill="#F8EBC6"
            opacity="1.000000"
            stroke="none"
            d="
M486.989441,457.495148 
	C488.041138,457.141998 489.142853,457.107544 490.651611,457.153381 
	C490.121307,460.214294 488.852570,460.899597 486.989441,457.495148 
z"
          />
          <path
            fill="#EEBB28"
            opacity="1.000000"
            stroke="none"
            d="
M333.558777,37.799721 
	C334.384735,37.797089 334.906403,37.882839 335.828735,37.995571 
	C336.419342,38.368031 336.609314,38.713509 336.799286,39.058987 
	C335.303497,39.320446 333.807709,39.581909 331.913483,39.747063 
	C331.659058,39.327095 331.803070,39.003437 331.980713,38.416065 
	C332.427734,38.064270 332.841064,37.976189 333.558777,37.799721 
z"
          />
          <path
            fill="#EEBB28"
            opacity="1.000000"
            stroke="none"
            d="
M486.614502,117.126183 
	C486.812805,118.329910 486.933441,119.299156 486.868530,120.645393 
	C486.682953,121.022377 486.207703,121.041183 486.207703,121.041183 
	C485.790009,119.632973 485.372345,118.224770 485.209686,116.454292 
	C485.765717,116.206787 486.067963,116.318192 486.371429,116.426247 
	C486.371429,116.426247 486.536865,116.891708 486.614502,117.126183 
z"
          />
          <path
            fill="#E8C152"
            opacity="1.000000"
            stroke="none"
            d="
M320.820648,42.389519 
	C321.171234,42.779957 321.110535,43.099041 320.714111,43.674713 
	C320.436371,43.942326 319.992798,44.015198 319.992798,44.015190 
	C319.454681,44.198231 318.916595,44.381279 317.817139,44.774464 
	C316.766815,44.941338 316.277832,44.898071 315.788818,44.854805 
	C317.348297,44.012939 318.907806,43.171066 320.820648,42.389519 
z"
          />
          <path
            fill="#F7E099"
            opacity="1.000000"
            stroke="none"
            d="
M612.999451,462.644196 
	C613.814514,461.349030 614.988342,460.162933 616.465820,458.879028 
	C615.632324,460.105194 614.495300,461.429260 612.999451,462.644196 
z"
          />
          <path
            fill="#F8EBC6"
            opacity="1.000000"
            stroke="none"
            d="
M407.034241,457.469696 
	C408.364777,457.105865 409.770081,457.068359 411.594543,457.080017 
	C410.378784,457.351501 408.743927,457.573761 407.034241,457.469696 
z"
          />
          <path
            fill="#F9D365"
            opacity="1.000000"
            stroke="none"
            d="
M265.646118,87.033195 
	C265.737854,88.094719 265.494873,89.193314 264.827911,90.638046 
	C264.043335,91.026718 263.682709,91.069244 263.322083,91.111771 
	C263.985199,89.764603 264.648315,88.417435 265.646118,87.033195 
z"
          />
          <path
            fill="#F8EBC6"
            opacity="1.000000"
            stroke="none"
            d="
M474.582581,451.041626 
	C475.320038,451.757233 475.716217,452.522888 476.112274,453.625427 
	C474.092224,453.915405 472.030396,453.895782 474.582581,451.041626 
z"
          />
          <path
            fill="#F7E099"
            opacity="1.000000"
            stroke="none"
            d="
M461.389648,239.825531 
	C461.250000,239.401947 461.500336,239.025253 461.853119,238.447998 
	C462.676208,237.785095 463.396790,237.322739 464.426361,236.787933 
	C463.750122,237.767776 462.764862,238.820099 461.389648,239.825531 
z"
          />
          <path
            fill="#F8EBC6"
            opacity="1.000000"
            stroke="none"
            d="
M479.080200,457.234253 
	C480.434814,456.971283 481.813568,457.057281 483.600830,457.217346 
	C482.374298,457.388702 480.739319,457.485992 479.080200,457.234253 
z"
          />
          <path
            fill="#EEBB28"
            opacity="1.000000"
            stroke="none"
            d="
M263.156036,91.353477 
	C263.682709,91.069244 264.043335,91.026718 264.674744,90.948036 
	C264.188232,92.243210 263.430939,93.574547 262.335876,94.625504 
	C262.010681,93.917572 262.023285,93.490028 262.035889,93.062485 
	C262.353943,92.573387 262.671967,92.084290 263.156036,91.353477 
z"
          />
          <path
            fill="#EEBB28"
            opacity="1.000000"
            stroke="none"
            d="
M417.740356,42.913548 
	C418.539581,43.488220 419.086853,44.031612 419.869324,44.950912 
	C420.104523,45.326820 420.097992,45.822983 420.097992,45.822983 
	C418.949677,45.638451 417.801331,45.453915 416.326508,45.134689 
	C416.000000,45.000000 416.006714,45.006123 416.006714,45.006123 
	C415.999481,44.662270 415.992279,44.318413 416.018066,43.455597 
	C416.530182,42.918510 417.009277,42.900391 417.740356,42.913548 
z"
          />
          <path
            fill="#F7E099"
            opacity="1.000000"
            stroke="none"
            d="
M482.440582,106.811996 
	C483.128174,107.743294 483.815735,108.674599 484.017029,109.808533 
	C483.355347,110.004242 483.179932,109.997307 483.004486,109.990364 
	C483.004486,109.990364 482.935516,110.008957 482.892120,109.747574 
	C482.616119,108.954506 482.383514,108.422806 482.200256,107.615143 
	C482.313263,107.163445 482.376923,106.987724 482.440582,106.811996 
z"
          />
          <path
            fill="#F8EBC6"
            opacity="1.000000"
            stroke="none"
            d="
M371.367493,456.960510 
	C371.019348,455.955719 371.016205,454.896332 370.995605,453.439880 
	C373.790222,453.842499 373.548492,455.222687 371.367493,456.960510 
z"
          />
          <path
            fill="#F7E099"
            opacity="1.000000"
            stroke="none"
            d="
M408.789795,39.334885 
	C410.380951,39.707340 411.972137,40.079803 413.362000,40.775993 
	C412.196167,41.012363 411.231689,40.924999 410.267181,40.837639 
	C410.267181,40.837635 410.144043,40.824734 409.999268,40.633884 
	C409.499573,40.073654 409.144684,39.704273 408.789795,39.334885 
z"
          />
          <path
            fill="#F7E099"
            opacity="1.000000"
            stroke="none"
            d="
M618.158447,455.774780 
	C618.059875,454.660492 618.305054,453.552277 618.811890,452.219055 
	C618.883118,453.256287 618.692688,454.518585 618.158447,455.774780 
z"
          />
          <path
            fill="#F9D365"
            opacity="1.000000"
            stroke="none"
            d="
M261.701233,93.104980 
	C262.023285,93.490028 262.010681,93.917572 261.976562,94.665962 
	C261.646240,95.435539 261.337463,95.884277 260.719513,96.666992 
	C260.093842,97.145187 259.777313,97.289413 259.385986,97.361374 
	C259.311218,97.289108 259.165436,97.140770 259.165436,97.140770 
	C259.899170,95.809669 260.632904,94.478577 261.701233,93.104980 
z"
          />
          <path
            fill="#EEBB28"
            opacity="1.000000"
            stroke="none"
            d="
M414.870728,42.848297 
	C413.368073,42.946438 411.821899,42.813618 410.629822,42.300404 
	C412.061188,41.908375 413.138489,41.896744 414.215820,41.885113 
	C414.474030,42.083847 414.677856,42.327923 414.870728,42.848297 
z"
          />
          <path
            fill="#F7E099"
            opacity="1.000000"
            stroke="none"
            d="
M480.202759,103.328690 
	C479.881805,102.463043 479.783020,101.761963 479.641449,100.713531 
	C480.376678,101.699257 481.154663,103.032318 481.663574,104.731842 
	C481.394440,105.098312 480.925751,105.076164 480.925751,105.076164 
	C480.758789,104.548531 480.591827,104.020897 480.202759,103.328690 
z"
          />
          <path
            fill="#F9D365"
            opacity="1.000000"
            stroke="none"
            d="
M288.731018,61.130531 
	C289.042755,61.534145 289.015594,61.940105 288.977844,62.650909 
	C288.967285,62.955761 288.985229,62.982731 288.755310,63.078999 
	C288.248871,63.372940 288.079529,63.638870 288.017334,63.973053 
	C288.017334,63.973053 287.988098,63.984730 287.658630,63.996452 
	C286.901276,64.015282 286.473419,64.022400 286.045563,64.029518 
	C286.827759,63.063972 287.609924,62.098423 288.731018,61.130531 
z"
          />
          <path
            fill="#F9E7AB"
            opacity="1.000000"
            stroke="none"
            d="
M425.980347,267.913879 
	C426.690125,267.564911 427.399902,267.215973 428.398254,266.813446 
	C427.943573,267.674500 427.200348,268.589142 426.224121,268.985596 
	C425.987549,268.282898 425.983948,268.098389 425.980347,267.913879 
z"
          />
          <path
            fill="#F9E7AB"
            opacity="1.000000"
            stroke="none"
            d="
M381.644226,442.424866 
	C381.248169,442.282410 381.187622,442.039612 381.082397,441.424835 
	C382.351807,440.762329 383.665802,440.471832 385.037842,440.438568 
	C384.057129,441.238708 383.018433,441.781647 381.644226,442.424866 
z"
          />
          <path
            fill="#F9D365"
            opacity="1.000000"
            stroke="none"
            d="
M257.048767,101.034935 
	C257.551147,99.834091 258.053528,98.633247 258.860657,97.286591 
	C259.165436,97.140770 259.311218,97.289108 259.273224,97.652695 
	C258.611786,99.101944 257.988342,100.187599 257.285767,101.213799 
	C257.206635,101.154335 257.048767,101.034935 257.048767,101.034935 
z"
          />
          <path
            fill="#F8EBC6"
            opacity="1.000000"
            stroke="none"
            d="
M483.835449,439.787872 
	C482.915985,440.387360 481.817719,440.740265 480.358093,441.089539 
	C481.216705,440.571014 482.436676,440.056152 483.835449,439.787872 
z"
          />
          <path
            fill="#F7E099"
            opacity="1.000000"
            stroke="none"
            d="
M459.125214,240.350296 
	C459.343811,240.009872 459.528595,240.036285 459.990601,240.115845 
	C459.922913,241.366898 459.577972,242.564835 458.912933,243.679047 
	C458.759003,242.635925 458.925171,241.676514 459.125214,240.350296 
z"
          />
          <path
            fill="#EEBB28"
            opacity="1.000000"
            stroke="none"
            d="
M280.501526,69.883011 
	C280.152344,70.880646 279.513062,71.959602 278.512939,73.103378 
	C278.152130,73.168198 278.078003,73.083847 278.049438,72.787872 
	C278.004944,72.322708 277.988953,72.153519 277.972992,71.984329 
	C278.719147,71.311005 279.465302,70.637672 280.501526,69.883011 
z"
          />
          <path
            fill="#F7E099"
            opacity="1.000000"
            stroke="none"
            d="
M323.997955,42.006916 
	C323.532532,42.012955 323.067078,42.018990 322.263153,41.938164 
	C323.052979,41.228451 324.181335,40.605598 325.663727,40.238888 
	C326.017792,40.495029 325.952057,40.969070 325.666565,41.020103 
	C324.920044,41.383064 324.458984,41.694988 323.997955,42.006916 
z"
          />
          <path
            fill="#F7E099"
            opacity="1.000000"
            stroke="none"
            d="
M321.623444,269.950928 
	C321.717438,270.432922 321.475800,270.929871 321.128723,271.681549 
	C320.469177,271.836670 319.915070,271.737122 319.193420,271.405945 
	C319.779846,270.771515 320.533844,270.368713 321.623444,269.950928 
z"
          />
          <path
            fill="#F8EBC6"
            opacity="1.000000"
            stroke="none"
            d="
M232.001556,452.908813 
	C231.186874,452.577911 230.649994,452.084167 230.025482,451.326965 
	C230.718338,451.624359 231.498840,452.185181 232.001556,452.908813 
z"
          />
          <path
            fill="#F9D365"
            opacity="1.000000"
            stroke="none"
            d="
M253.641922,108.043793 
	C253.986877,108.416458 254.001419,108.865036 253.999481,109.650314 
	C253.625351,110.257072 253.267700,110.527122 252.735413,110.932999 
	C252.811020,110.085770 253.061264,109.102737 253.641922,108.043793 
z"
          />
          <path
            fill="#E8C152"
            opacity="1.000000"
            stroke="none"
            d="
M350.319794,35.009178 
	C349.941559,34.964642 349.980072,34.489849 349.991638,34.252003 
	C350.424225,33.988682 350.845276,33.963207 351.580383,33.907963 
	C352.349945,33.896671 352.805420,33.915154 353.501068,34.159943 
	C353.486572,34.604084 353.231873,34.821915 352.977203,35.039742 
	C352.217468,35.044399 351.457764,35.049057 350.319794,35.009178 
z"
          />
          <path
            fill="#EEBB28"
            opacity="1.000000"
            stroke="none"
            d="
M408.567139,39.317268 
	C409.144684,39.704273 409.499573,40.073654 409.956299,40.678459 
	C409.134949,40.676567 408.211761,40.439259 407.158478,39.843811 
	C407.467102,39.423664 407.905823,39.361652 408.567139,39.317268 
z"
          />
          <path
            fill="#F7E099"
            opacity="1.000000"
            stroke="none"
            d="
M435.178741,53.093338 
	C434.882477,52.700024 434.801331,52.374489 434.645996,51.811333 
	C435.321320,51.864079 436.070862,52.154446 436.572021,52.851669 
	C436.142944,53.302944 435.962219,53.347363 435.781464,53.391777 
	C435.781464,53.391777 435.393860,53.161118 435.178741,53.093338 
z"
          />
          <path
            fill="#F7E099"
            opacity="1.000000"
            stroke="none"
            d="
M486.516205,116.109512 
	C486.067963,116.318192 485.765717,116.206787 485.223328,116.058990 
	C484.978577,115.556412 484.975250,115.086884 485.053833,114.274750 
	C485.644165,114.552368 486.152557,115.172569 486.516205,116.109512 
z"
          />
          <path
            fill="#E8C152"
            opacity="1.000000"
            stroke="none"
            d="
M340.341736,36.989479 
	C339.955048,36.776356 339.972382,36.609005 340.006317,36.189556 
	C340.421478,35.885258 340.820007,35.833061 341.513275,35.717381 
	C342.285553,35.739250 342.763123,35.824608 343.612793,36.187317 
	C343.984894,36.464664 343.988770,36.964294 343.988770,36.964294 
	C342.907745,36.987949 341.826752,37.011600 340.341736,36.989479 
z"
          />
          <path
            fill="#E8C152"
            opacity="1.000000"
            stroke="none"
            d="
M414.176453,41.672836 
	C413.138489,41.896744 412.061188,41.908375 410.589478,41.887512 
	C410.180237,41.684113 410.165344,41.513214 410.208832,41.089973 
	C411.231689,40.924999 412.196167,41.012363 413.526306,41.102394 
	C413.891968,41.105061 414.137054,41.460556 414.176453,41.672836 
z"
          />
          <path
            fill="#F9D365"
            opacity="1.000000"
            stroke="none"
            d="
M277.656464,72.021736 
	C277.988953,72.153519 278.004944,72.322708 278.011353,72.746643 
	C277.498840,73.278229 276.995941,73.555077 276.214447,73.905457 
	C276.403900,73.339043 276.871918,72.699097 277.656464,72.021736 
z"
          />
          <path
            fill="#F9D365"
            opacity="1.000000"
            stroke="none"
            d="
M257.048553,103.770554 
	C256.828583,103.994705 256.648895,103.996704 256.200928,103.969223 
	C256.149536,103.121643 256.366364,102.303543 256.815979,101.260193 
	C257.048767,101.034935 257.206635,101.154335 257.208862,101.512665 
	C257.205872,102.283852 257.200653,102.696716 257.195435,103.109573 
	C257.195435,103.109573 257.088837,103.548386 257.048553,103.770554 
z"
          />
          <path
            fill="#F7E099"
            opacity="1.000000"
            stroke="none"
            d="
M462.528534,77.004868 
	C462.528534,77.004860 462.613068,76.564964 462.695618,76.357147 
	C463.462860,76.635094 464.147583,77.120865 464.777039,77.965187 
	C464.721771,78.323738 464.384735,78.506508 464.053406,78.205948 
	C463.324219,77.605217 462.926361,77.305046 462.528534,77.004868 
z"
          />
          <path
            fill="#EEBB28"
            opacity="1.000000"
            stroke="none"
            d="
M435.709808,53.627739 
	C435.962219,53.347363 436.142944,53.302944 436.597198,53.204521 
	C437.024384,53.462437 437.178070,53.774361 437.345032,54.343178 
	C436.784912,54.354618 436.211548,54.109161 435.709808,53.627739 
z"
          />
          <path
            fill="#F7E099"
            opacity="1.000000"
            stroke="none"
            d="
M468.118469,82.474350 
	C467.952209,82.831161 467.776611,82.860413 467.334473,82.883720 
	C466.924835,82.321320 466.781799,81.764854 466.592468,80.929504 
	C467.067169,81.149345 467.588165,81.648071 468.118469,82.474350 
z"
          />
          <path
            fill="#EEBB28"
            opacity="1.000000"
            stroke="none"
            d="
M315.585175,44.887230 
	C316.277832,44.898071 316.766815,44.941338 317.624084,44.987541 
	C317.238953,45.334702 316.485535,45.678917 315.405273,46.038929 
	C315.078400,46.054722 315.055359,45.590530 315.040283,45.358982 
	C315.025238,45.127434 315.381531,44.919651 315.585175,44.887230 
z"
          />
          <path
            fill="#F7E099"
            opacity="1.000000"
            stroke="none"
            d="
M474.976746,220.400818 
	C475.052673,220.062164 475.529144,220.031906 475.767944,220.032959 
	C475.783020,220.890305 475.559326,221.746582 475.001587,222.699509 
	C474.745300,222.110596 474.823029,221.425034 474.976746,220.400818 
z"
          />
          <path
            fill="#E8C152"
            opacity="1.000000"
            stroke="none"
            d="
M480.923035,105.438484 
	C480.925751,105.076164 481.394440,105.098312 481.628021,105.113876 
	C481.985931,105.504257 482.110291,105.879082 482.337616,106.532951 
	C482.376923,106.987724 482.313263,107.163445 481.933472,107.643097 
	C481.439606,107.954964 481.261810,107.962921 481.084015,107.970886 
	C481.029449,107.247528 480.974884,106.524162 480.923035,105.438484 
z"
          />
          <path
            fill="#E8C152"
            opacity="1.000000"
            stroke="none"
            d="
M482.984314,110.345566 
	C483.179932,109.997307 483.355347,110.004242 483.794312,110.018173 
	C484.159790,110.714836 484.261719,111.404495 484.369446,112.442978 
	C483.904877,112.094803 483.434509,111.397789 482.984314,110.345566 
z"
          />
          <path
            fill="#E8C152"
            opacity="1.000000"
            stroke="none"
            d="
M325.952057,40.969070 
	C325.952057,40.969070 326.017792,40.495029 326.030273,40.256042 
	C326.042755,40.017056 326.432343,39.898067 326.625305,39.834373 
	C327.279602,39.809734 327.740936,39.848797 328.564636,40.130554 
	C328.926971,40.373253 328.939514,40.861126 328.939514,40.861126 
	C328.939514,40.861126 328.891876,40.888466 328.519745,40.915398 
	C327.415771,40.951248 326.683899,40.960159 325.952057,40.969070 
z"
          />
          <path
            fill="#EEBB28"
            opacity="1.000000"
            stroke="none"
            d="
M404.235535,39.217896 
	C404.554810,38.937855 405.129791,38.951290 406.136108,38.985336 
	C406.567474,39.005951 406.712402,39.233635 406.733093,39.363708 
	C405.999603,39.499649 405.245422,39.505512 404.235535,39.217896 
z"
          />
          <path
            fill="#F9E7AB"
            opacity="1.000000"
            stroke="none"
            d="
M389.166504,457.429626 
	C389.382263,457.078979 389.813965,456.915680 390.586243,456.685730 
	C390.945190,456.826233 390.963562,457.033478 391.021851,457.549500 
	C390.502045,457.777832 389.942261,457.697388 389.166504,457.429626 
z"
          />
          <path
            fill="#EEBB28"
            opacity="1.000000"
            stroke="none"
            d="
M285.939941,64.232124 
	C286.473419,64.022400 286.901276,64.015282 287.650024,64.009628 
	C287.473724,64.327126 286.976593,64.643150 286.165924,64.925262 
	C285.852417,64.891342 285.834290,64.434731 285.939941,64.232124 
z"
          />
          <path
            fill="#F9E7AB"
            opacity="1.000000"
            stroke="none"
            d="
M378.864471,445.338013 
	C378.853485,445.025482 379.300873,444.986176 379.524658,444.974976 
	C379.676239,445.553284 379.604004,446.142792 379.233521,446.815796 
	C378.915314,446.483032 378.895386,446.066772 378.864471,445.338013 
z"
          />
          <path
            fill="#E8C152"
            opacity="1.000000"
            stroke="none"
            d="
M527.681030,442.969330 
	C527.523743,443.301331 527.065735,443.648682 526.307983,443.996948 
	C525.991943,443.830322 525.975708,443.662842 526.070129,443.268158 
	C526.580566,443.022217 526.980408,443.003448 527.681030,442.969330 
z"
          />
          <path
            fill="#F7E099"
            opacity="1.000000"
            stroke="none"
            d="
M604.768005,441.115906 
	C604.706543,441.509308 604.330200,441.886871 603.689697,442.337952 
	C603.768066,441.974365 604.110596,441.537201 604.768005,441.115906 
z"
          />
          <path
            fill="#EEBB28"
            opacity="1.000000"
            stroke="none"
            d="
M439.113312,55.587078 
	C438.628479,55.686329 438.008545,55.494007 437.324585,54.959858 
	C437.833099,54.843857 438.405640,55.069679 439.113312,55.587078 
z"
          />
          <path
            fill="#F8DD86"
            opacity="1.000000"
            stroke="none"
            d="
M456.338074,245.970947 
	C456.311981,245.546799 456.538635,245.051285 457.057800,244.569946 
	C457.097137,245.022629 456.843964,245.461105 456.338074,245.970947 
z"
          />
          <path
            fill="#E8C152"
            opacity="1.000000"
            stroke="none"
            d="
M476.738831,96.974075 
	C476.974548,96.986771 477.169922,97.003296 477.658569,97.038971 
	C478.035553,97.460373 478.119232,97.862640 478.201660,98.572937 
	C477.997314,98.914864 477.794220,98.948746 477.283020,99.007248 
	C476.974854,99.031883 476.926636,98.543945 476.891602,98.042259 
	C476.797180,97.379196 476.737793,97.217819 476.678436,97.056442 
	C476.678436,97.056442 476.698517,96.977913 476.738831,96.974075 
z"
          />
          <path
            fill="#F7E099"
            opacity="1.000000"
            stroke="none"
            d="
M479.871338,214.434387 
	C479.704803,213.940079 479.874634,213.451492 480.256042,212.802460 
	C480.381012,213.241364 480.294342,213.840744 479.871338,214.434387 
z"
          />
          <path
            fill="#F7E099"
            opacity="1.000000"
            stroke="none"
            d="
M493.082153,168.741791 
	C492.868561,168.426514 492.882324,168.006104 492.969788,167.277527 
	C493.132172,167.525116 493.220825,168.080887 493.082153,168.741791 
z"
          />
          <path
            fill="#EEBB28"
            opacity="1.000000"
            stroke="none"
            d="
M471.599609,88.421494 
	C471.154419,88.320343 470.662292,87.901550 470.229614,87.118912 
	C470.710266,87.204659 471.131470,87.654259 471.599609,88.421494 
z"
          />
          <path
            fill="#F9E7AB"
            opacity="1.000000"
            stroke="none"
            d="
M400.003662,278.057526 
	C400.164368,278.009735 400.325104,277.961945 400.735901,277.941406 
	C400.762909,278.406158 400.539917,278.843658 400.156311,278.914276 
	C399.995697,278.547363 400.003662,278.057526 400.003662,278.057526 
z"
          />
          <path
            fill="#F6C232"
            opacity="1.000000"
            stroke="none"
            d="
M247.756683,129.010117 
	C247.790710,129.572983 247.590103,130.140961 247.124115,130.871735 
	C246.863144,130.577255 246.867554,130.119995 246.962494,129.330292 
	C247.053009,128.997864 247.522049,129.015244 247.756683,129.010117 
z"
          />
          <path
            fill="#F7E099"
            opacity="1.000000"
            stroke="none"
            d="
M466.363403,80.080811 
	C466.040253,80.286537 465.875488,80.186966 465.717407,79.866058 
	C465.674194,79.727074 466.119720,79.511749 466.119720,79.511749 
	C466.119720,79.511749 466.471924,79.857864 466.363403,80.080811 
z"
          />
          <path
            fill="#F7E099"
            opacity="1.000000"
            stroke="none"
            d="
M617.425415,456.941711 
	C617.126770,456.751587 617.180054,456.445892 617.588135,456.029480 
	C617.902100,456.225220 617.846802,456.527649 617.425415,456.941711 
z"
          />
          <path
            fill="#F9CD4E"
            opacity="1.000000"
            stroke="none"
            d="
M492.056946,157.176514 
	C492.043793,156.966904 492.459961,156.998993 492.668304,157.001678 
	C492.859711,157.187653 492.842804,157.370941 492.694336,157.797363 
	C492.305634,157.892365 492.141388,157.674240 492.056946,157.176514 
z"
          />
          <path
            fill="#EEBB28"
            opacity="1.000000"
            stroke="none"
            d="
M440.635376,56.109276 
	C440.865723,56.133156 440.804871,56.594147 440.766571,56.823776 
	C440.494659,56.961563 440.300507,56.816814 440.067291,56.453850 
	C439.988800,56.288544 440.405029,56.085392 440.635376,56.109276 
z"
          />
          <path
            fill="#F8DD86"
            opacity="1.000000"
            stroke="none"
            d="
M454.866821,247.183914 
	C454.760284,247.233032 454.565613,246.860458 454.565613,246.860458 
	C454.565613,246.860458 454.858490,246.560135 455.047607,246.658295 
	C455.236755,246.756439 454.973389,247.134796 454.866821,247.183914 
z"
          />
          <path
            fill="#F7E099"
            opacity="1.000000"
            stroke="none"
            d="
M425.728577,267.972717 
	C425.983948,268.098389 425.987549,268.282898 425.987305,268.744446 
	C425.782135,269.008179 425.580750,268.994873 425.092285,268.881531 
	C425.029114,268.531525 425.252991,268.281555 425.728577,267.972717 
z"
          />
          <path
            fill="#F7E099"
            opacity="1.000000"
            stroke="none"
            d="
M421.466431,270.120422 
	C421.211060,269.773621 421.346710,269.553345 421.905884,269.581787 
	C421.997681,269.890564 421.840363,270.029388 421.466431,270.120422 
z"
          />
          <path
            fill="#F7E6A5"
            opacity="1.000000"
            stroke="none"
            d="
M399.750824,278.075745 
	C400.003662,278.057526 399.995697,278.547363 399.986877,278.792480 
	C399.757294,279.008057 399.536499,278.978485 399.008423,278.813812 
	C398.966797,278.483795 399.232391,278.288849 399.750824,278.075745 
z"
          />
          <path
            fill="#F7E099"
            opacity="1.000000"
            stroke="none"
            d="
M465.428375,79.025787 
	C465.049866,79.158295 464.869873,78.970062 464.760254,78.497421 
	C465.007812,78.461464 465.273224,78.625481 465.428375,79.025787 
z"
          />
          <path
            fill="#F7E099"
            opacity="1.000000"
            stroke="none"
            d="
M467.027832,233.609985 
	C467.017731,233.413101 467.185486,233.285782 467.654480,233.063995 
	C467.705688,233.206207 467.455688,233.442886 467.027832,233.609985 
z"
          />
          <path
            fill="#F7E099"
            opacity="1.000000"
            stroke="none"
            d="
M481.539062,210.045639 
	C481.276276,209.860489 481.345856,209.552521 481.801849,209.163239 
	C482.001556,209.357605 481.895935,209.637894 481.539062,210.045639 
z"
          />
          <path
            fill="#EEBB28"
            opacity="1.000000"
            stroke="none"
            d="
M472.654541,89.038429 
	C472.863525,89.131287 472.721985,89.571487 472.613800,89.776031 
	C472.289642,89.822998 472.136627,89.618324 472.018005,89.172745 
	C471.989410,88.978943 472.445557,88.945572 472.654541,89.038429 
z"
          />
          <path
            fill="#F9DC83"
            opacity="1.000000"
            stroke="none"
            d="
M269.129700,233.214020 
	C269.213593,232.969452 269.382111,232.967346 269.803619,232.955902 
	C270.085022,233.111954 270.113403,233.277344 270.107422,233.691864 
	C269.786835,233.779510 269.500580,233.618011 269.129700,233.214020 
z"
          />
          <path
            fill="#F9D060"
            opacity="1.000000"
            stroke="none"
            d="
M465.931213,234.318787 
	C465.808655,234.114319 465.829224,233.828506 466.097351,233.598846 
	C466.340485,233.888992 466.250305,234.083145 465.931213,234.318787 
z"
          />
          <path
            fill="#E8C152"
            opacity="1.000000"
            stroke="none"
            d="
M305.777893,49.394863 
	C305.890381,49.633709 305.747162,49.798195 305.389893,49.970352 
	C305.184937,49.722763 305.300385,49.503590 305.777893,49.394863 
z"
          />
          <path
            fill="#F7E099"
            opacity="1.000000"
            stroke="none"
            d="
M433.709290,50.958412 
	C433.421539,51.185947 433.143951,51.030876 432.826660,50.534218 
	C433.029144,50.443291 433.339996,50.571014 433.709290,50.958412 
z"
          />
          <path
            fill="#FBCA42"
            opacity="1.000000"
            stroke="none"
            d="
M393.997864,95.973778 
	C396.048737,96.320717 398.099640,96.667664 400.790771,97.278664 
	C403.954803,98.693939 406.478546,99.845154 409.001160,100.998184 
	C409.000000,101.000000 408.997864,101.003067 408.569824,101.012146 
	C404.746033,100.978752 401.350281,100.936287 397.647583,100.895660 
	C396.931702,100.910576 396.522705,100.923637 396.024261,100.596573 
	C393.618378,99.208466 391.301971,98.160477 388.534088,97.083572 
	C385.065155,97.016632 382.047729,96.978600 378.809265,96.676834 
	C377.053955,96.010307 375.519623,95.607521 373.533997,95.164215 
	C370.059692,95.154015 367.036682,95.184334 363.643555,95.159554 
	C362.135223,95.708527 360.997009,96.312607 359.556396,96.907593 
	C358.850708,96.898285 358.447388,96.898071 357.810486,96.665085 
	C356.376862,96.607147 355.176880,96.781975 353.541443,96.957962 
	C351.075745,97.018745 349.045441,97.078369 346.637726,97.137634 
	C342.311920,98.130699 338.204285,98.721481 334.450226,100.206200 
	C327.818817,102.828918 321.402771,105.996178 314.581299,108.938103 
	C313.846466,108.964409 313.426910,108.988106 312.667572,109.036453 
	C310.882874,109.700211 309.437897,110.339310 307.866638,110.751450 
	C307.457764,110.327065 307.175232,110.129631 307.045105,109.656143 
	C307.511230,108.615784 307.824982,107.851463 308.138733,107.087151 
	C309.514557,106.412163 310.890350,105.737183 312.712006,105.202469 
	C313.436554,105.226898 313.715271,105.111053 313.993988,104.995209 
	C314.161865,104.985481 314.329712,104.975746 315.041565,104.941948 
	C316.041138,104.605705 316.496765,104.293526 316.952393,103.981354 
	C317.089630,103.674263 317.291656,103.418304 318.152405,103.054436 
	C324.508759,100.935814 330.271149,98.976242 336.033569,97.016663 
	C337.770721,96.702599 339.507843,96.388519 342.046387,96.049698 
	C346.273468,95.043221 349.699097,94.061501 353.124756,93.079788 
	C354.456268,93.076340 355.787750,93.072891 357.691162,93.270042 
	C358.865234,93.327919 359.467346,93.185196 360.069489,93.042480 
	C360.069489,93.042488 360.528320,93.053513 361.226013,93.045715 
	C366.941467,93.038055 371.959229,93.038208 376.977020,93.038361 
	C376.977020,93.038361 377.001221,93.002609 377.162659,93.253990 
	C377.879883,93.647217 378.435638,93.789055 378.991394,93.930893 
	C379.737061,93.967186 380.482758,94.003471 381.840546,94.290947 
	C386.301056,95.019348 390.149475,95.496559 393.997864,95.973778 
z"
          />
          <path
            fill="#FBC531"
            opacity="1.000000"
            stroke="none"
            d="
M329.009918,81.008835 
	C306.900024,88.703705 288.256104,101.464012 272.730530,119.782867 
	C269.952637,122.856880 267.517700,125.430862 264.797211,127.994476 
	C264.321198,127.977943 264.130798,127.971779 263.996826,127.622543 
	C269.565918,120.351578 274.442657,112.761490 280.771667,106.683678 
	C286.992462,100.709793 294.696625,96.283531 301.729645,91.150452 
	C303.523254,89.841393 305.049591,87.968132 307.027832,87.146774 
	C313.264679,84.557312 319.657776,82.344322 326.330475,79.989761 
	C327.450317,80.332214 328.230133,80.670532 329.009918,81.008835 
z"
          />
          <path
            fill="#F9CA48"
            opacity="1.000000"
            stroke="none"
            d="
M248.923950,183.029022 
	C248.365585,182.521393 247.807205,182.013763 247.431931,181.014175 
	C250.108398,174.032028 252.601776,167.541840 255.095169,161.051666 
	C256.367065,159.265717 257.638977,157.479767 259.200806,155.184967 
	C259.671234,154.125717 259.851746,153.575333 260.032257,153.024933 
	C260.032257,153.024933 260.015656,152.998779 260.235962,152.904572 
	C260.725525,152.600540 260.895874,152.328766 260.967316,151.994995 
	C262.213867,150.192078 263.460419,148.389160 265.125122,146.103027 
	C267.743683,143.729630 269.944061,141.839478 272.051331,140.301422 
	C270.834503,142.669174 270.004272,144.934189 268.538361,146.659012 
	C260.493927,156.124435 254.819199,166.824326 251.153656,178.657471 
	C250.676697,180.197144 249.679932,181.575790 248.923950,183.029022 
z"
          />
          <path
            fill="#FBCA42"
            opacity="1.000000"
            stroke="none"
            d="
M388.914001,72.101700 
	C389.430542,73.280281 389.947113,74.458870 390.756714,76.306015 
	C373.884308,73.889587 357.361572,74.638870 340.590576,76.826447 
	C341.197662,76.044128 342.102509,75.516441 343.382324,74.985352 
	C345.512177,74.342514 347.267120,73.703072 349.489319,73.060516 
	C361.638245,72.733627 373.319885,72.409859 385.395172,72.091057 
	C386.835388,72.085869 387.882019,72.075722 388.935394,72.070946 
	C388.942169,72.076324 388.913971,72.101700 388.914001,72.101700 
z"
          />
          <path
            fill="#F9CA48"
            opacity="1.000000"
            stroke="none"
            d="
M488.025574,177.551483 
	C487.817688,178.225555 487.605682,178.501770 486.907410,178.664581 
	C484.866333,175.086365 483.571991,171.473709 481.705841,168.185562 
	C476.902893,159.722809 471.846680,151.403778 467.247864,143.018799 
	C468.079254,143.001587 468.554504,142.992889 469.029755,142.984192 
	C472.715546,148.219650 476.671936,153.289612 480.005615,158.740433 
	C482.921082,163.507492 485.194275,168.675140 487.610748,173.732941 
	C488.079712,174.714539 487.901245,176.005417 488.025574,177.551483 
z"
          />
          <path
            fill="#FBC531"
            opacity="1.000000"
            stroke="none"
            d="
M435.978455,93.022400 
	C433.016083,91.707657 430.114807,90.227119 427.079987,89.109093 
	C417.769531,85.679131 408.380615,82.460823 399.093109,78.971474 
	C397.550751,78.391991 396.363983,76.865944 395.338348,75.387459 
	C396.109009,74.992340 396.554169,74.987091 397.329559,75.143784 
	C403.105743,77.185707 408.551727,79.065681 414.325806,80.963936 
	C415.091888,80.994591 415.529846,81.006973 416.141907,81.320992 
	C418.874634,82.749626 421.433289,83.876625 424.202637,85.257034 
	C425.264099,85.683090 426.114868,85.855736 427.129089,86.317764 
	C429.203735,87.721207 431.114960,88.835274 433.260834,89.970963 
	C433.495514,89.992577 433.965668,90.026291 434.096313,90.298752 
	C434.821198,91.376701 435.415436,92.182190 436.002747,92.995987 
	C435.995789,93.004303 435.978455,93.022400 435.978455,93.022400 
z"
          />
          <path
            fill="#FBC531"
            opacity="1.000000"
            stroke="none"
            d="
M466.948883,122.051491 
	C464.792542,120.024124 462.669373,117.960007 460.473846,115.976021 
	C452.778015,109.021790 445.054321,102.098373 437.352844,95.150345 
	C436.878967,94.722832 436.505646,94.183830 436.031982,93.359543 
	C435.978455,93.022400 435.995789,93.004303 436.353302,93.034378 
	C438.802094,94.050949 440.893341,95.037437 443.100708,96.236542 
	C443.425171,96.708786 443.687195,96.891609 444.156128,97.295059 
	C446.199371,98.741219 448.089233,99.889954 450.127319,101.318306 
	C451.504578,102.739426 452.733612,103.880920 454.101929,105.299118 
	C455.147339,106.402069 456.053497,107.228310 457.109009,108.334435 
	C458.500916,109.743874 459.743439,110.873413 461.098633,112.219498 
	C461.417358,112.703499 461.682495,112.884392 462.005341,112.989151 
	C462.003998,112.999596 462.022308,112.987099 462.136444,113.256180 
	C462.833710,114.019707 463.416840,114.514153 464.140503,115.289886 
	C465.203094,116.708031 466.125214,117.844894 467.037262,119.355499 
	C467.029541,120.475266 467.031891,121.221283 467.012817,121.988251 
	C466.991394,122.009209 466.948883,122.051491 466.948883,122.051491 
z"
          />
          <path
            fill="#FBCA42"
            opacity="1.000000"
            stroke="none"
            d="
M422.996338,105.898384 
	C423.436523,105.935944 423.876709,105.973503 424.856995,106.296280 
	C427.907837,107.760101 430.418610,108.938721 432.929382,110.117340 
	C434.058807,111.055161 435.188263,111.992981 436.799225,113.270752 
	C438.841370,114.431808 440.401947,115.252914 441.962555,116.074020 
	C442.076935,116.378609 442.266632,116.625237 442.880371,117.206383 
	C443.508057,118.376778 443.787079,119.154716 444.036804,119.965263 
	C444.007477,119.997879 443.950104,120.064240 443.603638,120.015427 
	C441.178223,118.975304 439.099274,117.983994 437.012329,116.994415 
	C437.004364,116.996147 437.001953,116.980377 436.862061,116.691994 
	C435.808472,115.961388 434.894836,115.519165 433.725555,114.885666 
	C432.634949,114.132881 431.799957,113.571373 430.765503,112.736267 
	C428.082672,111.156693 425.594025,109.860718 423.117157,108.542603 
	C418.407166,106.036110 413.703918,103.516960 408.997864,101.003067 
	C408.997864,101.003067 409.000000,101.000000 409.334900,100.997017 
	C410.114685,100.981728 410.559540,100.969421 411.004395,100.957108 
	C411.444214,100.978172 411.884003,100.999229 412.892761,101.282768 
	C415.969086,102.376114 418.476532,103.206993 420.983948,104.037865 
	C421.100830,104.348564 421.296295,104.597588 421.891296,105.101303 
	C422.410400,105.681015 422.671753,105.841263 422.996338,105.898384 
z"
          />
          <path
            fill="#FBCA42"
            opacity="1.000000"
            stroke="none"
            d="
M468.979553,142.650223 
	C468.554504,142.992889 468.079254,143.001587 466.967621,142.853653 
	C464.012543,140.142471 461.876465,137.386230 459.331207,135.081970 
	C455.757141,131.846268 451.879028,128.946442 448.112976,125.911682 
	C448.095001,125.920403 448.062408,125.906456 448.027130,125.576881 
	C446.644592,123.519615 445.297363,121.791931 443.950104,120.064240 
	C443.950104,120.064240 444.007477,119.997879 444.391235,119.979401 
	C447.191193,121.613213 449.607452,123.265488 452.023682,124.917770 
	C453.157745,125.851402 454.291809,126.785034 455.816101,128.077148 
	C456.412750,128.700089 456.678619,128.873306 457.003967,128.955261 
	C457.003967,128.955261 457.013000,128.987457 457.128815,129.206818 
	C457.432892,129.700760 457.688965,129.876709 458.012817,129.953979 
	C458.012817,129.953979 458.452942,130.017303 458.800476,130.256317 
	C459.414978,130.697006 459.681946,130.898682 459.948883,131.100372 
	C460.063080,131.394379 460.247467,131.634430 460.748962,132.218109 
	C461.337921,133.056747 461.679962,133.497803 462.022034,133.938873 
	C462.022034,133.938873 462.474915,133.996597 462.757446,134.353287 
	C464.336090,135.845184 465.632172,136.980408 466.928253,138.115616 
	C467.595306,139.515823 468.262329,140.916046 468.979553,142.650223 
z"
          />
          <path
            fill="#F0B813"
            opacity="1.000000"
            stroke="none"
            d="
M307.747589,107.096176 
	C307.824982,107.851463 307.511230,108.615784 306.842163,109.786850 
	C306.228271,110.409363 306.065491,110.682281 305.998657,111.005341 
	C305.998779,110.998306 306.012360,111.001831 305.637299,111.001503 
	C300.133972,114.203056 294.943665,117.310799 289.897034,120.636597 
	C285.854492,123.300674 281.986511,126.229607 278.023743,129.023788 
	C278.006439,129.006577 277.971466,128.972458 277.971466,128.972458 
	C279.448456,127.332588 280.741943,125.466461 282.435120,124.093117 
	C288.803223,118.927879 295.227600,113.823624 301.807800,108.934570 
	C303.291412,107.832260 305.488068,107.689636 307.747589,107.096176 
z"
          />
          <path
            fill="#F0B813"
            opacity="1.000000"
            stroke="none"
            d="
M254.733429,161.033035 
	C252.601776,167.541840 250.108398,174.032028 247.341873,180.740936 
	C247.022095,177.247314 246.975464,173.535004 246.962067,169.173401 
	C246.995270,168.524109 247.037766,168.032150 247.345337,168.118851 
	C248.097748,167.116592 248.545120,166.028656 248.986832,164.938446 
	C249.654755,163.289886 250.318741,161.639725 250.984406,159.990250 
	C252.113510,160.331635 253.242615,160.673035 254.733429,161.033035 
z"
          />
          <path
            fill="#EEBB28"
            opacity="1.000000"
            stroke="none"
            d="
M343.007385,74.988747 
	C342.102509,75.516441 341.197662,76.044128 340.142334,76.852844 
	C337.715515,81.280922 333.801941,81.155304 329.445068,81.008682 
	C328.230133,80.670532 327.450317,80.332214 326.377502,79.636787 
	C328.794647,73.319168 334.472809,79.157532 338.000000,76.202736 
	C338.000000,75.195129 338.000000,74.097870 338.355469,73.008331 
	C340.143097,73.673615 341.575226,74.331177 343.007385,74.988747 
z"
          />
          <path
            fill="#F0B813"
            opacity="1.000000"
            stroke="none"
            d="
M272.144440,139.949310 
	C269.944061,141.839478 267.743683,143.729630 265.269745,145.808228 
	C266.330383,143.462250 267.368774,140.705338 269.068665,138.446091 
	C271.560425,135.134369 274.525696,132.178894 277.630310,129.022034 
	C277.971466,128.972458 278.006439,129.006577 278.011108,129.386230 
	C277.130615,131.230545 276.183014,132.661819 275.377228,134.168961 
	C274.509888,135.791245 273.775513,137.484650 272.886169,139.323013 
	C272.623352,139.719391 272.408447,139.869492 272.144440,139.949310 
z"
          />
          <path
            fill="#FBCA42"
            opacity="1.000000"
            stroke="none"
            d="
M263.940430,127.965622 
	C264.130798,127.971779 264.321198,127.977943 264.774170,128.330109 
	C265.036713,129.124527 265.036713,129.572952 265.036713,130.021362 
	C265.036713,130.021362 265.007721,130.500519 264.682312,130.790009 
	C263.909424,131.721283 263.461945,132.363068 263.014465,133.004852 
	C263.014465,133.004852 263.000000,132.998779 262.747253,133.201324 
	C261.980469,134.594315 261.466400,135.784760 260.952362,136.975220 
	C260.317596,138.084351 259.682831,139.193481 258.730865,140.784241 
	C257.945435,142.177536 257.477173,143.089203 257.008911,144.000854 
	C257.008911,144.000854 256.998993,143.998779 256.780823,144.110199 
	C256.290558,144.419952 256.105713,144.680237 256.008057,145.002472 
	C256.008057,145.002472 255.538254,145.038010 255.268829,145.018188 
	C254.999405,144.998352 254.931686,144.930496 254.943726,144.539352 
	C255.440033,137.360092 259.640015,132.634979 263.940430,127.965622 
z"
          />
          <path
            fill="#FBCA42"
            opacity="1.000000"
            stroke="none"
            d="
M478.977783,135.032990 
	C479.981384,138.108170 480.984985,141.183350 481.997986,144.640930 
	C478.646790,139.631912 475.286163,134.240494 471.961426,128.424713 
	C471.997345,128.000381 472.096893,127.908943 472.411621,127.979980 
	C474.136871,129.389633 475.547394,130.728241 477.065399,132.274445 
	C477.363800,132.741348 477.610748,132.925537 477.951782,133.328568 
	C478.316681,134.083969 478.643524,134.545456 478.979126,135.007874 
	C478.987854,135.008804 478.977783,135.032990 478.977783,135.032990 
z"
          />
          <path
            fill="#EFBD2D"
            opacity="1.000000"
            stroke="none"
            d="
M250.990082,159.604797 
	C250.318741,161.639725 249.654755,163.289886 248.986832,164.938446 
	C248.545120,166.028656 248.097748,167.116592 247.322357,167.884308 
	C246.654953,167.406494 246.318130,167.249954 245.981293,167.093399 
	C245.636414,165.322052 245.291519,163.550720 245.361786,161.373718 
	C247.189301,158.634659 248.601654,156.301239 250.253464,153.949585 
	C250.492920,153.931381 250.966080,153.849014 251.187973,153.991058 
	C251.605850,154.420731 251.801819,154.708344 251.997803,154.995972 
	C251.663788,156.403778 251.329773,157.811569 250.990082,159.604797 
z"
          />
          <path
            fill="#F0B813"
            opacity="1.000000"
            stroke="none"
            d="
M335.581238,97.007675 
	C330.271149,98.976242 324.508759,100.935814 318.365570,102.930489 
	C322.662323,97.996620 328.687469,96.899612 335.581238,97.007675 
z"
          />
          <path
            fill="#F0B813"
            opacity="1.000000"
            stroke="none"
            d="
M265.382019,129.976212 
	C265.036713,129.572952 265.036713,129.124527 265.059753,128.340485 
	C267.517700,125.430862 269.952637,122.856880 272.683350,120.124107 
	C274.081879,125.848679 268.980438,127.217392 265.382019,129.976212 
z"
          />
          <path
            fill="#F0B813"
            opacity="1.000000"
            stroke="none"
            d="
M420.997314,103.678505 
	C418.476532,103.206993 415.969086,102.376114 413.222961,101.288284 
	C416.063934,100.517822 419.143616,100.004318 422.517914,99.441689 
	C421.984314,100.814461 421.497528,102.066803 420.997314,103.678505 
z"
          />
          <path
            fill="#F0B813"
            opacity="1.000000"
            stroke="none"
            d="
M393.941925,95.587967 
	C390.149475,95.496559 386.301056,95.019348 382.213745,94.299377 
	C386.112823,92.695763 390.245972,91.385353 393.941925,95.587967 
z"
          />
          <path
            fill="#F0B813"
            opacity="1.000000"
            stroke="none"
            d="
M376.793884,92.691589 
	C371.959229,93.038208 366.941467,93.038055 361.455078,93.032372 
	C366.144501,91.652519 371.263702,89.388298 376.793884,92.691589 
z"
          />
          <path
            fill="#F0B813"
            opacity="1.000000"
            stroke="none"
            d="
M452.011780,124.558540 
	C449.607452,123.265488 447.191193,121.613213 444.420532,119.946793 
	C443.787079,119.154716 443.508057,118.376778 443.090546,117.314011 
	C448.075714,116.759529 450.352997,120.081650 452.011780,124.558540 
z"
          />
          <path
            fill="#F0B813"
            opacity="1.000000"
            stroke="none"
            d="
M352.668152,93.051361 
	C349.699097,94.061501 346.273468,95.043221 342.430664,96.034775 
	C344.208984,90.974403 348.730957,93.755890 352.668152,93.051361 
z"
          />
          <path
            fill="#F0B813"
            opacity="1.000000"
            stroke="none"
            d="
M432.916260,109.753197 
	C430.418610,108.938721 427.907837,107.760101 425.187683,106.316612 
	C429.146667,103.538704 430.843140,106.895432 432.916260,109.753197 
z"
          />
          <path
            fill="#F7B706"
            opacity="1.000000"
            stroke="none"
            d="
M396.999359,74.981842 
	C396.554169,74.987091 396.109009,74.992340 395.329803,74.993469 
	C393.212189,74.006599 391.428558,73.023865 389.279480,72.071411 
	C388.913971,72.101700 388.942169,72.076324 389.017456,71.718246 
	C392.814575,69.615623 395.788940,70.978050 396.999359,74.981842 
z"
          />
          <path
            fill="#FBCA42"
            opacity="1.000000"
            stroke="none"
            d="
M255.033646,145.031891 
	C255.055786,145.799225 255.043701,146.533020 254.760132,147.849518 
	C253.308319,149.974884 252.128006,151.517517 250.965164,152.664398 
	C251.659302,149.845749 252.335953,147.422867 253.332962,144.983337 
	C254.079437,144.954636 254.505569,144.942566 254.931686,144.930496 
	C254.931686,144.930496 254.999405,144.998352 255.033646,145.031891 
z"
          />
          <path
            fill="#F0B813"
            opacity="1.000000"
            stroke="none"
            d="
M410.987610,100.567719 
	C410.559540,100.969421 410.114685,100.981728 409.336060,100.995201 
	C406.478546,99.845154 403.954803,98.693939 401.194092,97.282669 
	C404.428040,97.652504 408.571991,96.146591 410.987610,100.567719 
z"
          />
          <path
            fill="#F0B813"
            opacity="1.000000"
            stroke="none"
            d="
M250.947678,153.060165 
	C252.128006,151.517517 253.308319,149.974884 254.757584,148.217514 
	C257.467926,151.387878 255.151428,153.157471 252.344879,154.937241 
	C251.801819,154.708344 251.605850,154.420731 251.182373,153.806656 
	C250.954895,153.480209 250.914108,153.109741 250.922714,153.096771 
	C250.931320,153.083817 250.947678,153.060165 250.947678,153.060165 
z"
          />
          <path
            fill="#F0B813"
            opacity="1.000000"
            stroke="none"
            d="
M466.920227,137.759476 
	C465.632172,136.980408 464.336090,135.845184 462.984955,134.372253 
	C464.257324,135.157471 465.584747,136.280411 466.920227,137.759476 
z"
          />
          <path
            fill="#F0B813"
            opacity="1.000000"
            stroke="none"
            d="
M441.921967,115.717072 
	C440.401947,115.252914 438.841370,114.431808 437.119415,113.316193 
	C438.599182,113.801155 440.240265,114.580635 441.921967,115.717072 
z"
          />
          <path
            fill="#FBC531"
            opacity="1.000000"
            stroke="none"
            d="
M469.955872,126.061615 
	C468.986084,124.939354 468.016296,123.817093 466.997681,122.373161 
	C466.948883,122.051491 466.991394,122.009209 467.344666,122.023788 
	C468.481049,122.973885 469.264130,123.909409 470.026245,125.149246 
	C469.988800,125.656242 469.972321,125.858925 469.955872,126.061615 
z"
          />
          <path
            fill="#FBCA42"
            opacity="1.000000"
            stroke="none"
            d="
M470.073059,126.324783 
	C469.972321,125.858925 469.988800,125.656242 470.342834,125.198868 
	C471.106323,124.977608 471.532257,125.011055 471.979065,125.022247 
	C472.000000,125.000000 471.955170,125.041771 471.966797,125.401291 
	C472.017944,126.476852 472.057404,127.192902 472.096893,127.908943 
	C472.096893,127.908943 471.997345,128.000381 471.945251,128.046539 
	C471.325500,127.591110 470.757904,127.089523 470.073059,126.324783 
z"
          />
          <path
            fill="#F0B813"
            opacity="1.000000"
            stroke="none"
            d="
M261.314331,136.920105 
	C261.466400,135.784760 261.980469,134.594315 262.750061,133.208344 
	C262.562469,134.296875 262.119385,135.580933 261.314331,136.920105 
z"
          />
          <path
            fill="#F0B813"
            opacity="1.000000"
            stroke="none"
            d="
M257.352478,143.942108 
	C257.477173,143.089203 257.945435,142.177536 258.695374,141.110794 
	C258.550079,141.931595 258.123077,142.907471 257.352478,143.942108 
z"
          />
          <path
            fill="#F0B813"
            opacity="1.000000"
            stroke="none"
            d="
M263.333008,132.958206 
	C263.461945,132.363068 263.909424,131.721283 264.675415,131.029922 
	C264.546448,131.624084 264.098999,132.267822 263.333008,132.958206 
z"
          />
          <path
            fill="#F0B813"
            opacity="1.000000"
            stroke="none"
            d="
M259.738678,153.047424 
	C259.851746,153.575333 259.671234,154.125717 259.248840,154.845367 
	C259.153015,154.366394 259.299042,153.718155 259.738678,153.047424 
z"
          />
          <path
            fill="#F0B813"
            opacity="1.000000"
            stroke="none"
            d="
M378.812012,93.701538 
	C378.435638,93.789055 377.879883,93.647217 377.149170,93.269104 
	C377.527039,93.179291 378.079803,93.325737 378.812012,93.701538 
z"
          />
          <path
            fill="#F0B813"
            opacity="1.000000"
            stroke="none"
            d="
M359.711121,93.027252 
	C359.467346,93.185196 358.865234,93.327919 358.090637,93.262344 
	C358.396362,93.040047 358.874542,93.026031 359.711121,93.027252 
z"
          />
          <path
            fill="#F0B813"
            opacity="1.000000"
            stroke="none"
            d="
M316.667511,104.006790 
	C316.496765,104.293526 316.041138,104.605705 315.292847,104.920456 
	C315.461029,104.626106 315.921814,104.329170 316.667511,104.006790 
z"
          />
          <path
            fill="#F0B813"
            opacity="1.000000"
            stroke="none"
            d="
M461.990173,133.644958 
	C461.679962,133.497803 461.337921,133.056747 460.951843,132.325348 
	C461.257996,132.473694 461.608154,132.912384 461.990173,133.644958 
z"
          />
          <path
            fill="#F0B813"
            opacity="1.000000"
            stroke="none"
            d="
M422.879211,105.700287 
	C422.671753,105.841263 422.410400,105.681015 422.107086,105.207405 
	C422.305634,105.089737 422.559021,105.258080 422.879211,105.700287 
z"
          />
          <path
            fill="#F0B813"
            opacity="1.000000"
            stroke="none"
            d="
M313.734070,104.996872 
	C313.715271,105.111053 313.436554,105.226898 313.056396,105.183174 
	C313.127991,105.015259 313.301086,105.006905 313.734070,104.996872 
z"
          />
          <path
            fill="#F0B813"
            opacity="1.000000"
            stroke="none"
            d="
M459.852478,130.856842 
	C459.681946,130.898682 459.414978,130.697006 459.021973,130.275452 
	C459.182648,130.241501 459.469360,130.427414 459.852478,130.856842 
z"
          />
          <path
            fill="#F0B813"
            opacity="1.000000"
            stroke="none"
            d="
M457.902161,129.747864 
	C457.688965,129.876709 457.432892,129.700760 457.145020,129.210892 
	C457.348907,129.102829 457.597595,129.284866 457.902161,129.747864 
z"
          />
          <path
            fill="#F0B813"
            opacity="1.000000"
            stroke="none"
            d="
M456.888000,128.745361 
	C456.678619,128.873306 456.412750,128.700089 456.096252,128.220306 
	C456.304840,128.097870 456.566772,128.274704 456.888000,128.745361 
z"
          />
          <path
            fill="#F0B813"
            opacity="1.000000"
            stroke="none"
            d="
M260.755676,152.111908 
	C260.895874,152.328766 260.725525,152.600540 260.236328,152.917603 
	C260.104797,152.701553 260.280701,152.436203 260.755676,152.111908 
z"
          />
          <path
            fill="#F0B813"
            opacity="1.000000"
            stroke="none"
            d="
M256.224670,144.893829 
	C256.105713,144.680237 256.290558,144.419952 256.780823,144.115112 
	C256.897430,144.328049 256.711517,144.586914 256.224670,144.893829 
z"
          />
          <path
            fill="#F7B706"
            opacity="1.000000"
            stroke="none"
            d="
M254.943726,144.539352 
	C254.505569,144.942566 254.079437,144.954636 253.270920,144.641235 
	C251.929337,142.545502 250.970139,140.775253 250.010956,139.005020 
	C254.355148,129.974152 258.438538,120.804153 263.151550,111.970055 
	C265.573639,107.430054 268.719208,103.110466 272.259857,99.375748 
	C276.452545,94.953255 281.466858,91.316994 286.028748,87.232368 
	C287.272827,86.118439 288.170593,84.617744 289.227264,83.294548 
	C288.907288,82.845627 288.587311,82.396706 288.267334,81.947784 
	C286.398041,82.876419 284.528778,83.805054 282.342346,84.875481 
	C282.011719,79.327942 284.202393,76.772659 289.771271,75.670410 
	C291.332092,75.361481 292.800140,74.583755 294.534637,74.300972 
	C293.825378,75.700287 292.892242,76.819702 291.595825,77.968857 
	C290.550659,78.508644 289.868805,79.018700 289.186920,79.528748 
	C289.563354,79.964539 290.165619,80.829102 290.280579,80.768547 
	C291.227386,80.269768 292.083710,79.599281 293.275146,78.838203 
	C295.178192,77.566139 296.760010,76.417015 298.371460,75.311058 
	C302.911224,72.195419 307.463165,69.097527 312.010284,65.992645 
	C312.010284,65.992638 311.999817,66.000656 312.219971,65.896469 
	C312.720276,65.588524 312.910431,65.320831 313.010590,64.989182 
	C313.010590,64.989182 313.000061,64.999672 313.395874,65.000549 
	C321.546692,62.819374 329.239777,60.374866 337.073212,58.524712 
	C345.953400,56.427330 354.922516,53.672474 363.920868,53.393433 
	C373.328644,53.101688 382.914154,54.915497 392.242706,56.730297 
	C401.229065,58.478531 410.471222,59.458740 418.586914,64.744324 
	C426.658600,70.001289 435.604706,73.926392 443.615082,79.264290 
	C455.814270,87.393471 465.115387,98.608803 472.962555,110.797478 
	C477.155273,117.309807 479.745850,124.853600 483.059479,131.931870 
	C482.999237,134.115799 483.348572,136.737228 479.336090,135.057587 
	C478.977783,135.032990 478.987854,135.008804 478.966583,134.705414 
	C478.601471,133.946228 478.257599,133.490433 477.913727,133.034637 
	C477.610748,132.925537 477.363800,132.741348 477.074463,131.881638 
	C476.157471,128.513351 476.603943,124.727509 471.955170,125.041771 
	C471.955170,125.041771 472.000000,125.000000 471.988831,124.652885 
	C470.334229,122.531090 468.690796,120.756409 467.047363,118.981743 
	C466.125214,117.844894 465.203094,116.708031 464.097931,114.971359 
	C463.284027,113.910057 462.653168,113.448578 462.022308,112.987091 
	C462.022308,112.987099 462.003998,112.999596 461.889954,112.775215 
	C461.570801,112.284271 461.307495,112.101646 460.985962,112.002960 
	C459.743439,110.873413 458.500916,109.743874 457.114502,107.957321 
	C455.967987,106.541008 454.965332,105.781715 453.962646,105.022415 
	C452.733612,103.880920 451.504578,102.739426 450.093506,100.939789 
	C449.306244,96.732239 446.660889,96.853516 444.002808,96.997635 
	C443.687195,96.891609 443.425171,96.708786 443.062103,95.874207 
	C440.845001,91.984627 438.606567,88.968437 433.965668,90.026291 
	C433.965668,90.026291 433.495514,89.992577 433.243927,89.600784 
	C430.983429,88.148796 428.974518,87.088585 426.965607,86.028381 
	C426.114868,85.855736 425.264099,85.683090 424.578430,85.249641 
	C427.711609,82.721703 433.303650,85.214386 434.929138,78.736153 
	C430.917542,79.765259 427.610992,80.613495 424.304443,81.461739 
	C424.419037,81.727112 424.533600,81.992493 424.648193,82.257866 
	C421.754730,81.845032 418.861267,81.432190 415.967804,81.019348 
	C415.529846,81.006973 415.091888,80.994591 414.279968,80.596970 
	C413.061646,76.273827 409.558411,76.492775 406.834381,76.119469 
	C402.600006,75.539169 399.469666,74.384048 400.357208,69.620552 
	C396.330231,68.289276 392.698059,67.204269 389.155090,65.880470 
	C386.342865,64.829712 385.039337,65.640266 385.011230,68.603958 
	C385.000244,69.764587 385.004333,70.925377 385.001587,72.086090 
	C373.319885,72.409859 361.638245,72.733627 349.409332,72.685577 
	C346.646942,71.235939 344.525238,69.834953 342.193420,69.160667 
	C339.157104,68.282661 337.579010,69.729240 338.000000,73.000610 
	C338.000000,74.097870 338.000000,75.195129 338.000000,76.202736 
	C334.472809,79.157532 328.794647,73.319168 326.037476,79.632645 
	C319.657776,82.344322 313.264679,84.557312 307.027832,87.146774 
	C305.049591,87.968132 303.523254,89.841393 301.729645,91.150452 
	C294.696625,96.283531 286.992462,100.709793 280.771667,106.683678 
	C274.442657,112.761490 269.565918,120.351578 263.996826,127.622543 
	C259.640015,132.634979 255.440033,137.360092 254.943726,144.539352 
z"
          />
          <path
            fill="#FBC531"
            opacity="1.000000"
            stroke="none"
            d="
M483.408417,132.020203 
	C479.745850,124.853600 477.155273,117.309807 472.962555,110.797478 
	C465.115387,98.608803 455.814270,87.393471 443.615082,79.264290 
	C435.604706,73.926392 426.658600,70.001289 418.586914,64.744324 
	C410.471222,59.458740 401.229065,58.478531 392.242706,56.730297 
	C382.914154,54.915497 373.328644,53.101688 363.920868,53.393433 
	C354.922516,53.672474 345.953400,56.427330 337.073212,58.524712 
	C329.239777,60.374866 321.546692,62.819374 313.389648,65.004501 
	C320.181030,58.773018 329.346527,57.051071 338.089020,55.238651 
	C349.021240,52.972271 360.277832,51.584759 371.431885,51.303104 
	C379.554230,51.098000 387.783966,53.042896 395.876038,54.520706 
	C400.816620,55.422970 405.556854,57.376957 410.450806,58.604546 
	C412.124725,59.024429 413.992584,58.671207 414.582245,58.671207 
	C418.718262,61.074852 422.007355,63.193001 425.473785,64.963974 
	C438.449341,71.593025 450.543152,79.376976 460.139740,90.571724 
	C463.881317,94.936401 467.682220,99.250282 471.761108,104.137527 
	C473.031799,105.799026 473.997437,106.910301 474.963074,108.021576 
	C475.470306,108.915535 475.977570,109.809502 476.740692,111.240280 
	C477.994965,113.519325 478.993347,115.261566 479.991730,117.003799 
	C479.991730,117.003799 480.000000,117.000305 480.033447,117.346649 
	C481.017334,118.802467 481.967834,119.911949 482.918335,121.021423 
	C482.940491,121.463760 482.962677,121.906113 482.993744,122.680786 
	C485.332214,130.075302 487.661804,137.137466 489.985168,144.603638 
	C487.905090,140.707932 485.831207,136.408234 483.408417,132.020203 
z"
          />
          <path
            fill="#FBC531"
            opacity="1.000000"
            stroke="none"
            d="
M291.959106,77.939117 
	C292.892242,76.819702 293.825378,75.700287 294.904419,74.303253 
	C295.050293,74.025642 295.529816,74.005714 295.983826,73.886627 
	C296.704956,73.573898 296.886749,73.319313 296.983276,73.003807 
	C296.983276,73.003807 297.001221,73.000809 297.219666,72.885849 
	C297.715210,72.575310 297.900879,72.314613 297.995117,71.988800 
	C300.393799,70.284103 302.641296,68.275040 305.244385,66.981766 
	C306.977692,66.120613 309.218384,66.280693 311.621124,65.988174 
	C307.463165,69.097527 302.911224,72.195419 298.371460,75.311058 
	C296.760010,76.417015 295.178192,77.566139 293.056152,78.721100 
	C292.261322,78.532593 292.071198,78.263420 291.959106,77.939117 
z"
          />
          <path
            fill="#EEBB28"
            opacity="1.000000"
            stroke="none"
            d="
M377.118378,33.327332 
	C381.898315,34.024181 386.678284,34.721027 391.771851,35.886978 
	C392.080536,36.543880 392.075592,36.731678 392.070648,36.919476 
	C388.551910,36.939732 385.015045,37.174721 381.522827,36.880432 
	C380.007935,36.752773 378.584930,35.535240 377.080597,34.251541 
	C377.041016,33.690342 377.118378,33.327332 377.118378,33.327332 
z"
          />
          <path
            fill="#EEBB28"
            opacity="1.000000"
            stroke="none"
            d="
M353.065277,35.397873 
	C353.231873,34.821915 353.486572,34.604084 353.843689,34.154503 
	C356.003815,33.691692 358.061523,33.460632 360.544067,33.425117 
	C360.968933,33.620663 361.000549,33.980785 361.000549,33.980785 
	C358.978058,37.194889 356.414459,38.017105 353.065277,35.397873 
z"
          />
          <path
            fill="#EEBB28"
            opacity="1.000000"
            stroke="none"
            d="
M257.585388,103.068039 
	C257.200653,102.696716 257.205872,102.283852 257.287964,101.572128 
	C257.988342,100.187599 258.611786,99.101944 259.348022,97.724960 
	C259.777313,97.289413 260.093842,97.145187 260.666504,96.942703 
	C260.471680,99.186836 262.751953,102.799789 257.585388,103.068039 
z"
          />
          <path
            fill="#EEBB28"
            opacity="1.000000"
            stroke="none"
            d="
M450.243225,65.164841 
	C451.044250,65.349861 451.916351,65.874695 452.905701,66.700424 
	C452.059998,68.823311 451.097046,70.645302 450.134064,72.467293 
	C449.875153,72.367668 449.616211,72.268051 449.357300,72.168434 
	C449.676300,69.947174 449.995331,67.725906 450.243225,65.164841 
z"
          />
          <path
            fill="#EEBB28"
            opacity="1.000000"
            stroke="none"
            d="
M462.229767,77.081696 
	C462.926361,77.305046 463.324219,77.605217 463.869690,78.260742 
	C463.163574,79.500786 462.309814,80.385475 461.456085,81.270172 
	C461.022858,80.911217 460.589600,80.552254 460.156372,80.193291 
	C460.747925,79.181702 461.339478,78.170113 462.229767,77.081696 
z"
          />
          <path
            fill="#EEBB28"
            opacity="1.000000"
            stroke="none"
            d="
M479.990173,116.618408 
	C478.993347,115.261566 477.994965,113.519325 476.984589,111.427628 
	C477.977936,112.796448 478.983276,114.514725 479.990173,116.618408 
z"
          />
          <path
            fill="#EEBB28"
            opacity="1.000000"
            stroke="none"
            d="
M474.967041,107.656372 
	C473.997437,106.910301 473.031799,105.799026 472.026154,104.343872 
	C472.981079,105.097046 473.976044,106.194107 474.967041,107.656372 
z"
          />
          <path
            fill="#EEBB28"
            opacity="1.000000"
            stroke="none"
            d="
M327.992401,43.005783 
	C329.138489,43.171532 330.284546,43.337276 331.430634,43.503025 
	C331.334961,43.939274 331.239288,44.375523 331.143585,44.811771 
	C330.142365,44.430573 329.141113,44.049377 328.069916,43.334171 
	C327.999939,43.000164 327.992401,43.005779 327.992401,43.005783 
z"
          />
          <path
            fill="#EEBB28"
            opacity="1.000000"
            stroke="none"
            d="
M445.686157,61.200905 
	C446.808167,61.502934 447.697601,62.010193 448.703064,62.801151 
	C447.701904,62.515926 444.710236,65.705063 445.686157,61.200905 
z"
          />
          <path
            fill="#EEBB28"
            opacity="1.000000"
            stroke="none"
            d="
M482.907410,120.673721 
	C481.967834,119.911949 481.017334,118.802467 480.029968,117.350800 
	C480.960876,118.114418 481.928680,119.220215 482.907410,120.673721 
z"
          />
          <path
            fill="#EEBB28"
            opacity="1.000000"
            stroke="none"
            d="
M325.666565,41.020103 
	C326.683899,40.960159 327.415771,40.951248 328.510468,40.941238 
	C328.646454,41.452782 328.419586,41.965412 328.092560,42.741913 
	C327.992401,43.005779 327.999939,43.000164 328.002930,42.997322 
	C326.898285,42.698502 325.790619,42.402527 324.340454,42.056732 
	C324.458984,41.694988 324.920044,41.383064 325.666565,41.020103 
z"
          />
          <path
            fill="#E8C152"
            opacity="1.000000"
            stroke="none"
            d="
M486.178650,121.388504 
	C486.207703,121.041183 486.682953,121.022377 486.921448,121.012009 
	C487.447296,121.783867 487.734680,122.566101 488.007996,123.675629 
	C487.613342,123.969604 487.232788,123.936287 486.575256,123.854599 
	C486.248718,123.116089 486.199158,122.425964 486.178650,121.388504 
z"
          />
          <path
            fill="#EEBB28"
            opacity="1.000000"
            stroke="none"
            d="
M344.179108,37.204849 
	C343.988770,36.964294 343.984894,36.464664 343.976685,36.214703 
	C343.968445,35.964741 343.986176,35.987389 344.358337,35.985394 
	C345.224854,35.888859 345.719208,35.794323 346.213562,35.699783 
	C346.597687,35.683197 346.981842,35.666615 347.652710,35.675663 
	C346.749451,36.282665 345.559448,36.864033 344.179108,37.204849 
z"
          />
          <path
            fill="#EEBB28"
            opacity="1.000000"
            stroke="none"
            d="
M480.845215,108.156670 
	C481.261810,107.962921 481.439606,107.954964 481.884155,107.919067 
	C482.383514,108.422806 482.616119,108.954506 482.870605,109.776077 
	C482.130493,109.491455 481.368469,108.916954 480.845215,108.156670 
z"
          />
          <path
            fill="#EEBB28"
            opacity="1.000000"
            stroke="none"
            d="
M415.996918,44.996662 
	C415.699127,45.491455 415.404419,45.989586 415.109680,46.487709 
	C414.907166,46.314095 414.704620,46.140484 414.502075,45.966873 
	C414.819427,45.673290 415.136810,45.379711 415.730469,45.046127 
	C416.006714,45.006123 416.000000,45.000000 415.996918,44.996662 
z"
          />
          <path
            fill="#EEBB28"
            opacity="1.000000"
            stroke="none"
            d="
M473.065216,92.928764 
	C472.535278,92.672073 472.005371,92.415390 471.475433,92.158691 
	C471.717224,91.805794 471.959015,91.452896 472.475281,91.065376 
	C472.749725,91.030754 472.879547,90.964226 472.945007,91.297287 
	C473.028717,92.063148 473.046967,92.495956 473.065216,92.928764 
z"
          />
          <path
            fill="#E8C152"
            opacity="1.000000"
            stroke="none"
            d="
M420.106201,45.852333 
	C420.097992,45.822983 420.104523,45.326820 420.136597,45.079250 
	C420.826843,44.912968 421.485077,44.994255 422.466797,45.144325 
	C422.790344,45.213112 422.883392,45.675564 422.866394,45.911850 
	C422.435638,46.115276 422.021881,46.082413 421.083801,45.996552 
	C420.559448,45.943550 420.128967,45.940868 420.128967,45.940868 
	C420.128967,45.940868 420.114410,45.881680 420.106201,45.852333 
z"
          />
          <path
            fill="#F7B706"
            opacity="1.000000"
            stroke="none"
            d="
M297.844604,71.842270 
	C297.900879,72.314613 297.715210,72.575310 297.218689,72.876762 
	C297.230865,72.553673 297.462494,72.124702 297.844604,71.842270 
z"
          />
          <path
            fill="#E8C152"
            opacity="1.000000"
            stroke="none"
            d="
M376.754883,33.241127 
	C377.118378,33.327332 377.041016,33.690342 377.019897,33.875237 
	C376.562714,34.035561 376.126678,34.010986 375.363831,33.973419 
	C375.037048,33.960430 374.985870,33.598248 374.945923,33.420612 
	C375.401093,33.213627 375.896210,33.184273 376.754883,33.241127 
z"
          />
          <path
            fill="#E8C152"
            opacity="1.000000"
            stroke="none"
            d="
M361.323486,33.971287 
	C361.000549,33.980785 360.968933,33.620663 360.932129,33.444710 
	C361.381866,33.211823 361.868469,33.154888 362.720825,33.148689 
	C363.086578,33.199429 362.999512,33.561535 362.967834,33.744362 
	C362.506256,33.938721 362.076324,33.950256 361.323486,33.971287 
z"
          />
          <path
            fill="#EEBB28"
            opacity="1.000000"
            stroke="none"
            d="
M272.192657,80.788269 
	C272.132507,80.395226 272.333557,79.863533 272.780823,79.176651 
	C272.835968,79.564171 272.644897,80.106888 272.192657,80.788269 
z"
          />
          <path
            fill="#EEBB28"
            opacity="1.000000"
            stroke="none"
            d="
M396.145874,37.237720 
	C396.464081,37.027515 396.911316,37.033188 397.675049,37.156395 
	C397.419312,37.333817 396.847107,37.393707 396.145874,37.237720 
z"
          />
          <path
            fill="#E8C152"
            opacity="1.000000"
            stroke="none"
            d="
M426.179077,48.112045 
	C426.655151,48.057709 427.197266,48.246193 427.918182,48.667465 
	C427.910034,48.926743 427.723145,48.953232 427.254120,49.006832 
	C426.729736,48.807587 426.487457,48.581226 426.179077,48.112045 
z"
          />
          <path
            fill="#EEBB28"
            opacity="1.000000"
            stroke="none"
            d="
M329.259491,40.873772 
	C328.939514,40.861126 328.926971,40.373253 328.912415,40.128838 
	C329.351593,39.829643 329.805359,39.774872 330.601318,39.724323 
	C330.488831,40.114506 330.034149,40.500462 329.259491,40.873772 
z"
          />
          <path
            fill="#EEBB28"
            opacity="1.000000"
            stroke="none"
            d="
M443.190369,59.074150 
	C443.607391,59.033455 444.131226,59.275333 444.773193,59.786140 
	C444.359955,59.822285 443.828583,59.589504 443.190369,59.074150 
z"
          />
          <path
            fill="#E8C152"
            opacity="1.000000"
            stroke="none"
            d="
M473.336395,92.934540 
	C473.046967,92.495956 473.028717,92.063148 473.017609,91.304688 
	C473.369446,91.417671 473.714203,91.856308 474.101868,92.580132 
	C473.965759,92.890312 473.786682,92.915306 473.336395,92.934540 
z"
          />
          <path
            fill="#EEBB28"
            opacity="1.000000"
            stroke="none"
            d="
M270.581055,82.071899 
	C270.673370,81.762955 271.018463,81.379288 271.644348,80.963036 
	C271.561371,81.286026 271.197632,81.641602 270.581055,82.071899 
z"
          />
          <path
            fill="#EEBB28"
            opacity="1.000000"
            stroke="none"
            d="
M402.048004,38.949463 
	C401.871033,38.725555 401.694061,38.501648 401.656067,38.186752 
	C401.795013,38.095760 401.902649,37.981709 401.972839,38.215515 
	C402.044708,38.616039 402.046356,38.782753 402.048004,38.949463 
z"
          />
          <path
            fill="#E8C152"
            opacity="1.000000"
            stroke="none"
            d="
M402.341583,38.906898 
	C402.046356,38.782753 402.044708,38.616039 402.049500,38.198952 
	C402.362152,37.990459 402.635223,38.108719 403.027527,38.466286 
	C402.998291,38.707588 402.816711,38.785965 402.341583,38.906898 
z"
          />
          <path
            fill="#EEBB28"
            opacity="1.000000"
            stroke="none"
            d="
M476.565735,97.170410 
	C476.737793,97.217819 476.797180,97.379196 476.864288,97.798630 
	C476.524780,97.892769 476.385101,97.635330 476.565735,97.170410 
z"
          />
          <path
            fill="#E8C152"
            opacity="1.000000"
            stroke="none"
            d="
M394.978058,36.518311 
	C395.156891,36.230896 395.469757,36.286888 395.893036,36.721786 
	C395.706543,36.961658 395.409393,36.881996 394.978058,36.518311 
z"
          />
          <path
            fill="#E8C152"
            opacity="1.000000"
            stroke="none"
            d="
M392.279297,36.833572 
	C392.075592,36.731678 392.080536,36.543880 392.124054,36.075249 
	C392.162598,35.794415 392.635834,35.894115 392.859467,35.988503 
	C392.959076,36.371105 392.760712,36.592701 392.279297,36.833572 
z"
          />
          <path
            fill="#EEBB28"
            opacity="1.000000"
            stroke="none"
            d="
M269.175537,84.869141 
	C269.130524,84.694969 269.324982,84.428574 269.743530,84.050400 
	C269.783447,84.218048 269.599213,84.497482 269.175537,84.869141 
z"
          />
          <path
            fill="#EEBB28"
            opacity="1.000000"
            stroke="none"
            d="
M267.250488,86.896896 
	C267.095123,86.675507 267.259796,86.395401 267.744385,86.056396 
	C267.905182,86.280907 267.740601,86.561134 267.250488,86.896896 
z"
          />
          <path
            fill="#EEBB28"
            opacity="1.000000"
            stroke="none"
            d="
M288.237701,63.875923 
	C288.079529,63.638870 288.248871,63.372940 288.746399,63.078590 
	C288.901062,63.313660 288.731262,63.579285 288.237701,63.875923 
z"
          />
          <path
            fill="#E8C152"
            opacity="1.000000"
            stroke="none"
            d="
M449.335968,64.010513 
	C449.566498,63.888634 449.772247,64.044258 450.027893,64.419296 
	C449.763336,64.682465 449.507782,64.565567 449.335968,64.010513 
z"
          />
          <path
            fill="#F7B706"
            opacity="1.000000"
            stroke="none"
            d="
M281.794189,85.126770 
	C281.911957,85.341965 281.722534,85.605057 281.230835,85.922821 
	C281.107697,85.707069 281.293823,85.439453 281.794189,85.126770 
z"
          />
          <path
            fill="#FBC531"
            opacity="1.000000"
            stroke="none"
            d="
M312.785767,65.098946 
	C312.910431,65.320831 312.720276,65.588524 312.215088,65.901016 
	C312.180389,65.742737 312.370667,65.475723 312.785767,65.098946 
z"
          />
          <path
            fill="#EEBB28"
            opacity="1.000000"
            stroke="none"
            d="
M457.256653,71.935570 
	C457.489014,71.858948 457.785065,72.087212 458.228088,72.557648 
	C458.023499,72.613373 457.671906,72.426910 457.256653,71.935570 
z"
          />
          <path
            fill="#F7B706"
            opacity="1.000000"
            stroke="none"
            d="
M296.773132,73.112114 
	C296.886749,73.319313 296.704956,73.573898 296.223206,73.869469 
	C296.115631,73.663666 296.300446,73.413345 296.773132,73.112114 
z"
          />
          <path
            fill="#EEBB28"
            opacity="1.000000"
            stroke="none"
            d="
M320.090210,44.167858 
	C319.992798,44.015198 320.436371,43.942326 320.658295,43.913292 
	C320.649353,44.029678 320.418518,44.175098 320.090210,44.167858 
z"
          />
          <path
            fill="#EEBB28"
            opacity="1.000000"
            stroke="none"
            d="
M420.178833,46.110649 
	C420.128967,45.940868 420.559448,45.943550 420.773193,45.967598 
	C420.734192,46.087906 420.481476,46.184166 420.178833,46.110649 
z"
          />
          <path
            fill="#EEBB28"
            opacity="1.000000"
            stroke="none"
            d="
M423.183899,46.985001 
	C423.294861,46.730392 423.470337,46.740768 423.878571,47.044720 
	C424.111359,47.338291 424.136780,47.816498 424.136780,47.816502 
	C423.840668,47.627663 423.544525,47.438824 423.183899,46.985001 
z"
          />
          <path
            fill="#E8C152"
            opacity="1.000000"
            stroke="none"
            d="
M424.409790,47.796333 
	C424.136780,47.816498 424.111359,47.338291 424.136597,47.098854 
	C424.445496,46.919785 424.693542,47.048676 425.045410,47.402000 
	C425.017517,47.630657 424.850159,47.703411 424.409790,47.796333 
z"
          />
          <path
            fill="#F6B808"
            opacity="1.000000"
            stroke="none"
            d="
M275.543579,218.881134 
	C272.563782,221.521255 269.583954,224.161377 266.302063,226.954758 
	C261.312775,218.711487 261.749573,219.019058 267.727142,210.529709 
	C278.232635,195.609787 291.642975,183.975433 307.385193,175.270966 
	C319.920715,168.339584 332.909210,161.925247 347.700775,160.927872 
	C349.314270,160.819092 350.883392,160.051208 353.211578,159.323944 
	C364.297943,159.082870 374.645813,159.106903 384.993683,159.130936 
	C387.643829,159.898880 390.337189,160.543793 392.936676,161.456085 
	C404.155396,165.393448 415.346588,169.409119 426.811890,173.970047 
	C428.072998,174.991348 429.070099,175.438873 430.067200,175.886414 
	C430.983826,176.375336 431.900452,176.864273 432.694885,177.768188 
	C432.048065,178.789246 431.523407,179.395355 430.597351,179.991821 
	C427.742096,179.614990 425.288269,179.247803 422.187073,178.783737 
	C423.041870,180.768356 423.521545,181.882034 423.775146,183.192230 
	C424.367126,184.590042 425.185150,185.791351 425.627930,186.994598 
	C424.502197,186.997665 423.751709,186.998825 422.888916,186.784210 
	C422.576691,186.299255 422.318237,186.110001 421.868774,185.701477 
	C420.823792,184.600296 419.911285,183.798309 418.994019,182.633392 
	C417.993652,181.180115 416.998047,180.089783 416.001953,178.599030 
	C416.000580,177.130768 415.999664,176.062943 415.992493,174.620422 
	C415.160675,173.283569 414.209991,171.430176 413.533020,171.525284 
	C411.449524,171.817947 409.470673,172.855820 407.451172,173.604095 
	C407.967468,174.070007 408.483734,174.535904 408.997925,175.390381 
	C407.660919,177.518585 406.325958,179.258240 404.692505,180.867249 
	C403.265869,180.812836 402.137787,180.889053 400.683228,180.969284 
	C399.548157,180.733871 398.739563,180.494431 397.767822,179.938141 
	C395.722900,179.437851 393.841095,179.254440 391.542175,179.068237 
	C389.733948,178.970551 388.342896,178.875671 386.951813,178.780792 
	C385.095459,178.372070 383.239105,177.963348 380.842896,177.793182 
	C379.868652,177.978638 379.434204,177.925537 378.942261,177.559326 
	C377.902893,177.201218 376.921021,177.156235 375.477112,177.108963 
	C370.363586,177.107544 365.712128,177.108429 360.821045,176.822052 
	C358.063385,177.066757 355.545349,177.598724 352.703949,178.365631 
	C341.290344,181.177917 329.946838,182.998932 319.198730,186.597977 
	C312.006378,189.006409 305.381042,193.528778 299.002655,197.845032 
	C290.270081,203.754440 281.193237,209.459167 275.543579,218.881134 
z"
          />
          <path
            fill="#F9D365"
            opacity="1.000000"
            stroke="none"
            d="
M361.060699,177.109314 
	C365.712128,177.108429 370.363586,177.107544 375.717560,177.331741 
	C377.279999,177.662033 378.139893,177.767242 378.999786,177.872437 
	C379.434204,177.925537 379.868652,177.978638 380.629395,178.063629 
	C382.410461,178.765091 383.865265,179.434692 385.320038,180.104279 
	C385.222260,180.505997 385.124481,180.907730 385.026703,181.309464 
	C380.814575,180.874786 376.604462,180.089752 372.389954,180.065308 
	C352.792572,179.951675 333.977051,182.981689 316.086334,191.903580 
	C305.313354,197.275970 295.339630,203.544113 286.045624,211.026840 
	C283.095001,213.402435 280.534271,216.262299 277.490997,219.012085 
	C276.914337,219.081146 276.641754,219.038666 275.956390,218.938660 
	C281.193237,209.459167 290.270081,203.754440 299.002655,197.845032 
	C305.381042,193.528778 312.006378,189.006409 319.198730,186.597977 
	C329.946838,182.998932 341.290344,181.177917 353.129517,178.349152 
	C356.272522,177.768295 358.666626,177.438812 361.060699,177.109314 
z"
          />
          <path
            fill="#F9D365"
            opacity="1.000000"
            stroke="none"
            d="
M387.073151,179.143250 
	C388.342896,178.875671 389.733948,178.970551 391.827332,179.322144 
	C394.330078,179.804230 396.130524,180.029602 397.930969,180.254990 
	C398.739563,180.494431 399.548157,180.733871 400.906738,181.242615 
	C402.602600,181.742630 403.748474,181.973343 405.178070,182.415619 
	C406.635437,182.758865 407.809113,182.890533 409.148132,183.268539 
	C409.861542,183.703033 410.409607,183.891190 411.300476,184.162109 
	C414.083374,185.181839 416.523468,186.118790 419.176880,187.319641 
	C421.931152,188.702713 424.472137,189.821884 427.220886,190.993439 
	C427.428711,191.045822 427.856842,191.066666 427.993256,191.282379 
	C428.421448,191.660553 428.713226,191.823013 429.208679,192.029633 
	C429.412323,192.073807 429.829102,192.078568 429.917603,192.385620 
	C430.679260,193.072937 431.352478,193.453171 432.050568,193.882141 
	C432.075470,193.930862 432.179108,193.966049 432.179108,193.966049 
	C442.302704,200.682861 452.312958,207.543091 460.833679,216.788330 
	C460.985779,217.832092 460.984558,218.570343 460.971252,219.680084 
	C457.652740,217.228836 454.335388,214.418854 451.041809,211.581253 
	C433.952972,196.858185 414.421173,187.001541 392.425659,181.965775 
	C390.590393,181.545593 388.933990,180.344162 387.073151,179.143250 
z"
          />
          <path
            fill="#F9D365"
            opacity="1.000000"
            stroke="none"
            d="
M321.243469,209.930832 
	C320.625580,209.719147 320.368347,209.471634 320.005310,208.969727 
	C322.021362,207.496796 324.143188,206.278275 326.632141,205.078796 
	C326.643799,205.746475 326.288330,206.395111 326.137390,207.213623 
	C324.762665,208.220688 323.183411,209.057846 321.243469,209.930832 
z"
          />
          <path
            fill="#FACD57"
            opacity="1.000000"
            stroke="none"
            d="
M276.710236,237.069794 
	C276.546417,237.370972 276.098755,237.683975 275.365479,237.989014 
	C275.528687,237.681229 275.977539,237.381424 276.710236,237.069794 
z"
          />
          <path
            fill="#FACD57"
            opacity="1.000000"
            stroke="none"
            d="
M479.996582,201.146088 
	C479.684448,201.673660 479.372284,202.201218 478.924072,203.011017 
	C475.931305,199.281891 472.669495,195.495224 470.296112,191.215836 
	C463.923401,179.725540 454.246277,171.480820 443.891327,164.022369 
	C436.589478,158.763000 429.046112,153.546082 420.928589,149.780304 
	C408.651154,144.084717 395.846802,139.977448 382.027802,138.685059 
	C372.539124,137.797668 363.357971,137.669769 353.942139,138.852737 
	C338.144012,140.837524 323.130737,144.979996 309.373596,153.119873 
	C303.583649,156.545700 297.799805,160.026962 292.320862,163.916412 
	C289.002411,166.272217 286.273376,169.458298 282.781036,172.636551 
	C281.149994,173.979706 280.017426,174.957672 278.596283,176.110886 
	C272.870178,183.190842 267.432678,190.095551 261.735291,196.888245 
	C260.974976,196.512177 260.474548,196.248108 260.119629,195.670761 
	C267.172180,181.308365 279.970093,172.359802 290.320984,160.999634 
	C291.444153,160.359940 292.246613,159.735458 293.406677,159.055222 
	C300.913666,154.927795 308.072418,150.872360 315.191986,146.749207 
	C315.686066,146.463058 315.888580,145.673477 316.422668,145.123077 
	C316.618622,145.128143 317.004303,145.057617 317.338348,145.049438 
	C318.113220,144.979874 318.554016,144.918503 319.284668,144.862579 
	C320.056335,144.600983 320.538177,144.333908 321.366028,143.919983 
	C334.389496,138.933197 347.560333,136.213638 361.040466,135.183334 
	C366.283661,134.782578 371.622009,135.141190 376.882996,135.570694 
	C384.921753,136.226944 392.930969,137.244751 401.195435,138.365570 
	C420.478363,145.603958 438.580109,154.039612 453.962006,167.850937 
	C464.706787,177.498611 472.906219,188.808258 479.996582,201.146088 
z"
          />
          <path
            fill="#F6B80B"
            opacity="1.000000"
            stroke="none"
            d="
M261.995178,197.000259 
	C267.432678,190.095551 272.870178,183.190842 278.940033,176.160339 
	C280.715790,175.690094 281.859253,175.345657 283.002686,175.001221 
	C288.582794,171.912552 294.413483,169.193726 299.672241,165.630768 
	C304.887299,162.097427 305.443726,161.812149 309.791138,166.611847 
	C310.196869,167.059753 310.952637,167.190598 311.780090,168.012329 
	C312.025391,168.739655 312.036438,168.923309 312.047516,169.106964 
	C311.906097,169.413834 311.684967,169.644211 310.792145,169.892029 
	C309.466339,169.986084 308.732574,169.986237 307.984802,169.594025 
	C306.674103,164.909393 303.894135,167.761017 301.686401,167.906357 
	C298.045959,168.146027 299.231110,170.950058 299.003235,173.000641 
	C299.000000,173.000092 299.003174,173.006927 298.638641,172.963593 
	C295.165222,173.665817 292.035736,174.335831 288.952942,175.177048 
	C285.510437,176.116409 284.123932,177.883270 286.646240,181.067688 
	C283.836700,183.998642 279.769012,185.995193 280.980743,190.872299 
	C280.215698,191.505417 279.450684,192.138550 278.146362,193.046814 
	C277.405182,193.876450 277.203308,194.430969 277.001465,194.985504 
	C277.001465,194.985504 277.003510,195.003174 276.779236,195.106842 
	C276.285522,195.402527 276.100739,195.656189 276.000580,195.971512 
	C276.000580,195.971512 276.004059,196.003998 275.774536,196.108719 
	C275.278931,196.409409 275.097748,196.665482 275.001465,196.981674 
	C275.001465,196.981674 275.003754,197.003159 274.777557,197.109085 
	C274.282318,197.412476 274.101379,197.671494 274.008545,197.992081 
	C274.008545,197.992081 274.003601,198.003632 273.780334,198.109451 
	C273.284973,198.410416 273.103394,198.669128 273.012360,198.991394 
	C272.176880,200.195480 271.341431,201.399567 269.878204,202.805664 
	C265.550964,203.409241 265.246277,206.196609 265.014404,209.035141 
	C263.366699,211.210144 261.718994,213.385162 260.067535,215.565094 
	C256.111389,210.811630 255.796158,206.898865 259.244446,201.342133 
	C259.783905,200.245209 260.025696,199.714798 260.549683,199.081421 
	C261.219666,198.319046 261.607422,197.659653 261.995178,197.000259 
z"
          />
          <path
            fill="#F8DD86"
            opacity="1.000000"
            stroke="none"
            d="
M384.791473,158.833450 
	C374.645813,159.106903 364.297943,159.082870 353.482910,159.060944 
	C353.010254,158.881241 353.004700,158.699417 353.002197,158.244629 
	C361.691162,157.641006 370.377075,157.310349 379.531219,157.009048 
	C381.529388,157.537613 383.059326,158.036789 384.791473,158.833450 
z"
          />
          <path
            fill="#F0B813"
            opacity="1.000000"
            stroke="none"
            d="
M282.997803,174.640427 
	C281.859253,175.345657 280.715790,175.690094 279.228607,175.985077 
	C280.017426,174.957672 281.149994,173.979706 282.597534,172.917725 
	C282.939331,173.315689 282.966125,173.797653 282.997803,174.640427 
z"
          />
          <path
            fill="#F0B813"
            opacity="1.000000"
            stroke="none"
            d="
M312.380554,169.078781 
	C312.036438,168.923309 312.025391,168.739655 312.009003,168.280151 
	C312.770874,168.007111 313.538116,168.009949 314.690247,168.028503 
	C314.287933,168.379684 313.500763,168.715149 312.380554,169.078781 
z"
          />
          <path
            fill="#F9D365"
            opacity="1.000000"
            stroke="none"
            d="
M329.308533,207.007507 
	C329.809235,206.661087 330.615570,206.326553 331.772583,205.998795 
	C331.286865,206.343506 330.450500,206.681442 329.308533,207.007507 
z"
          />
          <path
            fill="#F7E6A5"
            opacity="1.000000"
            stroke="none"
            d="
M333.910889,247.389893 
	C333.059204,246.944962 332.610535,246.464767 332.101807,245.662018 
	C332.213074,245.256500 332.384430,245.173553 333.103546,245.041504 
	C333.825928,244.963699 334.000488,244.935013 334.175049,244.906342 
	C334.971008,244.696121 335.766998,244.485916 337.083954,244.136658 
	C338.030365,243.798157 338.455780,243.598694 338.881226,243.399231 
	C358.334656,238.332001 377.790436,239.408615 397.610321,243.763336 
	C397.991394,244.424606 398.008484,244.699722 398.014221,244.986313 
	C398.003876,244.997726 397.985413,245.022476 397.555725,245.018738 
	C396.262939,245.078110 395.399841,245.141220 394.536713,245.204346 
	C392.914856,245.101624 391.292999,244.998917 389.007507,244.581482 
	C387.176392,244.201675 386.008881,244.136581 384.841400,244.071503 
	C371.868805,244.402725 358.896179,244.733963 345.350891,245.057800 
	C344.590729,245.088348 344.416016,245.158188 344.254089,245.259918 
	C343.414978,245.489471 342.575867,245.719040 341.074860,245.964142 
	C340.005219,246.132004 339.597473,246.284348 339.189728,246.436676 
	C337.564453,246.742645 335.939209,247.048630 333.910889,247.389893 
z"
          />
          <path
            fill="#F7E6A5"
            opacity="1.000000"
            stroke="none"
            d="
M405.625366,245.506165 
	C405.956970,246.419220 405.944366,247.114441 405.901489,248.166901 
	C404.483673,248.089035 403.096130,247.653915 401.171387,246.876526 
	C399.751251,246.030334 398.868347,245.526413 397.985413,245.022476 
	C397.985413,245.022476 398.003876,244.997726 398.422119,244.992767 
	C399.660309,244.943253 400.480225,244.898682 401.300171,244.854095 
	C402.627167,244.998840 403.954132,245.143585 405.625366,245.506165 
z"
          />
          <path
            fill="#F7E099"
            opacity="1.000000"
            stroke="none"
            d="
M401.065155,244.648529 
	C400.480225,244.898682 399.660309,244.943253 398.432465,244.981354 
	C398.008484,244.699722 397.991394,244.424606 397.954346,243.949951 
	C398.900269,243.981216 399.865204,244.212082 401.065155,244.648529 
z"
          />
          <path
            fill="#F7E099"
            opacity="1.000000"
            stroke="none"
            d="
M338.630798,243.323975 
	C338.455780,243.598694 338.030365,243.798157 337.361938,244.004028 
	C337.539429,243.756546 337.959900,243.502625 338.630798,243.323975 
z"
          />
          <path
            fill="#F7E099"
            opacity="1.000000"
            stroke="none"
            d="
M334.043823,244.792084 
	C334.000488,244.935013 333.825928,244.963699 333.387573,245.012024 
	C333.386749,244.913712 333.649658,244.795776 334.043823,244.792084 
z"
          />
          <path
            fill="#F8DD86"
            opacity="1.000000"
            stroke="none"
            d="
M343.265198,266.817078 
	C342.351379,266.111572 341.772675,265.475525 340.849854,264.713623 
	C341.767059,264.048248 343.028412,263.508667 344.655457,263.013763 
	C345.296967,263.711761 345.572784,264.365143 346.139404,265.256256 
	C345.486877,265.958191 344.543579,266.422363 343.265198,266.817078 
z"
          />
          <path
            fill="#F8DD86"
            opacity="1.000000"
            stroke="none"
            d="
M385.245911,262.295868 
	C382.873322,262.315887 380.260040,262.033081 377.350464,261.533386 
	C379.704529,261.542023 382.354858,261.767548 385.245911,262.295868 
z"
          />
          <path
            fill="#F7E099"
            opacity="1.000000"
            stroke="none"
            d="
M397.555725,245.018738 
	C398.868347,245.526413 399.751251,246.030334 400.821747,246.786224 
	C399.005127,246.632889 397.000946,246.227600 394.766724,245.513336 
	C395.399841,245.141220 396.262939,245.078110 397.555725,245.018738 
z"
          />
          <path
            fill="#F7E099"
            opacity="1.000000"
            stroke="none"
            d="
M385.144226,244.251633 
	C386.008881,244.136581 387.176392,244.201675 388.613525,244.474442 
	C387.737823,244.598663 386.592438,244.515213 385.144226,244.251633 
z"
          />
          <path
            fill="#F8DD86"
            opacity="1.000000"
            stroke="none"
            d="
M357.592865,261.275024 
	C356.828583,261.589996 355.725281,261.786621 354.270447,261.926697 
	C355.030548,261.632324 356.142181,261.394501 357.592865,261.275024 
z"
          />
          <path
            fill="#F7E099"
            opacity="1.000000"
            stroke="none"
            d="
M339.415985,246.538879 
	C339.597473,246.284348 340.005219,246.132004 340.637512,245.967957 
	C340.455444,246.184525 340.048859,246.412811 339.415985,246.538879 
z"
          />
          <path
            fill="#F8DD86"
            opacity="1.000000"
            stroke="none"
            d="
M339.130524,264.574554 
	C338.810150,264.805542 338.252441,264.878754 337.273438,264.925507 
	C337.532501,264.738312 338.212830,264.577545 339.130524,264.574554 
z"
          />
          <path
            fill="#F8DD86"
            opacity="1.000000"
            stroke="none"
            d="
M360.753601,261.268494 
	C361.005920,261.317230 360.671021,261.614716 360.671021,261.614716 
	C360.671021,261.614716 360.235687,261.598785 360.126282,261.495087 
	C360.178406,261.334167 360.339874,261.276978 360.753601,261.268494 
z"
          />
          <path
            fill="#F8DD86"
            opacity="1.000000"
            stroke="none"
            d="
M353.637207,261.902344 
	C353.643066,262.127411 353.381744,262.387085 352.829681,262.624939 
	C352.815948,262.381073 353.092987,262.159027 353.637207,261.902344 
z"
          />
          <path
            fill="#F7E099"
            opacity="1.000000"
            stroke="none"
            d="
M322.702881,269.384216 
	C322.765472,269.620728 322.570801,269.779388 322.135071,269.915070 
	C322.035553,269.671570 322.219452,269.476318 322.702881,269.384216 
z"
          />
          <path
            fill="#F7E099"
            opacity="1.000000"
            stroke="none"
            d="
M344.399719,245.334518 
	C344.416016,245.158188 344.590729,245.088348 344.923157,245.039642 
	C344.953979,245.238602 344.779694,245.365356 344.399719,245.334518 
z"
          />
          <path
            fill="#EDEEF1"
            opacity="1.000000"
            stroke="none"
            d="
M431.065125,368.397339 
	C430.583740,356.840515 435.481384,350.748138 444.727661,350.918671 
	C451.921722,351.051361 457.139435,355.900391 457.909668,363.169312 
	C458.683044,370.468048 454.711029,377.037048 448.452637,378.809509 
	C441.435272,380.796906 434.717285,377.527222 431.787445,370.686676 
	C431.526917,370.078461 431.340179,369.438629 431.065125,368.397339 
z"
          />
          <path
            fill="#F8DD86"
            opacity="1.000000"
            stroke="none"
            d="
M356.424988,264.830994 
	C357.783966,264.491730 359.532318,264.236389 361.683014,264.028870 
	C360.328339,264.356110 358.571350,264.635498 356.424988,264.830994 
z"
          />
          <path
            fill="#F8DD86"
            opacity="1.000000"
            stroke="none"
            d="
M374.224976,264.286194 
	C374.894379,264.139709 375.805481,264.206177 376.861359,264.518372 
	C376.159668,264.675751 375.313171,264.587463 374.224976,264.286194 
z"
          />
          <path
            fill="#F8DD86"
            opacity="1.000000"
            stroke="none"
            d="
M348.908203,265.313232 
	C348.977997,265.166260 349.219147,265.130554 349.825745,265.119995 
	C349.820648,265.238251 349.450073,265.331360 348.908203,265.313232 
z"
          />
          <path
            fill="#EDEEF1"
            opacity="1.000000"
            stroke="none"
            d="
M260.519714,350.914429 
	C269.896423,350.471558 274.105560,353.462311 275.783508,361.755432 
	C267.390228,361.755432 259.071381,361.755432 250.443314,361.755432 
	C251.378006,355.935364 254.426910,352.310516 260.519714,350.914429 
z"
          />
          <path
            fill="#E8E9EF"
            opacity="1.000000"
            stroke="none"
            d="
M491.687927,367.341492 
	C495.907928,367.288757 499.692657,367.288757 503.528748,367.288757 
	C505.239777,372.426514 503.393341,375.887177 499.273071,378.403870 
	C495.319580,380.818604 488.561310,380.557922 485.627319,377.962799 
	C482.552002,375.242676 483.194092,370.111725 486.925934,368.445404 
	C488.258881,367.850250 489.804230,367.730835 491.687927,367.341492 
z"
          />
          <path
            fill="#E8E9EF"
            opacity="1.000000"
            stroke="none"
            d="
M358.026062,367.167175 
	C360.447906,367.166718 362.405975,367.166718 364.460541,367.166718 
	C366.041229,372.321533 364.170807,375.834412 360.149078,378.333496 
	C356.159760,380.812408 349.375916,380.594452 346.450684,378.134644 
	C342.879578,375.131683 343.722870,369.584503 348.252014,368.239044 
	C351.201233,367.362915 354.448517,367.489990 358.026062,367.167175 
z"
          />
          <path
            fill="#E8E9EF"
            opacity="1.000000"
            stroke="none"
            d="
M166.694336,379.058472 
	C163.081223,379.232971 159.592422,379.971771 156.668915,379.003571 
	C154.769318,378.374512 152.477524,375.184723 152.592239,373.276306 
	C152.705902,371.385345 155.379410,368.498138 157.348984,368.095184 
	C162.280182,367.086395 167.448547,367.236877 172.471695,366.925385 
	C173.991821,373.000488 171.789978,376.567749 166.694336,379.058472 
z"
          />
          <path
            fill="#E8E9EF"
            opacity="1.000000"
            stroke="none"
            d="
M567.561401,379.879791 
	C563.628601,378.879822 560.981445,376.963226 561.365295,372.965546 
	C561.762085,368.832916 565.025024,367.671570 568.540222,367.507019 
	C572.801025,367.307617 577.078308,367.459717 581.327393,367.459717 
	C583.132019,375.846558 578.418701,380.210938 567.561401,379.879791 
z"
          />
          <path
            fill="#F8B701"
            opacity="1.000000"
            stroke="none"
            d="
M429.829102,192.078568 
	C429.829102,192.078568 429.412323,192.073807 429.081329,191.806458 
	C428.452515,191.381638 428.154663,191.224152 427.856842,191.066666 
	C427.856842,191.066666 427.428711,191.045822 427.162903,190.668915 
	C425.598480,189.194672 424.299866,188.097336 423.001221,187.000000 
	C423.751709,186.998825 424.502197,186.997665 426.003296,187.002686 
	C429.355408,186.958313 432.946808,191.831802 434.539734,185.361664 
	C430.886963,184.541611 427.444092,183.768677 424.001221,182.995728 
	C423.521545,181.882034 423.041870,180.768356 422.187073,178.783737 
	C425.288269,179.247803 427.742096,179.614990 430.806519,180.266937 
	C434.705597,181.652206 437.994080,182.752716 441.282562,183.853226 
	C442.403717,184.385391 443.524841,184.917542 444.833801,185.694733 
	C444.656372,187.232941 444.291138,188.526093 444.113495,189.155121 
	C441.418030,188.734589 439.503357,188.435867 437.588684,188.137146 
	C438.097260,189.575409 438.605804,191.013672 439.363342,193.156143 
	C437.345062,193.416748 435.160400,193.698837 432.577423,193.973495 
	C432.179108,193.966049 432.075470,193.930862 431.985138,193.573181 
	C431.206238,192.836533 430.517670,192.457550 429.829102,192.078568 
z"
          />
          <path
            fill="#F8B701"
            opacity="1.000000"
            stroke="none"
            d="
M459.813751,200.878525 
	C460.392609,201.437683 460.654510,201.863724 461.035706,202.508057 
	C457.552216,208.316208 455.936890,202.046326 453.031616,201.814224 
	C454.905334,196.582260 457.199615,198.650742 459.813751,200.878525 
z"
          />
          <path
            fill="#F6B80B"
            opacity="1.000000"
            stroke="none"
            d="
M272.051331,140.301422 
	C272.408447,139.869492 272.623352,139.719391 273.172974,139.134216 
	C279.578094,133.435974 285.539124,128.032272 291.640381,122.791809 
	C296.339691,118.755455 301.215179,114.924248 306.012360,111.001831 
	C306.012360,111.001831 305.998779,110.998306 306.331177,111.025558 
	C307.106689,111.028000 307.549805,111.003204 307.992950,110.978409 
	C309.437897,110.339310 310.882874,109.700211 312.667999,109.438133 
	C313.955261,112.889053 320.228760,115.804504 324.414032,115.856934 
	C326.627563,115.884659 328.823730,117.298752 330.976440,118.451996 
	C328.997650,119.573021 327.070129,120.328987 324.891876,121.159683 
	C324.423584,121.498955 324.206055,121.763504 323.771912,122.054077 
	C323.555328,122.080109 323.119080,122.084816 322.807495,122.076088 
	C322.009003,122.389778 321.522034,122.712204 321.019562,123.020058 
	C321.004028,123.005493 321.018127,123.045547 320.602356,123.030334 
	C318.275818,123.628609 316.343903,124.183853 314.458832,124.868141 
	C310.931274,126.148651 307.430847,127.503975 303.919159,128.828186 
	C303.749115,128.447525 303.579071,128.066879 303.409058,127.686218 
	C302.293274,129.826736 301.177460,131.967270 299.867371,134.326416 
	C299.120209,134.705475 298.567322,134.865906 297.917847,134.859039 
	C297.567017,134.836670 297.312836,134.981613 297.037903,135.343063 
	C297.017212,135.559570 296.944153,135.988388 296.739655,136.097183 
	C296.280396,136.396667 296.097412,136.641098 295.982910,136.959244 
	C295.979614,136.979233 295.940674,136.967911 295.552368,136.977142 
	C292.536346,137.164368 289.871796,137.295486 290.019440,141.011017 
	C290.008820,141.002258 290.011536,141.028458 289.654053,141.022461 
	C288.545258,141.704636 287.793976,142.392792 286.879517,143.374268 
	C276.670197,151.147797 268.859650,160.656418 262.327850,171.204010 
	C258.254181,177.782211 254.727249,184.699020 250.954956,191.463882 
	C250.412735,191.149933 249.870483,190.835983 249.148163,190.260376 
	C248.961258,187.965118 248.954437,185.931534 248.935791,183.463470 
	C249.679932,181.575790 250.676697,180.197144 251.153656,178.657471 
	C254.819199,166.824326 260.493927,156.124435 268.538361,146.659012 
	C270.004272,144.934189 270.834503,142.669174 272.051331,140.301422 
z"
          />
          <path
            fill="#F9CD4E"
            opacity="1.000000"
            stroke="none"
            d="
M298.014435,135.026337 
	C298.567322,134.865906 299.120209,134.705475 300.211945,134.250671 
	C303.443268,132.482300 306.126434,130.991058 308.830322,129.538406 
	C312.885284,127.359909 316.954803,125.208496 321.018127,123.045547 
	C321.018127,123.045547 321.004028,123.005493 321.318298,122.997162 
	C322.128082,122.687492 322.623566,122.386154 323.119080,122.084816 
	C323.119080,122.084816 323.555328,122.080109 324.009338,121.958481 
	C324.689789,121.586220 324.916199,121.335587 325.142639,121.084953 
	C327.070129,120.328987 328.997650,119.573021 331.249207,118.547256 
	C332.102448,118.831871 332.631622,119.386284 333.115112,120.282692 
	C333.041138,121.082306 333.012817,121.539940 332.859070,122.191620 
	C332.534180,122.630508 332.286469,122.807907 331.606995,122.963379 
	C327.929016,124.037018 324.369843,124.576607 321.392334,126.189812 
	C314.059753,130.162521 306.994446,134.628555 299.613220,138.891968 
	C299.404755,138.887711 298.992371,138.949997 298.640625,138.991852 
	C291.241699,144.826401 284.037018,150.442093 277.228333,156.502655 
	C274.661041,158.787872 273.010254,162.102753 270.959961,164.955353 
	C270.978271,164.962479 270.962799,164.946823 270.612885,164.976257 
	C268.477539,167.639954 266.692108,170.274231 264.614136,173.082916 
	C262.551331,176.502289 260.781067,179.747238 258.747192,183.114609 
	C258.012543,183.827118 257.541534,184.417221 256.982361,185.367188 
	C255.321045,188.558136 253.747925,191.389252 252.174805,194.220352 
	C251.808517,193.490707 251.442215,192.761047 251.015442,191.747650 
	C254.727249,184.699020 258.254181,177.782211 262.327850,171.204010 
	C268.859650,160.656418 276.670197,151.147797 287.194489,143.327194 
	C288.452240,142.334030 289.231903,141.681244 290.011536,141.028458 
	C290.011536,141.028458 290.008820,141.002258 290.356750,140.976715 
	C292.450012,139.623428 294.195343,138.295670 295.940674,136.967911 
	C295.940674,136.967911 295.979614,136.979233 296.200378,136.867065 
	C296.694183,136.566772 296.868530,136.311279 296.944153,135.988388 
	C296.944153,135.988388 297.017212,135.559570 297.276733,135.318604 
	C297.536285,135.077637 298.014435,135.026352 298.014435,135.026337 
z"
          />
          <path
            fill="#F5B809"
            opacity="1.000000"
            stroke="none"
            d="
M252.151993,194.630402 
	C253.747925,191.389252 255.321045,188.558136 257.318726,185.430786 
	C257.771210,186.339645 257.813110,187.544617 257.823547,188.749863 
	C257.837494,190.360275 257.826996,191.970901 257.826996,194.814117 
	C262.480011,189.052490 262.535065,184.939331 259.010803,182.992203 
	C260.781067,179.747238 262.551331,176.502289 264.641418,173.109650 
	C264.961243,172.961990 265.013763,173.013168 265.009094,173.424042 
	C264.879242,176.052750 264.754028,178.270584 264.576355,181.417770 
	C268.892761,177.198685 272.658905,173.561523 276.330719,169.831497 
	C276.581787,169.576462 276.125916,168.625488 276.039856,167.643723 
	C279.980988,163.785965 283.880981,160.282639 288.613861,156.031189 
	C289.284576,158.442108 289.642426,159.728470 290.000305,161.014832 
	C279.970093,172.359802 267.172180,181.308365 259.871704,195.883392 
	C259.290771,197.245071 259.103302,198.080841 258.898407,198.906631 
	C258.880981,198.896667 258.847626,198.871323 258.847656,198.871353 
	C257.250854,200.085464 255.654037,201.299561 253.455276,202.971313 
	C251.301025,201.390656 252.027100,198.113556 252.151993,194.630402 
z"
          />
          <path
            fill="#F5B809"
            opacity="1.000000"
            stroke="none"
            d="
M286.993896,181.009460 
	C284.123932,177.883270 285.510437,176.116409 288.952942,175.177048 
	C292.035736,174.335831 295.165222,173.665817 298.602173,173.310791 
	C298.638641,174.819458 298.347076,175.937622 298.055481,177.055771 
	C298.055481,177.055771 298.027649,177.005081 297.677277,177.016846 
	C296.570190,177.697784 295.813507,178.366959 295.056793,179.036148 
	C295.056793,179.036148 295.031708,179.014191 294.802002,179.124847 
	C294.292389,179.428116 294.105621,179.687744 294.011993,180.014359 
	C292.307831,181.051941 290.603699,182.089508 288.279053,182.954254 
	C287.436981,182.190750 287.215454,181.600113 286.993896,181.009460 
z"
          />
          <path
            fill="#F0B813"
            opacity="1.000000"
            stroke="none"
            d="
M286.646240,181.067688 
	C287.215454,181.600113 287.436981,182.190750 287.931335,182.967346 
	C286.004242,185.653107 283.804382,188.152924 281.292603,190.762512 
	C279.769012,185.995193 283.836700,183.998642 286.646240,181.067688 
z"
          />
          <path
            fill="#F0B813"
            opacity="1.000000"
            stroke="none"
            d="
M298.403320,177.007355 
	C298.347076,175.937622 298.638641,174.819458 298.966675,173.354111 
	C299.003174,173.006927 299.000000,173.000092 299.340271,172.852859 
	C302.453278,171.799210 305.226013,170.892807 307.998779,169.986389 
	C308.732574,169.986237 309.466339,169.986084 310.565521,169.980255 
	C306.871002,172.302704 302.811066,174.630829 298.403320,177.007355 
z"
          />
          <path
            fill="#EEBA1A"
            opacity="1.000000"
            stroke="none"
            d="
M265.303314,208.875229 
	C265.246277,206.196609 265.550964,203.409241 269.626099,203.002930 
	C268.531891,204.903885 267.062042,206.809601 265.303314,208.875229 
z"
          />
          <path
            fill="#F0B813"
            opacity="1.000000"
            stroke="none"
            d="
M295.371979,179.005402 
	C295.813507,178.366959 296.570190,177.697784 297.684265,177.041779 
	C297.256836,177.694870 296.472015,178.334747 295.371979,179.005402 
z"
          />
          <path
            fill="#EEBA1A"
            opacity="1.000000"
            stroke="none"
            d="
M277.254272,194.833618 
	C277.203308,194.430969 277.405182,193.876450 277.885498,193.192307 
	C277.945007,193.602386 277.726044,194.142075 277.254272,194.833618 
z"
          />
          <path
            fill="#FACD57"
            opacity="1.000000"
            stroke="none"
            d="
M260.267456,199.184387 
	C260.025696,199.714798 259.783905,200.245209 259.327087,200.977280 
	C259.062744,200.665298 259.013458,200.151627 258.905884,199.254639 
	C258.847626,198.871323 258.880981,198.896667 259.237671,198.968292 
	C259.818756,199.088074 260.043121,199.136230 260.267456,199.184387 
z"
          />
          <path
            fill="#F0B813"
            opacity="1.000000"
            stroke="none"
            d="
M294.235718,179.917084 
	C294.105621,179.687744 294.292389,179.428116 294.796570,179.140533 
	C294.935913,179.377792 294.748779,179.635864 294.235718,179.917084 
z"
          />
          <path
            fill="#EEBA1A"
            opacity="1.000000"
            stroke="none"
            d="
M275.221741,196.883636 
	C275.097748,196.665482 275.278931,196.409409 275.758301,196.107178 
	C275.894897,196.329895 275.718384,196.591446 275.221741,196.883636 
z"
          />
          <path
            fill="#EEBA1A"
            opacity="1.000000"
            stroke="none"
            d="
M274.232056,197.895447 
	C274.101379,197.671494 274.282318,197.412476 274.766724,197.108459 
	C274.911469,197.336838 274.735931,197.602478 274.232056,197.895447 
z"
          />
          <path
            fill="#EEBA1A"
            opacity="1.000000"
            stroke="none"
            d="
M273.236450,198.897095 
	C273.103394,198.669128 273.284973,198.410416 273.774506,198.112015 
	C273.915009,198.340515 273.737885,198.605194 273.236450,198.897095 
z"
          />
          <path
            fill="#EEBA1A"
            opacity="1.000000"
            stroke="none"
            d="
M276.222229,195.876892 
	C276.100739,195.656189 276.285522,195.402527 276.770325,195.107681 
	C276.901062,195.330917 276.720490,195.590057 276.222229,195.876892 
z"
          />
          <path
            fill="#F5B80C"
            opacity="1.000000"
            stroke="none"
            d="
M441.173523,183.521484 
	C437.994080,182.752716 434.705597,181.652206 431.207947,180.276581 
	C431.523407,179.395355 432.048065,178.789246 432.854065,178.050262 
	C435.778412,179.674820 438.421417,181.432281 441.173523,183.521484 
z"
          />
          <path
            fill="#F5B80C"
            opacity="1.000000"
            stroke="none"
            d="
M429.904907,175.616577 
	C429.070099,175.438873 428.072998,174.991348 427.066895,174.203247 
	C427.952789,174.357361 428.847687,174.852051 429.904907,175.616577 
z"
          />
          <path
            fill="#F8EBC6"
            opacity="1.000000"
            stroke="none"
            d="
M574.561157,447.370239 
	C573.541138,449.310669 572.504761,451.009460 571.158813,452.411102 
	C570.937195,452.641968 568.636292,451.675262 568.548401,451.077850 
	C568.330933,449.600098 568.499329,446.882660 569.122681,446.695526 
	C570.718384,446.216461 572.646301,446.844177 574.561157,447.370239 
z"
          />
          <path
            fill="#F8EBC6"
            opacity="1.000000"
            stroke="none"
            d="
M159.072296,449.993835 
	C160.222244,448.107941 161.326614,446.517365 162.725174,445.251923 
	C162.917877,445.077545 165.336472,446.469086 165.381607,447.216736 
	C165.468475,448.655792 164.871765,451.180481 164.057861,451.400177 
	C162.608200,451.791473 160.786118,450.803314 159.072296,449.993835 
z"
          />
          <path
            fill="#F8EBC6"
            opacity="1.000000"
            stroke="none"
            d="
M246.897736,452.149231 
	C245.699448,450.879242 244.154068,449.457855 244.334625,448.307251 
	C244.521561,447.115906 246.268005,445.970856 247.581192,445.275543 
	C247.975342,445.066833 250.022141,446.726776 249.913864,447.027649 
	C249.273911,448.806183 248.222046,450.436554 246.897736,452.149231 
z"
          />
          <path
            fill="#EDBD31"
            opacity="1.000000"
            stroke="none"
            d="
M313.983429,449.954956 
	C315.660187,442.070404 319.884979,439.039032 326.642853,441.109497 
	C328.477631,441.671600 330.254883,443.702881 331.226471,445.507782 
	C332.777924,448.389862 331.619598,450.219147 328.102631,450.242096 
	C325.774261,450.257263 323.447327,450.488068 321.119751,450.622345 
	C321.134796,451.172150 321.149811,451.721954 321.164825,452.271759 
	C322.703094,452.271759 324.297089,452.024109 325.761200,452.353607 
	C326.846832,452.597931 327.763733,453.592102 328.755768,454.252258 
	C328.219147,454.969818 327.836914,455.975647 327.121277,456.358978 
	C321.751404,459.235382 315.706116,456.434174 313.983429,449.954956 
z"
          />
          <path
            fill="#F5B70F"
            opacity="1.000000"
            stroke="none"
            d="
M249.731232,139.254456 
	C250.970139,140.775253 251.929337,142.545502 252.950562,144.657852 
	C252.335953,147.422867 251.659302,149.845749 250.965164,152.664398 
	C250.947678,153.060165 250.931320,153.083817 250.724609,153.179993 
	C250.277390,153.453842 250.109436,153.684387 250.014008,153.967804 
	C248.601654,156.301239 247.189301,158.634659 245.406555,160.966751 
	C245.057297,160.532410 245.078445,160.099380 245.357727,159.080902 
	C245.755768,157.335617 245.895676,156.175812 246.243683,154.863174 
	C246.334534,154.142685 246.217239,153.575012 246.099960,153.007355 
	C246.097061,151.957474 246.094162,150.907608 246.110748,149.466705 
	C246.955002,148.547897 248.364166,148.139893 248.501282,147.471115 
	C249.036545,144.860168 249.169174,142.166687 249.731232,139.254456 
z"
          />
          <path
            fill="#F9E7AB"
            opacity="1.000000"
            stroke="none"
            d="
M414.255829,450.941467 
	C412.323059,451.275421 410.606293,451.302460 408.889496,451.329468 
	C408.956299,449.779358 408.483093,447.832611 409.246063,446.793915 
	C409.967804,445.811401 411.987549,445.782410 413.436401,445.334045 
	C413.781555,447.100861 414.126678,448.867706 414.255829,450.941467 
z"
          />
          <path
            fill="#F9E7AB"
            opacity="1.000000"
            stroke="none"
            d="
M482.941223,445.773438 
	C484.826050,446.132538 486.396912,446.717651 487.967743,447.302734 
	C487.352325,448.860626 487.068451,450.741669 485.989929,451.848206 
	C485.396790,452.456757 483.466217,451.761749 482.138611,451.654449 
	C482.301483,449.769440 482.464355,447.884430 482.941223,445.773438 
z"
          />
          <path
            fill="#F5B80C"
            opacity="1.000000"
            stroke="none"
            d="
M590.583862,455.297699 
	C589.596008,455.238342 588.187195,454.758759 588.226807,454.527405 
	C588.916382,450.502991 589.765015,446.504883 590.664307,442.520264 
	C590.711914,442.309235 591.515076,442.268707 592.908203,441.898743 
	C592.168884,446.572632 591.502991,450.782806 590.583862,455.297699 
z"
          />
          <path
            fill="#EFBD2D"
            opacity="1.000000"
            stroke="none"
            d="
M455.203308,443.222229 
	C461.561829,440.180206 463.859985,439.929321 469.552063,442.042999 
	C468.126923,443.730774 466.860138,445.231049 465.500854,446.840790 
	C466.969757,448.966461 470.957062,450.672180 467.362244,454.398804 
	C464.981323,456.867035 457.858765,458.262604 455.381195,456.397522 
	C454.746185,455.919495 454.153076,454.632538 454.366089,453.991516 
	C454.582306,453.340912 455.795349,452.694733 456.613434,452.637146 
	C458.478058,452.505829 460.368164,452.735809 462.438446,452.356018 
	C459.227539,450.114105 453.860870,450.013031 455.258179,443.717590 
	C455.267792,443.345978 455.088806,443.181213 455.203308,443.222229 
z"
          />
          <path
            fill="#F9CD4E"
            opacity="1.000000"
            stroke="none"
            d="
M491.977417,146.154785 
	C492.066864,148.081177 492.004059,150.217560 491.853088,152.743210 
	C491.183075,152.323090 490.601288,151.513702 490.276459,150.136627 
	C490.659271,148.696533 490.785095,147.824112 490.910950,146.951691 
	C490.910950,146.951691 490.940186,146.917511 490.945740,146.893921 
	C491.242615,146.701813 491.533875,146.533295 491.977417,146.154785 
z"
          />
          <path
            fill="#FBC531"
            opacity="1.000000"
            stroke="none"
            d="
M490.615021,146.996506 
	C490.785095,147.824112 490.659271,148.696533 490.283203,149.791412 
	C490.128357,149.023026 490.223724,148.032181 490.615021,146.996506 
z"
          />
          <path
            fill="#FBC531"
            opacity="1.000000"
            stroke="none"
            d="
M490.090271,145.246246 
	C490.254944,145.222748 490.520264,145.428345 490.864563,145.879807 
	C490.692657,145.908890 490.441833,145.692108 490.090271,145.246246 
z"
          />
          <path
            fill="#E8C152"
            opacity="1.000000"
            stroke="none"
            d="
M524.927246,451.770630 
	C524.808838,451.761810 524.688110,451.483002 524.697266,451.086884 
	C524.859802,451.146606 524.892395,451.323608 524.927246,451.770630 
z"
          />
          <path
            fill="#F6C232"
            opacity="1.000000"
            stroke="none"
            d="
M245.847290,153.167038 
	C246.217239,153.575012 246.334534,154.142685 246.023880,154.858063 
	C245.595947,155.005783 245.156998,155.005096 245.156998,155.005096 
	C245.302872,154.445633 245.448730,153.886185 245.847290,153.167038 
z"
          />
          <path
            fill="#EFBD2D"
            opacity="1.000000"
            stroke="none"
            d="
M245.113449,155.405319 
	C245.156998,155.005096 245.595947,155.005783 245.815765,155.010895 
	C245.895676,156.175812 245.755768,157.335617 245.386917,158.756622 
	C245.128601,157.947052 245.099243,156.876297 245.113449,155.405319 
z"
          />
          <path
            fill="#F9CA48"
            opacity="1.000000"
            stroke="none"
            d="
M246.005676,167.372955 
	C246.318130,167.249954 246.654953,167.406494 247.014771,167.797607 
	C247.037766,168.032150 246.995270,168.524109 246.957001,168.768066 
	C246.622513,168.558853 246.326294,168.105682 246.005676,167.372955 
z"
          />
          <path
            fill="#E8C152"
            opacity="1.000000"
            stroke="none"
            d="
M489.068298,129.269012 
	C489.422943,130.009537 489.750916,131.125198 490.079559,132.605469 
	C489.734009,131.861435 489.387787,130.752792 489.068298,129.269012 
z"
          />
          <path
            fill="#E8C152"
            opacity="1.000000"
            stroke="none"
            d="
M488.181976,126.122269 
	C488.480316,126.344879 488.731659,126.880119 488.974243,127.719963 
	C488.688629,127.494675 488.411835,126.964783 488.181976,126.122269 
z"
          />
          <path
            fill="#F5B80C"
            opacity="1.000000"
            stroke="none"
            d="
M448.130981,125.902969 
	C451.879028,128.946442 455.757141,131.846268 459.331207,135.081970 
	C461.876465,137.386230 464.012543,140.142471 466.611450,142.862152 
	C471.846680,151.403778 476.902893,159.722809 481.705841,168.185562 
	C483.571991,171.473709 484.866333,175.086365 486.671204,178.782135 
	C487.098724,179.867676 487.276154,180.722275 487.711212,181.784302 
	C487.968842,181.991714 488.009583,182.486725 487.986908,183.099670 
	C487.973541,185.800537 487.982849,187.888443 487.992157,189.976364 
	C487.838928,190.574661 487.685699,191.172943 487.043945,191.986267 
	C486.368713,192.205795 486.181946,192.210312 485.871246,191.861298 
	C479.649475,176.768951 470.868256,163.797287 459.255249,152.921204 
	C452.161499,146.277557 444.428711,140.316284 436.877533,133.817368 
	C436.502167,133.389496 436.232178,133.192780 435.845703,132.793915 
	C435.535675,132.338913 435.293182,132.148346 434.896057,131.759338 
	C434.525208,131.004547 434.260040,130.510468 434.354889,129.969238 
	C436.151337,129.280594 437.587830,128.639099 439.024292,127.997604 
	C438.809143,128.479752 438.593964,128.961884 438.378815,129.444031 
	C441.500153,131.889893 444.621460,134.335754 447.742798,136.781616 
	C448.931610,134.579605 450.120422,132.377594 451.941681,129.004105 
	C449.382599,129.884964 448.540405,130.174850 447.410645,130.563721 
	C447.687042,128.775330 447.908997,127.339157 448.130981,125.902969 
z"
          />
          <path
            fill="#F9D060"
            opacity="1.000000"
            stroke="none"
            d="
M485.995209,192.214844 
	C486.181946,192.210312 486.368713,192.205795 486.835724,192.215820 
	C486.310120,195.358978 485.504242,198.487610 484.463013,201.898224 
	C484.227631,202.180206 484.112671,202.090439 484.076599,201.600555 
	C484.070770,198.738373 484.100983,196.366104 484.420227,193.952881 
	C485.137909,193.346252 485.566559,192.780548 485.995209,192.214844 
z"
          />
          <path
            fill="#F9CD4E"
            opacity="1.000000"
            stroke="none"
            d="
M488.276764,189.726974 
	C487.982849,187.888443 487.973541,185.800537 488.002167,183.347534 
	C488.213837,185.147507 488.387604,187.312546 488.276764,189.726974 
z"
          />
          <path
            fill="#F7B708"
            opacity="1.000000"
            stroke="none"
            d="
M484.131226,193.993820 
	C484.100983,196.366104 484.070770,198.738373 484.019714,201.554947 
	C482.827881,201.825836 481.656921,201.652435 480.241272,201.312561 
	C472.906219,188.808258 464.706787,177.498611 453.962006,167.850937 
	C438.580109,154.039612 420.478363,145.603958 401.197632,137.952087 
	C399.763092,136.521896 398.609619,135.685684 397.364349,135.021606 
	C395.952454,134.268677 394.458374,133.669891 393.088470,132.623352 
	C396.810181,131.167892 400.427734,130.033646 404.090515,129.071381 
	C404.779266,128.890457 405.770264,129.204559 406.414185,129.615524 
	C410.431305,132.179413 412.649567,131.664307 415.249268,127.346649 
	C405.995026,126.423920 396.791992,125.506287 387.588959,124.588661 
	C387.618958,124.249519 387.648926,123.910385 387.678925,123.571251 
	C388.948883,123.153091 390.218872,122.734940 392.434326,122.005478 
	C378.757538,122.005478 366.180359,122.005478 353.240356,122.005478 
	C353.173828,120.899612 353.086243,119.444153 353.213867,117.944878 
	C353.429108,117.901070 353.868225,117.888458 354.164795,117.910248 
	C354.965057,117.681625 355.468719,117.431221 356.309998,117.145172 
	C357.098236,117.065956 357.548859,117.022385 358.457031,116.995712 
	C362.271332,117.024712 365.628082,117.036819 369.180176,117.282455 
	C370.254944,117.362007 371.134399,117.208038 372.458344,117.040680 
	C375.276764,117.066093 377.650726,117.104897 380.297607,117.386002 
	C382.060883,117.742027 383.551147,117.855759 385.444519,117.992752 
	C392.455505,119.574036 399.075134,121.084671 405.667145,122.707367 
	C409.801361,123.725044 413.894165,124.911125 418.004578,126.007988 
	C418.003662,125.994324 417.977325,126.001892 417.877838,126.311287 
	C418.935120,131.206818 422.718323,127.870033 425.010071,129.006073 
	C425.005768,128.992691 424.978210,128.987503 425.083618,129.220016 
	C425.464630,129.644531 425.740173,129.836533 426.012482,130.013947 
	C426.009216,129.999374 425.978699,129.995010 426.181641,130.264343 
	C435.339722,137.283096 444.760803,143.510330 453.100128,150.950058 
	C459.565277,156.717804 465.199829,163.629318 470.248901,170.712891 
	C475.475800,178.045914 479.551819,186.199280 484.131226,193.993820 
z"
          />
          <path
            fill="#E8C152"
            opacity="1.000000"
            stroke="none"
            d="
M345.998749,35.543991 
	C345.719208,35.794323 345.224854,35.888859 344.352814,35.977165 
	C344.578064,35.776691 345.181000,35.582443 345.998749,35.543991 
z"
          />
          <path
            fill="#F8B701"
            opacity="1.000000"
            stroke="none"
            d="
M448.112976,125.911697 
	C447.908997,127.339157 447.687042,128.775330 447.410645,130.563721 
	C448.540405,130.174850 449.382599,129.884964 451.941681,129.004105 
	C450.120422,132.377594 448.931610,134.579605 447.742798,136.781616 
	C444.621460,134.335754 441.500153,131.889893 438.378815,129.444031 
	C438.593964,128.961884 438.809143,128.479752 439.024292,127.997604 
	C437.587830,128.639099 436.151337,129.280594 434.022461,129.817291 
	C431.153595,128.631271 428.631378,127.939194 426.878082,126.381592 
	C423.926178,123.759224 420.825623,122.424911 416.564209,123.029480 
	C415.488678,122.962723 414.771332,122.911949 413.948975,122.512596 
	C412.229980,121.775215 410.615906,121.386414 409.000916,120.998810 
	C409.000000,121.000000 409.002625,120.997643 409.052948,120.663452 
	C409.564880,119.411430 410.026489,118.493599 410.315247,117.919472 
	C406.136749,116.991570 402.281769,115.973984 398.362823,115.373795 
	C397.325623,115.214935 396.091522,116.341553 394.634521,116.877998 
	C393.904633,116.849731 393.488068,116.821396 392.965271,116.464928 
	C390.562714,115.782234 388.266388,115.427666 385.504272,115.056961 
	C379.369354,115.037521 373.700226,115.034218 367.731506,114.733841 
	C358.208893,114.701889 348.980011,114.858269 339.769775,115.358269 
	C338.498962,115.427261 337.320557,117.198547 335.958984,118.412643 
	C335.532440,119.095863 335.246307,119.548164 334.659393,119.994308 
	C333.959351,119.972343 333.560089,119.956528 333.160797,119.940704 
	C332.631622,119.386284 332.102448,118.831871 331.300507,118.182198 
	C328.823730,117.298752 326.627563,115.884659 324.414032,115.856934 
	C320.228760,115.804504 313.955261,112.889053 313.007751,109.413483 
	C313.426910,108.988106 313.846466,108.964409 314.959930,108.961487 
	C317.179291,109.413773 318.704742,109.845276 320.604889,110.382767 
	C319.322235,106.601143 322.099243,107.549866 324.283356,106.880684 
	C331.603394,104.637947 338.779388,101.917877 345.964142,99.255310 
	C346.502136,99.055931 346.673431,97.866943 347.015167,97.138000 
	C349.045441,97.078369 351.075745,97.018745 353.758545,97.224106 
	C355.622101,97.292015 356.833069,97.094940 358.044067,96.897858 
	C358.447388,96.898071 358.850708,96.898285 359.937256,96.935501 
	C361.751556,96.386543 362.882629,95.800598 364.013672,95.214653 
	C367.036682,95.184334 370.059692,95.154015 373.607361,95.473785 
	C375.764740,96.196098 377.397522,96.568336 379.030273,96.940567 
	C382.047729,96.978600 385.065155,97.016632 388.588806,97.436584 
	C390.329254,101.345886 393.232208,101.117149 396.113739,100.936699 
	C396.522705,100.923637 396.931702,100.910576 397.861511,101.176918 
	C399.827911,102.148720 401.273438,102.841110 402.681610,103.515610 
	C402.068024,105.302345 401.574677,106.738960 400.707092,109.265381 
	C406.639557,105.940025 411.403870,105.317856 415.137695,110.743660 
	C415.330902,111.024445 416.078796,110.929543 416.570007,110.998322 
	C421.368134,111.670204 426.166595,112.339691 430.964966,113.009857 
	C431.799957,113.571373 432.634949,114.132881 433.792419,115.218643 
	C435.077240,116.155396 436.039581,116.567886 437.001953,116.980377 
	C437.001953,116.980377 437.004364,116.996147 437.044189,117.347382 
	C439.286316,120.376511 441.488586,123.054413 443.690857,125.732323 
	C443.428406,126.174408 443.165985,126.616493 442.903534,127.058586 
	C444.623169,126.674538 446.342773,126.290489 448.062408,125.906448 
	C448.062408,125.906456 448.095001,125.920403 448.112976,125.911697 
z"
          />
          <path
            fill="#F6B80B"
            opacity="1.000000"
            stroke="none"
            d="
M430.765503,112.736267 
	C426.166595,112.339691 421.368134,111.670204 416.570007,110.998322 
	C416.078796,110.929543 415.330902,111.024445 415.137695,110.743660 
	C411.403870,105.317856 406.639557,105.940025 400.707092,109.265381 
	C401.574677,106.738960 402.068024,105.302345 402.681610,103.515610 
	C401.273438,102.841110 399.827911,102.148720 398.168457,101.175072 
	C401.350281,100.936287 404.746033,100.978752 408.569824,101.012146 
	C413.703918,103.516960 418.407166,106.036110 423.117157,108.542603 
	C425.594025,109.860718 428.082672,111.156693 430.765503,112.736267 
z"
          />
          <path
            fill="#F6B80B"
            opacity="1.000000"
            stroke="none"
            d="
M346.637726,97.137642 
	C346.673431,97.866943 346.502136,99.055931 345.964142,99.255310 
	C338.779388,101.917877 331.603394,104.637947 324.283356,106.880684 
	C322.099243,107.549866 319.322235,106.601143 320.604889,110.382767 
	C318.704742,109.845276 317.179291,109.413773 315.275208,108.958878 
	C321.402771,105.996178 327.818817,102.828918 334.450226,100.206200 
	C338.204285,98.721481 342.311920,98.130699 346.637726,97.137642 
z"
          />
          <path
            fill="#F6B80B"
            opacity="1.000000"
            stroke="none"
            d="
M396.024261,100.596573 
	C393.232208,101.117149 390.329254,101.345886 389.040283,97.465500 
	C391.301971,98.160477 393.618378,99.208466 396.024261,100.596573 
z"
          />
          <path
            fill="#F6B80B"
            opacity="1.000000"
            stroke="none"
            d="
M378.809265,96.676834 
	C377.397522,96.568336 375.764740,96.196098 374.058655,95.514297 
	C375.519623,95.607521 377.053955,96.010307 378.809265,96.676834 
z"
          />
          <path
            fill="#F6B80B"
            opacity="1.000000"
            stroke="none"
            d="
M357.810486,96.665085 
	C356.833069,97.094940 355.622101,97.292015 354.193970,97.222946 
	C355.176880,96.781975 356.376862,96.607147 357.810486,96.665085 
z"
          />
          <path
            fill="#F6B80B"
            opacity="1.000000"
            stroke="none"
            d="
M363.643555,95.159546 
	C362.882629,95.800598 361.751556,96.386543 360.239624,96.944595 
	C360.997009,96.312607 362.135223,95.708527 363.643555,95.159546 
z"
          />
          <path
            fill="#F9CA48"
            opacity="1.000000"
            stroke="none"
            d="
M307.866638,110.751450 
	C307.549805,111.003204 307.106689,111.028000 306.331055,111.032585 
	C306.065491,110.682281 306.228271,110.409363 306.689758,110.062904 
	C307.175232,110.129631 307.457764,110.327065 307.866638,110.751450 
z"
          />
          <path
            fill="#F7B706"
            opacity="1.000000"
            stroke="none"
            d="
M385.395203,72.091057 
	C385.004333,70.925377 385.000244,69.764587 385.011230,68.603958 
	C385.039337,65.640266 386.342865,64.829712 389.155090,65.880470 
	C392.698059,67.204269 396.330231,68.289276 400.357208,69.620552 
	C399.469666,74.384048 402.600006,75.539169 406.834381,76.119469 
	C409.558411,76.492775 413.061646,76.273827 413.951843,80.578690 
	C408.551727,79.065681 403.105743,77.185707 397.329559,75.143784 
	C395.788940,70.978050 392.814575,69.615623 389.010681,71.712875 
	C387.882019,72.075722 386.835388,72.085869 385.395203,72.091057 
z"
          />
          <path
            fill="#F7B706"
            opacity="1.000000"
            stroke="none"
            d="
M338.355469,73.008331 
	C337.579010,69.729240 339.157104,68.282661 342.193420,69.160667 
	C344.525238,69.834953 346.646942,71.235939 348.942078,72.688690 
	C347.267120,73.703072 345.512177,74.342514 343.382324,74.985344 
	C341.575226,74.331177 340.143097,73.673615 338.355469,73.008331 
z"
          />
          <path
            fill="#F7B706"
            opacity="1.000000"
            stroke="none"
            d="
M416.141907,81.320984 
	C418.861267,81.432190 421.754730,81.845032 424.648193,82.257866 
	C424.533600,81.992493 424.419037,81.727112 424.304443,81.461739 
	C427.610992,80.613495 430.917542,79.765259 434.929138,78.736153 
	C433.303650,85.214386 427.711609,82.721703 424.367737,84.996231 
	C421.433289,83.876625 418.874634,82.749626 416.141907,81.320984 
z"
          />
          <path
            fill="#F0B813"
            opacity="1.000000"
            stroke="none"
            d="
M434.096313,90.298752 
	C438.606567,88.968437 440.845001,91.984627 442.946045,95.661591 
	C440.893341,95.037437 438.802094,94.050949 436.360229,93.026062 
	C435.415436,92.182190 434.821198,91.376701 434.096313,90.298752 
z"
          />
          <path
            fill="#F0B813"
            opacity="1.000000"
            stroke="none"
            d="
M427.129089,86.317757 
	C428.974518,87.088585 430.983429,88.148796 433.009247,89.579178 
	C431.114960,88.835274 429.203735,87.721207 427.129089,86.317757 
z"
          />
          <path
            fill="#F0B813"
            opacity="1.000000"
            stroke="none"
            d="
M467.037292,119.355484 
	C468.690796,120.756409 470.334229,122.531090 471.967896,124.675133 
	C471.532257,125.011055 471.106323,124.977608 470.363831,124.894547 
	C469.264130,123.909409 468.481049,122.973885 467.366089,122.002831 
	C467.031891,121.221283 467.029541,120.475266 467.037292,119.355484 
z"
          />
          <path
            fill="#F0B813"
            opacity="1.000000"
            stroke="none"
            d="
M444.156128,97.295059 
	C446.660889,96.853516 449.306244,96.732239 449.945312,100.660164 
	C448.089233,99.889954 446.199371,98.741219 444.156128,97.295059 
z"
          />
          <path
            fill="#F0B813"
            opacity="1.000000"
            stroke="none"
            d="
M454.101929,105.299126 
	C454.965332,105.781715 455.967987,106.541008 456.965149,107.677429 
	C456.053497,107.228310 455.147339,106.402069 454.101929,105.299126 
z"
          />
          <path
            fill="#F0B813"
            opacity="1.000000"
            stroke="none"
            d="
M462.136444,113.256180 
	C462.653168,113.448578 463.284027,113.910057 463.957397,114.690071 
	C463.416840,114.514153 462.833710,114.019707 462.136444,113.256180 
z"
          />
          <path
            fill="#F0B813"
            opacity="1.000000"
            stroke="none"
            d="
M461.098633,112.219490 
	C461.307495,112.101646 461.570801,112.284271 461.891296,112.764771 
	C461.682495,112.884392 461.417358,112.703499 461.098633,112.219490 
z"
          />
          <path
            fill="#F5B80C"
            opacity="1.000000"
            stroke="none"
            d="
M448.027130,125.576874 
	C446.342773,126.290489 444.623169,126.674538 442.903534,127.058586 
	C443.165985,126.616493 443.428406,126.174408 443.690857,125.732323 
	C441.488586,123.054413 439.286316,120.376511 437.052185,117.345642 
	C439.099274,117.983994 441.178223,118.975304 443.603638,120.015427 
	C445.297363,121.791931 446.644592,123.519615 448.027130,125.576874 
z"
          />
          <path
            fill="#F5B80C"
            opacity="1.000000"
            stroke="none"
            d="
M436.862061,116.692001 
	C436.039581,116.567886 435.077240,116.155396 434.048035,115.409920 
	C434.894836,115.519165 435.808472,115.961388 436.862061,116.692001 
z"
          />
          <path
            fill="#F9CA48"
            opacity="1.000000"
            stroke="none"
            d="
M305.637299,111.001495 
	C301.215179,114.924248 296.339691,118.755455 291.640381,122.791809 
	C285.539124,128.032272 279.578094,133.435974 273.269897,138.958221 
	C273.775513,137.484650 274.509888,135.791245 275.377228,134.168961 
	C276.183014,132.661819 277.130615,131.230545 278.028412,129.403442 
	C281.986511,126.229607 285.854492,123.300674 289.897034,120.636597 
	C294.943665,117.310799 300.133972,114.203056 305.637299,111.001495 
z"
          />
          <path
            fill="#F0B813"
            opacity="1.000000"
            stroke="none"
            d="
M471.966797,125.401291 
	C476.603943,124.727509 476.157471,128.513351 476.966980,131.674042 
	C475.547394,130.728241 474.136871,129.389633 472.411621,127.979980 
	C472.057404,127.192902 472.017944,126.476852 471.966797,125.401291 
z"
          />
          <path
            fill="#F0B813"
            opacity="1.000000"
            stroke="none"
            d="
M477.951782,133.328568 
	C478.257599,133.490433 478.601471,133.946228 478.957825,134.704498 
	C478.643524,134.545456 478.316681,134.083969 477.951782,133.328568 
z"
          />
          <path
            fill="#F7B706"
            opacity="1.000000"
            stroke="none"
            d="
M250.253464,153.949585 
	C250.109436,153.684387 250.277390,153.453842 250.716003,153.192963 
	C250.914108,153.109741 250.954895,153.480209 250.960480,153.664612 
	C250.966080,153.849014 250.492920,153.931381 250.253464,153.949585 
z"
          />
          <path
            fill="#F6C232"
            opacity="1.000000"
            stroke="none"
            d="
M291.595825,77.968857 
	C292.071198,78.263420 292.261322,78.532593 292.748474,78.863747 
	C292.083710,79.599281 291.227386,80.269768 290.280579,80.768547 
	C290.165619,80.829102 289.563354,79.964539 289.186920,79.528748 
	C289.868805,79.018700 290.550659,78.508644 291.595825,77.968857 
z"
          />
          <path
            fill="#F8B701"
            opacity="1.000000"
            stroke="none"
            d="
M408.982788,183.022202 
	C407.809113,182.890533 406.635437,182.758865 405.213501,182.114029 
	C404.973816,181.399872 404.982422,181.198883 404.990997,180.997894 
	C406.325958,179.258240 407.660919,177.518585 409.435425,175.389771 
	C411.916229,174.998764 413.957489,174.996933 415.998779,174.995117 
	C415.999664,176.062943 416.000580,177.130768 415.785553,178.710526 
	C415.299835,179.422272 415.110779,179.681076 414.705505,180.022537 
	C413.938629,180.365143 413.468719,180.684097 412.622925,181.003174 
	C411.158966,181.676285 410.070862,182.349243 408.982788,183.022202 
z"
          />
          <path
            fill="#F5B80C"
            opacity="1.000000"
            stroke="none"
            d="
M423.775146,183.192230 
	C427.444092,183.768677 430.886963,184.541611 434.539734,185.361664 
	C432.946808,191.831802 429.355408,186.958313 426.378571,187.000763 
	C425.185150,185.791351 424.367126,184.590042 423.775146,183.192230 
z"
          />
          <path
            fill="#F8B705"
            opacity="1.000000"
            stroke="none"
            d="
M418.963593,187.055756 
	C416.523468,186.118790 414.083374,185.181839 411.473480,183.907684 
	C411.868744,183.379715 412.433746,183.188950 413.399170,182.998688 
	C414.867279,182.997208 415.935028,182.995255 417.335449,182.994720 
	C418.111694,182.996216 418.555237,182.996277 418.998779,182.996338 
	C419.911285,183.798309 420.823792,184.600296 421.526001,185.704376 
	C420.531677,186.356232 419.747650,186.705994 418.963593,187.055756 
z"
          />
          <path
            fill="#F5B80C"
            opacity="1.000000"
            stroke="none"
            d="
M415.992493,174.620422 
	C413.957489,174.996933 411.916229,174.998764 409.437469,175.001205 
	C408.483734,174.535904 407.967468,174.070007 407.451172,173.604095 
	C409.470673,172.855820 411.449524,171.817947 413.533020,171.525284 
	C414.209991,171.430176 415.160675,173.283569 415.992493,174.620422 
z"
          />
          <path
            fill="#F5B80C"
            opacity="1.000000"
            stroke="none"
            d="
M419.176880,187.319641 
	C419.747650,186.705994 420.531677,186.356232 421.658447,186.003571 
	C422.318237,186.110001 422.576691,186.299255 422.888916,186.784210 
	C424.299866,188.097336 425.598480,189.194672 426.955109,190.616531 
	C424.472137,189.821884 421.931152,188.702713 419.176880,187.319641 
z"
          />
          <path
            fill="#F5B80C"
            opacity="1.000000"
            stroke="none"
            d="
M360.821045,176.822052 
	C358.666626,177.438812 356.272522,177.768295 353.452881,178.114243 
	C355.545349,177.598724 358.063385,177.066757 360.821045,176.822052 
z"
          />
          <path
            fill="#F5B80C"
            opacity="1.000000"
            stroke="none"
            d="
M418.994019,182.633392 
	C418.555237,182.996277 418.111694,182.996216 417.306274,182.675262 
	C416.297089,181.569199 415.649780,180.784027 415.002441,179.998871 
	C415.110779,179.681076 415.299835,179.422272 415.786041,179.110962 
	C416.998047,180.089783 417.993652,181.180115 418.994019,182.633392 
z"
          />
          <path
            fill="#F5B80C"
            opacity="1.000000"
            stroke="none"
            d="
M397.767822,179.938141 
	C396.130524,180.029602 394.330078,179.804230 392.244446,179.324936 
	C393.841095,179.254440 395.722900,179.437851 397.767822,179.938141 
z"
          />
          <path
            fill="#F5B80C"
            opacity="1.000000"
            stroke="none"
            d="
M404.692505,180.867249 
	C404.982422,181.198883 404.973816,181.399872 404.929810,181.902451 
	C403.748474,181.973343 402.602600,181.742630 401.233185,181.238602 
	C402.137787,180.889053 403.265869,180.812836 404.692505,180.867249 
z"
          />
          <path
            fill="#F5B80C"
            opacity="1.000000"
            stroke="none"
            d="
M378.942261,177.559326 
	C378.139893,177.767242 377.279999,177.662033 376.179626,177.334030 
	C376.921021,177.156235 377.902893,177.201218 378.942261,177.559326 
z"
          />
          <path
            fill="#F5B80C"
            opacity="1.000000"
            stroke="none"
            d="
M412.998779,182.998169 
	C412.433746,183.188950 411.868744,183.379715 411.130676,183.824905 
	C410.409607,183.891190 409.861542,183.703033 409.148132,183.268539 
	C410.070862,182.349243 411.158966,181.676285 412.623169,181.335663 
	C412.999115,182.111404 412.998932,182.554794 412.998779,182.998169 
z"
          />
          <path
            fill="#F5B80C"
            opacity="1.000000"
            stroke="none"
            d="
M429.917603,192.385620 
	C430.517670,192.457550 431.206238,192.836533 431.960236,193.524460 
	C431.352478,193.453171 430.679260,193.072937 429.917603,192.385620 
z"
          />
          <path
            fill="#F5B80C"
            opacity="1.000000"
            stroke="none"
            d="
M427.993256,191.282379 
	C428.154663,191.224152 428.452515,191.381638 428.877686,191.762299 
	C428.713226,191.823013 428.421448,191.660553 427.993256,191.282379 
z"
          />
          <path
            fill="#F8B701"
            opacity="1.000000"
            stroke="none"
            d="
M352.998657,117.988678 
	C353.086243,119.444153 353.173828,120.899612 353.240356,122.005478 
	C366.180359,122.005478 378.757538,122.005478 392.434326,122.005478 
	C390.218872,122.734940 388.948883,123.153091 387.678925,123.571251 
	C387.648926,123.910385 387.618958,124.249519 387.588959,124.588661 
	C396.791992,125.506287 405.995026,126.423920 415.249268,127.346649 
	C412.649567,131.664307 410.431305,132.179413 406.414185,129.615524 
	C405.770264,129.204559 404.779266,128.890457 404.090515,129.071381 
	C400.427734,130.033646 396.810181,131.167892 392.625244,132.622101 
	C388.048248,133.000931 384.022888,133.000229 379.953247,132.681213 
	C379.272308,131.910324 378.635651,131.457779 377.623566,131.168869 
	C371.832642,131.221085 366.417114,131.109680 360.748779,130.727203 
	C358.757202,129.991135 356.259033,130.092117 355.415314,128.960037 
	C352.293518,124.771248 348.990204,126.783470 345.280273,128.713425 
	C347.852936,129.809280 350.425629,130.905121 352.724670,132.280151 
	C347.299866,133.372894 342.148712,134.186447 337.021545,134.703506 
	C337.363770,133.937698 337.682037,133.468369 338.333679,132.999374 
	C339.111267,132.999466 339.555511,132.999252 340.365845,132.997955 
	C341.655548,132.774963 342.579193,132.553024 343.502808,132.331100 
	C343.486786,132.033401 343.470764,131.735703 343.454712,131.438004 
	C342.303040,131.292191 341.151398,131.146362 339.666260,131.000366 
	C338.888550,131.000259 338.444275,131.000336 337.665649,131.034378 
	C335.887939,132.379089 334.444580,133.689850 333.000610,135.000305 
	C333.000000,135.000000 333.000000,135.001221 332.679749,135.029282 
	C331.573822,135.705093 330.788116,136.352844 329.585815,137.000778 
	C327.780518,136.999817 326.391876,136.998688 324.830933,136.684143 
	C319.742249,133.865814 317.138641,136.120041 317.000366,143.343109 
	C317.001129,144.142944 317.002716,144.600281 317.004303,145.057617 
	C317.004303,145.057617 316.618622,145.128143 316.013519,145.078278 
	C311.484222,145.028427 307.560028,145.028427 303.635834,145.028427 
	C303.677277,144.623108 303.718689,144.217789 303.760101,143.812469 
	C302.882263,144.542465 302.004425,145.272476 300.706177,146.352097 
	C300.022400,143.398819 299.507385,141.174408 298.992371,138.949997 
	C298.992371,138.949997 299.404755,138.887711 299.978943,138.931229 
	C301.828735,139.448425 303.104309,139.922119 303.762817,140.166656 
	C304.566315,138.674484 304.927155,136.833054 305.975006,136.269821 
	C309.671722,134.282837 313.588470,132.697708 317.458649,131.046722 
	C318.329803,130.675095 319.741241,130.155014 320.241974,130.547592 
	C325.570465,134.725327 323.808624,128.793808 325.018097,127.000305 
	C326.383423,127.000305 327.692932,127.000305 329.058990,127.342361 
	C330.077362,128.122131 331.039185,128.559845 332.351807,128.994385 
	C334.135101,128.327454 335.567535,127.663727 337.396545,126.992020 
	C341.961456,126.015862 346.129852,125.047684 349.578552,124.246658 
	C348.755859,122.591667 347.883453,120.836700 347.249023,119.043839 
	C347.487000,119.005951 347.968628,118.990570 348.181519,118.918388 
	C348.665649,118.700859 348.872192,118.491676 349.410828,118.173836 
	C350.871307,118.082237 351.934998,118.035461 352.998657,117.988678 
M387.650787,130.941971 
	C389.393738,130.185928 391.136688,129.429871 392.879608,128.673813 
	C392.681000,128.186768 392.482391,127.699722 392.283783,127.212669 
	C390.820465,127.144821 389.343018,126.915001 387.896637,127.041191 
	C384.354889,127.350174 384.133789,128.704926 387.650787,130.941971 
z"
          />
          <path
            fill="#F5B809"
            opacity="1.000000"
            stroke="none"
            d="
M298.640594,138.991852 
	C299.507385,141.174408 300.022400,143.398819 300.706177,146.352097 
	C302.004425,145.272476 302.882263,144.542465 303.760101,143.812469 
	C303.718689,144.217789 303.677277,144.623108 303.635834,145.028427 
	C307.560028,145.028427 311.484222,145.028427 315.817566,145.073212 
	C315.888580,145.673477 315.686066,146.463058 315.191986,146.749207 
	C308.072418,150.872360 300.913666,154.927795 293.343872,158.706970 
	C291.651276,156.935440 290.379089,155.456421 288.537048,153.314880 
	C291.576874,153.798401 293.472107,154.099869 296.334930,154.555252 
	C294.560638,151.662247 293.894806,149.873535 292.630005,148.773453 
	C291.849884,148.094910 289.540375,147.875076 288.975311,148.464081 
	C285.790375,151.783981 283.214600,155.722595 279.859680,158.834091 
	C277.258514,161.246506 275.787140,165.703232 270.941650,164.948242 
	C273.010254,162.102753 274.661041,158.787872 277.228333,156.502655 
	C284.037018,150.442093 291.241699,144.826401 298.640594,138.991852 
z"
          />
          <path
            fill="#F5B809"
            opacity="1.000000"
            stroke="none"
            d="
M325.003204,136.997559 
	C326.391876,136.998688 327.780518,136.999817 329.937744,136.994553 
	C331.470886,136.325851 332.235443,135.663528 333.000000,135.001221 
	C333.000000,135.001221 333.000000,135.000000 333.400238,135.000122 
	C334.866180,135.000153 335.931854,135.000076 336.997559,135.000000 
	C342.148712,134.186447 347.299866,133.372894 353.154297,132.279785 
	C356.238892,131.666245 358.620270,131.332245 361.001617,130.998260 
	C366.417114,131.109680 371.832642,131.221085 377.668976,131.486465 
	C378.725739,132.093460 379.361633,132.546494 379.997559,132.999527 
	C384.022888,133.000229 388.048248,133.000931 392.536804,133.002869 
	C394.458374,133.669891 395.952454,134.268677 397.364349,135.021606 
	C398.609619,135.685684 399.763092,136.521896 400.954895,137.695587 
	C392.930969,137.244751 384.921753,136.226944 376.882996,135.570694 
	C371.622009,135.141190 366.283661,134.782578 361.040466,135.183334 
	C347.560333,136.213638 334.389496,138.933197 321.359314,143.515610 
	C319.718811,139.498703 323.837250,139.190521 325.003204,136.997559 
z"
          />
          <path
            fill="#F5B80B"
            opacity="1.000000"
            stroke="none"
            d="
M270.959961,164.955353 
	C275.787140,165.703232 277.258514,161.246506 279.859680,158.834091 
	C283.214600,155.722595 285.790375,151.783981 288.975311,148.464081 
	C289.540375,147.875076 291.849884,148.094910 292.630005,148.773453 
	C293.894806,149.873535 294.560638,151.662247 296.334930,154.555252 
	C293.472107,154.099869 291.576874,153.798401 288.537048,153.314880 
	C290.379089,155.456421 291.651276,156.935440 292.986267,158.762711 
	C292.246613,159.735458 291.444153,160.359940 290.320984,160.999634 
	C289.642426,159.728470 289.284576,158.442108 288.613861,156.031189 
	C283.880981,160.282639 279.980988,163.785965 275.667358,167.644196 
	C273.835815,167.665680 272.417908,167.332230 270.998596,166.656982 
	C270.985718,165.859070 270.974274,165.402939 270.962830,164.946823 
	C270.962799,164.946823 270.978271,164.962479 270.959961,164.955353 
z"
          />
          <path
            fill="#F7B708"
            opacity="1.000000"
            stroke="none"
            d="
M324.830933,136.684143 
	C323.837250,139.190521 319.718811,139.498703 321.013336,143.662476 
	C320.538177,144.333908 320.056335,144.600983 319.233521,144.561859 
	C318.262085,143.837311 317.631653,143.418961 317.001221,143.000610 
	C317.138641,136.120041 319.742249,133.865814 324.830933,136.684143 
z"
          />
          <path
            fill="#F9D060"
            opacity="1.000000"
            stroke="none"
            d="
M260.549683,199.081421 
	C260.043121,199.136230 259.818756,199.088074 259.255127,198.978241 
	C259.103302,198.080841 259.290771,197.245071 259.726196,196.196686 
	C260.474548,196.248108 260.974976,196.512177 261.735291,196.888245 
	C261.607422,197.659653 261.219666,198.319046 260.549683,199.081421 
z"
          />
          <path
            fill="#F5B809"
            opacity="1.000000"
            stroke="none"
            d="
M317.000366,143.343109 
	C317.631653,143.418961 318.262085,143.837311 318.943665,144.556396 
	C318.554016,144.918503 318.113220,144.979874 317.338348,145.049438 
	C317.002716,144.600281 317.001129,144.142944 317.000366,143.343109 
z"
          />
          <path
            fill="#F5B809"
            opacity="1.000000"
            stroke="none"
            d="
M307.984802,169.594025 
	C305.226013,170.892807 302.453278,171.799210 299.343506,172.853424 
	C299.231110,170.950058 298.045959,168.146027 301.686401,167.906357 
	C303.894135,167.761017 306.674103,164.909393 307.984802,169.594025 
z"
          />
          <path
            fill="#EEBA1A"
            opacity="1.000000"
            stroke="none"
            d="
M320.602356,123.030342 
	C316.954803,125.208496 312.885284,127.359909 308.830322,129.538406 
	C306.126434,130.991058 303.443268,132.482300 300.406250,134.032028 
	C301.177460,131.967270 302.293274,129.826736 303.409058,127.686218 
	C303.579071,128.066879 303.749115,128.447525 303.919159,128.828186 
	C307.430847,127.503975 310.931274,126.148651 314.458832,124.868141 
	C316.343903,124.183853 318.275818,123.628609 320.602356,123.030342 
z"
          />
          <path
            fill="#EEBA1A"
            opacity="1.000000"
            stroke="none"
            d="
M295.552368,136.977142 
	C294.195343,138.295670 292.450012,139.623428 290.367371,140.985474 
	C289.871796,137.295486 292.536346,137.164368 295.552368,136.977142 
z"
          />
          <path
            fill="#EEBA1A"
            opacity="1.000000"
            stroke="none"
            d="
M289.654053,141.022461 
	C289.231903,141.681244 288.452240,142.334030 287.357635,143.033875 
	C287.793976,142.392792 288.545258,141.704636 289.654053,141.022461 
z"
          />
          <path
            fill="#EEBA1A"
            opacity="1.000000"
            stroke="none"
            d="
M322.807495,122.076088 
	C322.623566,122.386154 322.128082,122.687492 321.333832,123.011719 
	C321.522034,122.712204 322.009003,122.389778 322.807495,122.076088 
z"
          />
          <path
            fill="#EEBA1A"
            opacity="1.000000"
            stroke="none"
            d="
M296.739655,136.097183 
	C296.868530,136.311279 296.694183,136.566772 296.203674,136.847076 
	C296.097412,136.641098 296.280396,136.396667 296.739655,136.097183 
z"
          />
          <path
            fill="#EEBA1A"
            opacity="1.000000"
            stroke="none"
            d="
M297.917847,134.859039 
	C298.014435,135.026352 297.536285,135.077637 297.297455,135.102097 
	C297.312836,134.981613 297.567017,134.836670 297.917847,134.859039 
z"
          />
          <path
            fill="#EEBA1A"
            opacity="1.000000"
            stroke="none"
            d="
M324.891876,121.159683 
	C324.916199,121.335587 324.689789,121.586220 324.225952,121.932449 
	C324.206055,121.763504 324.423584,121.498955 324.891876,121.159683 
z"
          />
          <path
            fill="#F5B809"
            opacity="1.000000"
            stroke="none"
            d="
M337.000000,127.000000 
	C335.567535,127.663727 334.135101,128.327454 332.294128,128.652817 
	C330.924530,127.876396 329.963501,127.438354 329.002441,127.000305 
	C327.692932,127.000305 326.383423,127.000305 325.018097,127.000305 
	C323.808624,128.793808 325.570465,134.725327 320.241974,130.547592 
	C319.741241,130.155014 318.329803,130.675095 317.458649,131.046722 
	C313.588470,132.697708 309.671722,134.282837 305.975006,136.269821 
	C304.927155,136.833054 304.566315,138.674484 303.762817,140.166656 
	C303.104309,139.922119 301.828735,139.448425 300.187439,138.935486 
	C306.994446,134.628555 314.059753,130.162521 321.392334,126.189812 
	C324.369843,124.576607 327.929016,124.037018 331.675964,123.330597 
	C333.752228,124.768188 335.376129,125.884094 337.000000,127.000000 
z"
          />
          <path
            fill="#F7B708"
            opacity="1.000000"
            stroke="none"
            d="
M337.396545,126.992020 
	C335.376129,125.884094 333.752228,124.768188 332.059387,123.285065 
	C332.286469,122.807907 332.534180,122.630508 333.229248,122.195190 
	C334.819824,121.655113 335.914734,121.305511 337.303345,120.839233 
	C338.396118,120.487083 339.195221,120.251610 340.192596,120.259750 
	C341.274811,120.066063 342.158722,119.628738 343.437561,119.144684 
	C344.892059,119.092545 345.951538,119.087135 347.011047,119.081726 
	C347.883453,120.836700 348.755859,122.591667 349.578552,124.246658 
	C346.129852,125.047684 341.961456,126.015862 337.396545,126.992020 
z"
          />
          <path
            fill="#F5B80B"
            opacity="1.000000"
            stroke="none"
            d="
M258.747192,183.114609 
	C262.535065,184.939331 262.480011,189.052490 257.826996,194.814117 
	C257.826996,191.970901 257.837494,190.360275 257.823547,188.749863 
	C257.813110,187.544617 257.771210,186.339645 257.406921,185.070938 
	C257.541534,184.417221 258.012543,183.827118 258.747192,183.114609 
z"
          />
          <path
            fill="#F5B809"
            opacity="1.000000"
            stroke="none"
            d="
M270.612915,164.976257 
	C270.974274,165.402939 270.985718,165.859070 270.849274,166.957275 
	C268.805511,169.403976 266.909637,171.208572 265.013763,173.013168 
	C265.013763,173.013168 264.961243,172.961990 264.933960,172.935242 
	C266.692108,170.274231 268.477539,167.639954 270.612915,164.976257 
z"
          />
          <path
            fill="#EEBA1A"
            opacity="1.000000"
            stroke="none"
            d="
M337.009674,120.955910 
	C335.914734,121.305511 334.819824,121.655113 333.354675,122.001144 
	C333.012817,121.539940 333.041138,121.082306 333.115112,120.282700 
	C333.560089,119.956528 333.959351,119.972343 334.958740,120.021713 
	C336.042480,120.355476 336.526062,120.655685 337.009674,120.955910 
z"
          />
          <path
            fill="#F7B708"
            opacity="1.000000"
            stroke="none"
            d="
M265.009094,173.424042 
	C266.909637,171.208572 268.805511,169.403976 270.850708,167.299072 
	C272.417908,167.332230 273.835815,167.665680 275.626251,167.998657 
	C276.125916,168.625488 276.581787,169.576462 276.330719,169.831497 
	C272.658905,173.561523 268.892761,177.198685 264.576355,181.417770 
	C264.754028,178.270584 264.879242,176.052750 265.009094,173.424042 
z"
          />
          <path
            fill="#F9CD4E"
            opacity="1.000000"
            stroke="none"
            d="
M484.420227,193.952881 
	C479.551819,186.199280 475.475800,178.045914 470.248901,170.712891 
	C465.199829,163.629318 459.565277,156.717804 453.100128,150.950058 
	C444.760803,143.510330 435.339722,137.283096 426.579834,130.254822 
	C429.517334,130.657333 432.259552,131.338699 435.001740,132.020050 
	C435.293182,132.148346 435.535675,132.338913 435.948975,133.026550 
	C436.440125,133.657059 436.711517,133.852798 436.982910,134.048523 
	C444.428711,140.316284 452.161499,146.277557 459.255249,152.921204 
	C470.868256,163.797287 479.649475,176.768951 485.871246,191.861313 
	C485.566559,192.780548 485.137909,193.346252 484.420227,193.952881 
z"
          />
          <path
            fill="#EEBA1A"
            opacity="1.000000"
            stroke="none"
            d="
M434.896057,131.759338 
	C432.259552,131.338699 429.517334,130.657333 426.376923,129.985504 
	C425.978699,129.995010 426.009216,129.999374 425.908997,129.779877 
	C425.531921,129.369431 425.255066,129.178467 424.978210,128.987503 
	C424.978210,128.987503 425.005768,128.992691 424.839661,128.694107 
	C422.441498,127.597649 420.209412,126.799774 417.977325,126.001892 
	C417.977325,126.001892 418.003662,125.994324 417.902954,125.704193 
	C417.508942,124.624527 417.215637,123.834991 416.922363,123.045464 
	C420.825623,122.424911 423.926178,123.759224 426.878082,126.381592 
	C428.631378,127.939194 431.153595,128.631271 433.662476,129.864441 
	C434.260040,130.510468 434.525208,131.004547 434.896057,131.759338 
z"
          />
          <path
            fill="#EEBA1A"
            opacity="1.000000"
            stroke="none"
            d="
M436.877533,133.817368 
	C436.711517,133.852798 436.440125,133.657059 436.065460,133.228683 
	C436.232178,133.192780 436.502167,133.389496 436.877533,133.817368 
z"
          />
          <path
            fill="#F9CD4E"
            opacity="1.000000"
            stroke="none"
            d="
M416.564209,123.029480 
	C417.215637,123.834991 417.508942,124.624527 417.903870,125.717857 
	C413.894165,124.911125 409.801361,123.725044 405.667145,122.707367 
	C399.075134,121.084671 392.455505,119.574036 385.178619,117.731277 
	C383.014679,117.345604 381.519653,117.244652 380.024658,117.143700 
	C377.650726,117.104897 375.276764,117.066093 372.080261,117.011215 
	C370.500092,117.013077 369.742462,117.031006 368.984863,117.048935 
	C365.628082,117.036819 362.271332,117.024712 358.461853,116.736008 
	C358.013489,116.286194 358.017822,116.112953 358.466339,115.966225 
	C361.950714,115.672134 364.990936,115.351532 368.031128,115.030930 
	C373.700226,115.034218 379.369354,115.037521 385.766724,115.306824 
	C388.687164,115.979576 390.879333,116.386314 393.071503,116.793060 
	C393.488068,116.821396 393.904633,116.849731 395.014038,116.973427 
	C400.138794,118.378403 404.570709,119.688019 409.002625,120.997643 
	C409.002625,120.997643 409.000000,121.000000 409.222473,121.257034 
	C410.981293,121.963104 412.517609,122.412140 414.053955,122.861176 
	C414.771332,122.911949 415.488678,122.962723 416.564209,123.029480 
z"
          />
          <path
            fill="#FACD57"
            opacity="1.000000"
            stroke="none"
            d="
M358.022156,115.939705 
	C358.017822,116.112953 358.013489,116.286194 358.004333,116.719124 
	C357.548859,117.022385 357.098236,117.065956 356.018127,117.117470 
	C354.881866,117.379761 354.375031,117.634102 353.868225,117.888458 
	C353.868225,117.888458 353.429108,117.901070 353.213867,117.944870 
	C351.934998,118.035461 350.871307,118.082237 349.184875,118.215759 
	C348.275635,118.455330 348.077820,118.684692 347.968628,118.990570 
	C347.968628,118.990570 347.487000,119.005951 347.249023,119.043839 
	C345.951538,119.087135 344.892059,119.092545 343.099640,119.095596 
	C341.575928,119.400879 340.785095,119.708504 339.994293,120.016129 
	C339.195221,120.251610 338.396118,120.487083 337.303345,120.839233 
	C336.526062,120.655685 336.042480,120.355476 335.259521,120.027863 
	C335.246307,119.548164 335.532440,119.095863 336.369873,118.364815 
	C342.574127,117.128624 348.214935,116.085938 353.889099,115.277252 
	C355.208496,115.089188 356.641449,115.697708 358.022156,115.939705 
z"
          />
          <path
            fill="#F9CD4E"
            opacity="1.000000"
            stroke="none"
            d="
M417.877838,126.311287 
	C420.209412,126.799774 422.441498,127.597649 424.843994,128.707489 
	C422.718323,127.870033 418.935120,131.206818 417.877838,126.311287 
z"
          />
          <path
            fill="#EEBA1A"
            opacity="1.000000"
            stroke="none"
            d="
M380.297607,117.386002 
	C381.519653,117.244652 383.014679,117.345604 384.775574,117.708015 
	C383.551147,117.855759 382.060883,117.742027 380.297607,117.386002 
z"
          />
          <path
            fill="#EEBA1A"
            opacity="1.000000"
            stroke="none"
            d="
M369.180176,117.282455 
	C369.742462,117.031006 370.500092,117.013077 371.635773,117.024605 
	C371.134399,117.208038 370.254944,117.362007 369.180176,117.282455 
z"
          />
          <path
            fill="#EEBA1A"
            opacity="1.000000"
            stroke="none"
            d="
M354.164795,117.910240 
	C354.375031,117.634102 354.881866,117.379761 355.680511,117.153114 
	C355.468719,117.431221 354.965057,117.681625 354.164795,117.910240 
z"
          />
          <path
            fill="#F9CD4E"
            opacity="1.000000"
            stroke="none"
            d="
M425.083618,129.220016 
	C425.255066,129.178467 425.531921,129.369431 425.912262,129.794464 
	C425.740173,129.836533 425.464630,129.644531 425.083618,129.220016 
z"
          />
          <path
            fill="#EEBA1A"
            opacity="1.000000"
            stroke="none"
            d="
M358.466339,115.966225 
	C356.641449,115.697708 355.208496,115.089188 353.889099,115.277252 
	C348.214935,116.085938 342.574127,117.128624 336.510254,118.133896 
	C337.320557,117.198547 338.498962,115.427261 339.769775,115.358269 
	C348.980011,114.858269 358.208893,114.701889 367.731506,114.733841 
	C364.990936,115.351532 361.950714,115.672134 358.466339,115.966225 
z"
          />
          <path
            fill="#EEBA1A"
            opacity="1.000000"
            stroke="none"
            d="
M409.052948,120.663452 
	C404.570709,119.688019 400.138794,118.378403 395.327362,116.973358 
	C396.091522,116.341553 397.325623,115.214935 398.362823,115.373795 
	C402.281769,115.973984 406.136749,116.991570 410.315247,117.919472 
	C410.026489,118.493599 409.564880,119.411430 409.052948,120.663452 
z"
          />
          <path
            fill="#EEBA1A"
            opacity="1.000000"
            stroke="none"
            d="
M392.965271,116.464920 
	C390.879333,116.386314 388.687164,115.979576 386.232544,115.322968 
	C388.266388,115.427666 390.562714,115.782234 392.965271,116.464920 
z"
          />
          <path
            fill="#EEBA1A"
            opacity="1.000000"
            stroke="none"
            d="
M413.948975,122.512596 
	C412.517609,122.412140 410.981293,121.963104 409.223389,121.255836 
	C410.615906,121.386414 412.229980,121.775215 413.948975,122.512596 
z"
          />
          <path
            fill="#F6B808"
            opacity="1.000000"
            stroke="none"
            d="
M413.399170,182.998672 
	C412.998932,182.554794 412.999115,182.111404 412.999054,181.335541 
	C413.468719,180.684097 413.938629,180.365143 414.705505,180.022537 
	C415.649780,180.784027 416.297089,181.569199 416.973602,182.673828 
	C415.935028,182.995255 414.867279,182.997208 413.399170,182.998672 
z"
          />
          <path
            fill="#F7B708"
            opacity="1.000000"
            stroke="none"
            d="
M360.748779,130.727203 
	C358.620270,131.332245 356.238892,131.666245 353.427917,132.000610 
	C350.425629,130.905121 347.852936,129.809280 345.280273,128.713425 
	C348.990204,126.783470 352.293518,124.771248 355.415314,128.960037 
	C356.259033,130.092117 358.757202,129.991135 360.748779,130.727203 
z"
          />
          <path
            fill="#F7B708"
            opacity="1.000000"
            stroke="none"
            d="
M387.268616,130.864899 
	C384.133789,128.704926 384.354889,127.350174 387.896637,127.041191 
	C389.343018,126.915001 390.820465,127.144821 392.283783,127.212669 
	C392.482391,127.699722 392.681000,128.186768 392.879608,128.673813 
	C391.136688,129.429871 389.393738,130.185928 387.268616,130.864899 
z"
          />
          <path
            fill="#F7B708"
            opacity="1.000000"
            stroke="none"
            d="
M337.021545,134.703522 
	C335.931854,135.000076 334.866180,135.000153 333.400848,135.000427 
	C334.444580,133.689850 335.887939,132.379089 337.665649,131.367645 
	C338.000092,132.110992 338.000183,132.555008 338.000275,132.999054 
	C337.682037,133.468369 337.363770,133.937698 337.021545,134.703522 
z"
          />
          <path
            fill="#F7B708"
            opacity="1.000000"
            stroke="none"
            d="
M339.999725,131.000549 
	C341.151398,131.146362 342.303040,131.292191 343.454712,131.438004 
	C343.470764,131.735703 343.486786,132.033401 343.502808,132.331100 
	C342.579193,132.553024 341.655548,132.774963 340.365967,132.664764 
	C339.999908,131.888611 339.999817,131.444580 339.999725,131.000549 
z"
          />
          <path
            fill="#F7B708"
            opacity="1.000000"
            stroke="none"
            d="
M329.058990,127.342361 
	C329.963501,127.438354 330.924530,127.876396 331.943298,128.656006 
	C331.039185,128.559845 330.077362,128.122131 329.058990,127.342361 
z"
          />
          <path
            fill="#F7B708"
            opacity="1.000000"
            stroke="none"
            d="
M332.679749,135.029282 
	C332.235443,135.663528 331.470886,136.325851 330.354370,136.994385 
	C330.788116,136.352844 331.573822,135.705093 332.679749,135.029282 
z"
          />
          <path
            fill="#F5B809"
            opacity="1.000000"
            stroke="none"
            d="
M339.666260,131.000366 
	C339.999817,131.444580 339.999908,131.888611 339.999878,132.665833 
	C339.555511,132.999252 339.111267,132.999466 338.333679,132.999359 
	C338.000183,132.555008 338.000092,132.110992 338.000000,131.333679 
	C338.444275,131.000336 338.888550,131.000259 339.666260,131.000366 
z"
          />
          <path
            fill="#F7B708"
            opacity="1.000000"
            stroke="none"
            d="
M379.953247,132.681213 
	C379.361633,132.546494 378.725739,132.093460 378.044403,131.322830 
	C378.635651,131.457779 379.272308,131.910324 379.953247,132.681213 
z"
          />
          <path
            fill="#EEBA1A"
            opacity="1.000000"
            stroke="none"
            d="
M348.181519,118.918388 
	C348.077820,118.684692 348.275635,118.455330 348.788086,118.260574 
	C348.872192,118.491676 348.665649,118.700859 348.181519,118.918388 
z"
          />
          <path
            fill="#EEBA1A"
            opacity="1.000000"
            stroke="none"
            d="
M340.192596,120.259750 
	C340.785095,119.708504 341.575928,119.400879 342.704651,119.142334 
	C342.158722,119.628738 341.274811,120.066063 340.192596,120.259750 
z"
          />
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
